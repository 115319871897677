/* eslint-disable no-throw-literal */

import { eachMonthOfInterval } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { showAlert } from "../../slices/alert";
import { bankHolidays, createBooking, pupilBooking } from "../../slices/booking";
import { IEvent, ITerm } from "../../slices/interfaces";
import { createTerm, editTerm, terms } from "../../slices/term";
import { BookingContext } from "../context";
import { useAppDispatch, useAppSelector } from "../hooks/store";
import { set } from "date-fns";

const createHolidayEvent = (date: string, note?: string, title?: string): IEvent => {
  return {
    title: title ?? "Term holiday",
    date: set(new Date(date), { hours: 12 }),
    notes: note ?? "",
    bunting: false,
  } as IEvent;
};

export const BookingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const publicHolidays = useAppSelector(bankHolidays);
  const [startYear, setStartYear] = useState<Date>(new Date(new Date().setFullYear(2022)));
  const term = useAppSelector(terms);
  const currentPupilBooking = useAppSelector(pupilBooking);

  const dispatch = useAppDispatch();

  const [calendarPeriod, setCalendarPeriod] = useState<Date[]>([]);

  const [selectedOffDays, setSelectedOffDays] = useState<string[]>([]);

  const [bookedDays, setBookedDays] = useState<string[]>([]);

  const savePupilBooking = async (pupilId: string) => {
    const pupilBookedDays = bookedDays.map((day) => createHolidayEvent(day, "Booking", "Current Term Booking"));
    const date = new Date();

    const currentMonth = date.getMonth();
    if (currentMonth <= 6) {
      date.setFullYear(date.getFullYear() - 1);
    }
    const termEndYear = currentMonth <= 6 ? new Date().getFullYear() : new Date().getFullYear() + 1;
    const termStart = new Date(date.getFullYear(), 7);
    const termEnd = new Date(termEndYear, 7, 0);
    const ret = await dispatch(
      createBooking({
        pupilId,
        termEnd,
        termStart,
        bookedDays: pupilBookedDays,
        ...(currentPupilBooking.length > 0 ? { _id: currentPupilBooking[0]._id } : {}),
      })
    );
    if (!createBooking.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
      setBookedDays([]);
    } else {
      dispatch(showAlert({ message: "Success", severity: "success" }));
    }
  };

  const saveTerm = async (editMode: boolean, termId?: string) => {
    const termStart = new Date(startYear.getFullYear(), 7);
    const termEnd = new Date(startYear.getFullYear() + 1, 7, 0);
    const holidays = selectedOffDays.map((day) => createHolidayEvent(day));
    if (editMode) {
      const ret = await dispatch(
        editTerm({
          _id: termId,
          holidays: [...(currentSchoolTermHolidays ?? []), ...holidays],
        } as Omit<ITerm, "termStart |termEnd">)
      );
      if (!editTerm.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
      } else {
        dispatch(showAlert({ message: "Success", severity: "success" }));
      }
    } else {
      const ret = await dispatch(
        createTerm({
          termStart,
          termEnd,
          holidays,
        })
      );
      if (!createTerm.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
      } else {
        dispatch(showAlert({ message: "Success", severity: "success" }));
      }
    }
  };
  const [currentSchoolTermHolidays, setCurrentSchoolTermHolidays] = useState<IEvent[] | undefined>(undefined);

  useEffect(() => {
    setCurrentSchoolTermHolidays(
      term.find((e) => new Date(e.termStart).getFullYear() === startYear.getFullYear())?.holidays ?? []
    );
    setBookedDays(currentPupilBooking[0]?.bookedDays.map((bookedDay) => new Date(bookedDay.date).toDateString()) ?? []);
  }, [currentPupilBooking, startYear, term]);

  const filterHolidays = useMemo(() => {
    return publicHolidays.filter((holiday) => {
      const termTimeStart = new Date(startYear.getFullYear(), 7);
      const endYear = startYear.getFullYear() + 1;
      const termTimeEnd = new Date(endYear, 7, 0);
      const holidayDate = new Date(holiday.date);
      setCalendarPeriod(eachMonthOfInterval({ start: termTimeStart, end: termTimeEnd }));
      return holidayDate >= termTimeStart && holidayDate <= termTimeEnd;
    });
  }, [startYear, publicHolidays]);

  return (
    <BookingContext.Provider
      value={{
        startYear,
        setStartYear,
        filterHolidays,
        setCalendarPeriod,
        calendarPeriod,
        selectedOffDays,
        setSelectedOffDays,
        saveTerm,
        currentSchoolTermHolidays,
        setCurrentSchoolTermHolidays,
        bookedDays,
        setBookedDays,
        savePupilBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
