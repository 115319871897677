import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Grid,
} from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { formatNumber } from "../../utils";
interface IAccountPreviewProps {
  open: boolean;
  handleClose: () => void;
  data: {
    summary: {
      bankBalance: number;
      totalRevenue: number;
      totalExpense: number;
    };
    yearToDate: {
      [x: string]: number;
    };
  };
}

const AccountPreview: FC<IAccountPreviewProps> = ({ open, handleClose, data }): JSX.Element => {
  const total = useMemo(() => {
    return Object.values(data.yearToDate).reduce((acc, x) => acc + x, 0);
  }, [data]);

  const getPercentage = useCallback(
    (amount: number) => {
      return ((amount / total) * 100).toFixed(2);
    },

    [total]
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
      fullWidth
      sx={{ textAlign: "center" }}
    >
      <DialogTitle variant="h6">Account Statement Preview</DialogTitle>
      <DialogContent>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            position: "relative",
            td: { fontSize: "12px" },
            th: {
              position: "sticky",
              top: "0px",
              zIndex: 10,
            },
            "td:nth-of-type(n+2), th:nth-of-type(n+2)": {
              textAlign: "right",
            },
            "tr:last-child td": {
              fontWeight: "bold",
              fontSize: "15px",
            },
          }}
        >
          <Table aria-label="transactionCategory" size="small">
            <TableHead
              sx={{
                th: {
                  backgroundColor: "#14e6dd	",
                },
              }}
            >
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data.yearToDate)
                .filter((x) => x !== "HMRC")
                .sort()
                .map((x) => {
                  return (
                    <TableRow key={x}>
                      <TableCell>{x}</TableCell>
                      <TableCell>
                        <Grid container justifyContent={"space-between"}>
                          <Typography
                            fontSize={"10px"}
                            color={"GrayText"}
                            textAlign={"right"}
                            width={"50px"}
                          >
                            {getPercentage(data.yearToDate[x])}%
                          </Typography>
                          {formatNumber(data.yearToDate[x])}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TableCell>Expense</TableCell>
                <TableCell>{`(${formatNumber(data.summary.totalExpense)})`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Revenue</TableCell>
                <TableCell>{formatNumber(data.summary.totalRevenue)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Balance</TableCell>
                <TableCell>{formatNumber(data.summary.bankBalance)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default AccountPreview;
