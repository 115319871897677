import { RecaptchaVerifier, User } from "firebase/auth";

export const defaultErrorMessage = "An error occurred";

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier | undefined;
  }
}

export interface IBlog {
  [x: string]: any;
  title: string;
  body: string;
}

// [
//   {
//     staff: [
//       {
//         fullName: "Jade Hermann",
//         staffId: "ccc7a5e4-f24d-48c1-af16-7a2136393085",
//       },
//       {
//         fullName: "Eddie Kozey",
//         staffId: "f000271b-59f4-4a17-895b-9c668b7c8092",
//       },
//     ],
//     createdAt: "2024-09-09T14:07:22.509Z",
//     updatedAt: "2024-09-09T14:47:17.900Z",
//     date: "02/09/2024",
//     _id: "66df011ab85c410790607865",
//   },
// ];

export interface IStaffRoasterMonthBooking {
  bookings: IStaffRoasterBooking[];
  currentMonthHolidays: string[];
}

export interface IStaffRoasterBooking {
  staff: IStaffDayBooking[];
  createdAt: Date;
  updatedAt: Date;
  date: string;
  _id: string;
}

export interface IStaffDayBooking {
  fullName: string;
  staffId: string;
}

export interface IBreakfastClubMonthHoliday {
  bookings: IBreakfastClub[];
  currentMonthHolidays: string[];
}

export interface IBreakfastClub {
  breakfastClubAttendance: IBreakfastAttendance[];
  createdAt: Date;
  updatedAt: Date;
  date: string;
  _id: string;
}

export interface IBreakfastAttendance {
  fullName: string;
  parentId: string;
  pupilId: string;
}

export interface IBankTransaction {
  "Transaction Date": string;
  "Transaction Type": string;
  "Sort Code": string;
  "Account Number": number;
  "Transaction Description": string;
  "Debit Amount"?: number;
  "Credit Amount"?: number;
  Balance: number;
  category?: string;
  matcherResult?: ITransactionMatcher;
}

export interface ITransactionMatcher {
  perfectMatch: boolean;
  categoryId: string;
  perfectDescription: boolean;
  score: number;
}

export interface IBankTransactionData {
  transactionDate: string;
  transactionType: string;
  sortCode: string;
  accountNumber: number;
  transactionDescription: string;
  amount: number;
  balance: number;
  transactionCategoryId: string;
  category?: ITransactionCategory;
  _id?: string;
}
export interface ICategorizedBankTransactionData {
  "Transaction Date": string;
  "Transaction Type": string;
  "Sort Code": string;
  "Account Number": number;
  "Transaction Description": string;
  "Debit Amount": number | string;
  "Credit Amount": number | string;
  Balance: number;
  Category: string;
}

export interface ITransactionCategory {
  categoryName: string;
  transactionType: number;
  _id: string;
}

export enum ITransactionCategoryEnum {
  EXPENSE = "EXPENSE",
  REVENUE = "REVENUE",
}

export interface IAttendance {
  breakfast?: boolean;
  pupilId: string;
  createdById: string;
  loggedAt: string;
  fullName?: string;
  signedOut: boolean;
  fullDay: boolean;
}

export interface IAttendanceData {
  attendance: IAttendance[];
  createdAt: string;
  date: string;
  updatedAt: string;
  _id: string;
  convertedDate?: string;
  breakfast?: boolean;
}

export interface IDbUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  accountType: AccountTypeEnum;
  address?: string;
  postCode?: string;
  city?: string;
  createdAt?: string;
  appCounter?: number;
  kids?: IStudent[];
}

export interface ISchool {
  _id?: string;
  name: string;
  description?: string;
  studentCount?: number;
  managerId?: number;
  manager?: IDbUser;
}

export interface IStudent {
  firstName: string;
  lastName: string;
  schoolId: string;
  parentId: string;
  birthDay: Date;
  parent?: IDbUser;
  school?: ISchool;
  isActive?: boolean;
  breakfastClub?: boolean;
  _id?: string;
  notes?: string;
}

export interface studentType {
  parentId: string;
  schoolId: string;
  firstName: string;
  lastName: string;
  birthDay: any;
  _id?: string;
  school?: ISchool;
  breakfastClub?: boolean;
  isActive?: boolean;
  notes: string;
}

export type Maybe<T> = T | null;

export interface IStudentData extends Omit<IStudent, "schoolId" | "parentId"> {
  parent: IDbUser;
  school: ISchool;
}

export enum AccountTypeEnum {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  STAFF = "STAFF",
  UNDEFINED = "UNDEFINED",
  LOCK = "LOCK",
}
export interface IRoute {
  path: string;
  component: any;
  name: string;
  protected: boolean;
  extra?: Record<string, string>;
}

export interface EmailPassType {
  email: string;
  password: string;
}

export interface PageProps {
  title: string;
}

export interface IAuth {
  user: User | null;
  dbUser: IDbUser | null;
  setDbUser: (dbUser: Maybe<IDbUser>) => void;
  setLoading?: (loading: boolean) => void;
}

export interface IBooking {
  startYear: Date;
  setStartYear: (date: Date) => void;
  filterHolidays: IEvent[];
  setCalendarPeriod: (dates: Date[]) => void;
  calendarPeriod: Date[];
  selectedOffDays: string[];
  setSelectedOffDays: (dateString: string[]) => void;
  saveTerm: (editMode: boolean, termId?: string) => Promise<void>;
  setCurrentSchoolTermHolidays: (events: IEvent[]) => void;
  currentSchoolTermHolidays: IEvent[] | undefined;
  bookedDays: string[];
  setBookedDays: (dateString: string[]) => void;
  savePupilBooking: (pupilId: string) => Promise<void>;
}

export interface IPupilBooking extends Omit<ITerm, "holidays"> {
  pupilId: string;
  bookedDays: IEvent[];
}

export interface ITerm {
  _id?: string;
  termStart: Date;
  termEnd: Date;
  holidays: IEvent[];
}
export interface IEvent {
  title: string;
  date: Date;
  notes: Notes;
  bunting: boolean;
}

enum Notes {
  Empty = "",
  SubstituteDay = "Substitute day",
}

export const RangeShortcut = {
  mondays: "MONDAYS",
  tuesdays: "TUESDAYS",
  wednesdays: "WEDNESDAYS",
  thursdays: "THURSDAYS",
  fridays: "FRIDAYS",
  reset: "RESET",
};
export const RangeShortcuts = [
  {
    range: RangeShortcut.mondays,
    label: "Mondays",
  },
  {
    range: RangeShortcut.tuesdays,
    label: "Tuesdays",
  },
  {
    range: RangeShortcut.wednesdays,
    label: "Wednesdays",
  },
  {
    range: RangeShortcut.thursdays,
    label: "Thursdays",
  },
  {
    range: RangeShortcut.fridays,
    label: "Fridays",
  },
  {
    range: RangeShortcut.reset,
    label: "Reset",
  },
];

export type RangeShortcutType = keyof typeof RangeShortcut;

export interface IPupilShort {
  pupil: string;
  pupilId: string;
}

export interface ICurrentMonthBooking {
  _id: string;
  manager?: IDbUser;
  data: PupilBooking[] | IPupilShort[];
}

export interface PupilBooking {
  pupil: string;
  bookedDates: string[];
}

export interface IInvoice {
  _id: string;
  period: string;
  data: Datum[];
  fullDayCharge: number;
  normalCharge: number;
  updatedAt: string;
}

export interface Datum {
  pupilId: string;
  parentId: string;
  record: IDayAttendance[];
  discount: number;
  travel: number;
  partialPayment: number;
  paid: boolean;
  firstName: string;
  lastName: string;
  schoolId: string;
  birthDay: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  notes: Notes;
  read: boolean;
  isAutoMarkedPaid: boolean;
}

export interface IDayAttendance {
  fullDay: boolean;
  date: string;
}

export type PaymentCodes = keyof typeof IPaymentCodeEnum;

export enum IPaymentCodeEnum {
  BGC = "Bank Giro Credit",
  BNS = "Bonus BP",
  BP = "Bill Payment",
  CHG = "Charge",
  CHQ = "Cheque",
  COM = "Commission",
  COR = "Correction",
  CPT = "Cashpoint",
  CSH = "Cash",
  CSQ = "Cash/Cheque",
  DD = "Direct Debit",
  DEB = "Debit Card",
  DEP = "Deposit",
  EFT = "EFTPOS (Electrionic funds transfer at point of sale)",
  EUR = "Euro Cheque",
  FE = "Foreign Exchange",
  FEE = "Fixed Servce Charge",
  FPC = " Faster Payment Charge",
  FPI = "Faster Payment Incoming",
  FPO = "Faster Payment Outgoing",
  IB = "Internet Banking",
  INT = "Interest",
  MPI = "Mobile Payment Incoming",
  MPO = "Mobile Payment Outgoing",
  MTG = "Mortgage",
  NS = "National Savings Dividend",
  NSC = "National Savings Certificates",
  OTH = "Other",
  PAY = "Payment",
  PSB = "Premium Savings Bonds",
  PSV = "Paysave",
  SAL = "Salary",
  SPB = "Cashpoint",
  SO = "Standing Order",
  STK = "Stocks/Shares",
  TD = "Dep Term Dec",
  TDG = "Term Deposit Gross Interest",
  TDI = "Dep Term Inc",
  TDN = "Term Deposit Net Interest",
  TFR = "Transfer",
  UT = "Unit Trust",
  SUR = "Excess Reject",
}

export interface IGroupedTransactions {
  groupedTransactions: IGroupedTransaction[];
  period: string;
  monthInvoiceTotal: number;
}

export interface IGroupedTransactions {
  groupedTransactions: IGroupedTransaction[];
  period: string;
  monthInvoiceTotal: number;
}

export interface IGroupedTransaction {
  total: number;
  category: string;
}

export const MonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export type financialPeriod = "current" | "previousOne" | "previousTwo";

export type IFinancialData = {
  [x in financialPeriod]: IHistoricData[];
};

export interface IHistoricData {
  monthInvoiceTotal: number;
  paidInvoiceTotal: number;
  partPaidInvoice: number;
  period: string;
  paidTravelInvoice?: number;
}
