import { Chip, Avatar, Box, IconButton } from "@mui/material";
import { ITransactionCategory, ITransactionCategoryEnum } from "../../slices/interfaces";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleIcon from "@mui/icons-material/AddCircle";
type transactionCategoryProps = {
  allTransactionCategories: ITransactionCategory[];
  openCreateCategory: () => void;
};

const TransactionCategoryChip = ({
  allTransactionCategories,
  openCreateCategory,
}: transactionCategoryProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {allTransactionCategories.length > 0 &&
        [...allTransactionCategories]
          .sort(
            (a, b) =>
              b.transactionType - a.transactionType || a.categoryName.localeCompare(b.categoryName)
          )
          .map((category) => {
            const type = Object.keys(ITransactionCategoryEnum)[category.transactionType];

            return (
              <Chip
                sx={{
                  m: 0.5,
                  p: 0.2,
                }}
                size="small"
                deleteIcon={<SettingsIcon fontSize="small" />}
                key={category._id}
                avatar={
                  <Avatar
                    sx={{
                      color: "black !important",
                      bgcolor: type.charAt(0) === "R" ? "lightgreen" : "pink",
                    }}
                  >
                    {type.charAt(0)}
                  </Avatar>
                }
                label={category.categoryName}
                onDelete={() => console.log(category._id)}
              />
            );
          })}
      <Box width={"100%"} textAlign={"center"}>
        <IconButton color="primary" onClick={openCreateCategory} size="small" sx={{}}>
          <Avatar sx={{ bgcolor: "orangered" }}>
            <AddCircleIcon />
          </Avatar>
        </IconButton>
      </Box>
    </Box>
  );
};

export default TransactionCategoryChip;
