import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  GridRowParams,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
  Fragment,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { AccountTypeEnum, IAuth, IPupilShort, ISchool, IStudent } from "../../slices/interfaces";
import { createStudent, data, getStudents, isLoading } from "../../slices/student";
import PageLoadingSpinner from "../PageLoadingSpinner";
import { CustomizedDataGrid } from "../styledComponent";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import { showAlert } from "../../slices/alert";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import { format } from "date-fns";
import { getTodayBooking, todaysBooking } from "../../slices/booking";
import Attendance from "./Index";
import { attendance, createAttendance, getAttendance } from "../../slices/attendance";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import CakeIcon from "@mui/icons-material/Cake";
import { AuthContext } from "../../modules/context";
import { yellow } from "@mui/material/colors";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import LightModeIcon from "@mui/icons-material/LightMode";

const CreateEditPupil = lazy(() => import("../appBar/CreateEditPupil"));

const MonthAttendance = lazy(() => import("./MonthAttendance"));
const SingleAttendance = lazy(() => import("./SingleAttendance"));
const BreakfastClubAttendance = lazy(() => import("./BreakfastClub"));
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Student = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const students = useAppSelector(data);
  const loading = useAppSelector(isLoading);
  const [editUser, setEditUser] = useState<IStudent | undefined>(undefined);
  const totalAttendance = useAppSelector(attendance);

  const [toggleActiveId, setToggleActiveId] = useState<string>("");
  const [breakfastClubId, setBreakfastClubId] = useState<string>("");

  const [openModal, setOpenSchoolModal] = useState<boolean>(false);
  const [openMonthAttendanceModal, setOpenMonthAttendanceModal] = useState<boolean>(false);

  const [openMonthBreakfastAttendance, setOpenMonthBreakfastAttendance] = useState<boolean>(false);

  const currentBooking = useAppSelector(todaysBooking);

  const [updatedBooking, setUpdatedBooking] = useState<boolean>(false);
  const [singleAttendanceModal, setSingleAttendanceModal] = useState<boolean>(false);
  const [student, setStudent] = useState<IStudent>();
  const [fullDay, setFullDay] = useState<boolean>(false);
  const { dbUser } = useContext(AuthContext) as IAuth;

  logEvent(analytics, "wPupils");

  useEffect(() => {
    dispatch(getTodayBooking(false));
    dispatch(getStudents());
    setUpdatedBooking(true);
    dispatch(getAttendance({ month: false }));
  }, [dispatch]);

  const disableLoggedInPupils = useMemo(() => {
    if (totalAttendance.length === 0) return [];
    return totalAttendance.at(-1)!.attendance.map((x) => x.pupilId);
  }, [totalAttendance]);

  const toggleActive = useCallback(
    async (params: GridRowParams) => {
      setToggleActiveId(params.row._id);
      const ret = await dispatch(
        createStudent({
          ...params.row,
          isActive: !params.row.isActive,
        })
      );
      if (!createStudent.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
      } else {
        dispatch(showAlert({ message: "Success", severity: "success" }));
      }
      setToggleActiveId("");
    },
    [dispatch]
  );

  const toggleBreakfastClub = useCallback(
    async (params: GridRowParams) => {
      setBreakfastClubId(params.row._id);
      const ret = await dispatch(
        createStudent({
          ...params.row,
          breakfastClub: !params.row.breakfastClub,
        })
      );
      if (!createStudent.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
      } else {
        dispatch(showAlert({ message: "Success", severity: "success" }));
      }
      setBreakfastClubId("");
    },
    [dispatch]
  );

  const rows: GridRowsProp<IStudent> = students;
  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "firstName",
        headerName: "Name",
        flex: 1,
        renderCell: (params: GridRenderCellParams<ISchool>) => {
          const userDob = new Date(params.row.birthDay);
          const currentDay = new Date();
          let isBirthday = false;
          if (
            userDob.getMonth() === currentDay.getMonth() &&
            userDob.getDate() === currentDay.getDate()
          ) {
            isBirthday = true;
          }
          return (
            <>
              {params.value}
              {params.row.notes && <ArticleTwoToneIcon fontSize="small" sx={{ ml: 1 }} />}
              {isBirthday && (
                <CakeIcon fontSize="small" sx={{ ml: "2px", fontSize: "12px", color: "red" }} />
              )}
            </>
          );
        },
      },
      {
        field: "lastName",
        headerName: "Surname",
        flex: 1,
      },
      {
        field: "birthDay",
        headerName: "D.O.B",
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          format(new Date(params.value), "dd/MM"),
      },
      {
        field: "actions",
        type: "actions",
        width: 150,
        getActions: (params: GridRowParams<IStudent>) => [
          <GridActionsCellItem
            icon={<EditIcon color="primary" />}
            label="Toggle Admin"
            onClick={() => {
              setEditUser(params.row);
              setOpenSchoolModal(true);
            }}
          />,
          <GridActionsCellItem
            icon={
              toggleActiveId === params.row._id ? (
                <CircularProgress size={15} color="error" />
              ) : (
                <LockTwoToneIcon color={params.row.isActive ? "success" : "error"} />
              )
            }
            label="Mark Inactive"
            onClick={() => toggleActive(params)}
          />,
          <GridActionsCellItem
            icon={<EventAvailableIcon color="secondary" />}
            label="Create attendance"
            disabled={!params.row.isActive}
            onClick={
              !params.row.isActive
                ? undefined
                : () => {
                    dispatch(getAttendance({ month: false, pupilId: params.row._id }));
                    setSingleAttendanceModal(true);
                    setStudent(params.row);
                  }
            }
          />,
          <GridActionsCellItem
            icon={
              breakfastClubId === params.row._id ? (
                <CircularProgress size={15} color="error" />
              ) : (
                <LightModeIcon
                  sx={{
                    color: params.row?.breakfastClub ? yellow[900] : "info",
                  }}
                />
              )
            }
            label="Breakfast Club"
            disabled={!params.row.isActive}
            onClick={
              !params.row.isActive
                ? undefined
                : () => {
                    toggleBreakfastClub(params);
                  }
            }
          />,
        ],
      },
    ],
    [dispatch, toggleActive, toggleActiveId, toggleBreakfastClub, breakfastClubId]
  );

  const handleClose = (): void => {
    setOpenSchoolModal(!openModal);
    setEditUser(undefined);
  };

  const handleSingleAttendance = (): void => {
    setSingleAttendanceModal(!singleAttendanceModal);
    dispatch(getAttendance({ month: false }));
  };

  const handleBreakfastClubAttendance = (): void => {
    setOpenMonthBreakfastAttendance(!openMonthBreakfastAttendance);
  };

  const handleMonthAttendanceClose = (): void => {
    setOpenMonthAttendanceModal(!openMonthAttendanceModal);
    dispatch(getAttendance({ month: false }));
  };

  const _createAttendance = async (pupilId: string) => {
    const ret = await dispatch(createAttendance({ pupilId, fullDay }));
    if (!createAttendance.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      dispatch(showAlert({ message: "Attendance created", severity: "success" }));
    }
  };

  return (
    <>
      {loading ? (
        <ComponentLoadingSpinner height="90vh" />
      ) : (
        <>
          {dbUser?.accountType === AccountTypeEnum.ADMIN && (
            <Tooltip title="Add pupil">
              <Fab
                onClick={() => setOpenSchoolModal(!openModal)}
                sx={{ position: "absolute", top: 68, left: 5 }}
                color="success"
              >
                <PersonAddIcon />
              </Fab>
            </Tooltip>
          )}
          <Tooltip title="View Attendance">
            <Fab
              size="medium"
              onClick={() => {
                setOpenMonthAttendanceModal(!openMonthAttendanceModal);
                dispatch(getAttendance({ month: true }));
              }}
              sx={{ position: "absolute", top: 68, right: 5 }}
              color="secondary"
            >
              <EventAvailableIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="View & Edit Breakfast Club">
            <Fab
              onClick={() => {
                setOpenMonthBreakfastAttendance(!openMonthBreakfastAttendance);
              }}
              size="small"
              sx={{
                position: "absolute",
                top: 120,
                right: 10,
                bgcolor: yellow[800],
                fontSize: "30px",
              }}
            >
              <LightModeIcon />
            </Fab>
          </Tooltip>

          <Grid
            container
            justifyContent={"center"}
            columnSpacing={2}
            height={"100vh"}
            paddingTop={"20px"}
          >
            <Grid item xs={4} height={"88vh"}>
              <CustomizedDataGrid
                rows={rows}
                rowHeight={30}
                columns={columns}
                pageSize={40}
                rowsPerPageOptions={[40]}
                headerHeight={38}
                loading={loading}
                getRowId={(row) => row._id}
                getRowClassName={(params) => (params.row.isActive ? "" : "inactive-pupil")}
              />
            </Grid>
            <Grid item xs={4}>
              {!updatedBooking ? (
                <Box
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"90vh"}
                  display={"flex"}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{
                    position: "relative",
                    height: "88vh",
                    overflow: "auto",
                    border: "1px solid rgba(224, 224, 224, 1)",
                    borderRadius: "4px",
                  }}
                >
                  <Table aria-label="term table" size="small">
                    <TableHead
                      sx={{
                        th: {
                          backgroundColor: "#D3D3D3	",
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell width={"300px"}>
                          <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography>Today's Booking</Typography>
                            <Box display={"flex"} justifyContent={"space-around"} width={"25%"}>
                              <Typography>Full Day :</Typography>
                              <Checkbox
                                size="small"
                                checked={fullDay}
                                {...label}
                                onClick={() => setFullDay(!fullDay)}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 20 },
                                  p: 0,
                                  "&.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {updatedBooking &&
                        [...currentBooking]
                          ?.sort((a, b) => a._id.localeCompare(b._id))
                          .map((school, x) => {
                            return (
                              <Fragment key={x}>
                                {school.data?.map((data, x) => {
                                  return (
                                    <TableRow key={`${x}`}>
                                      <TableCell component="th" scope="row">
                                        <Box
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                          display={"flex"}
                                          width={"100%"}
                                        >
                                          <Container style={{ padding: 0 }}>
                                            <Typography fontSize={"12px"}>
                                              {(data as IPupilShort).pupil}
                                            </Typography>
                                            <Box
                                              width={"50%"}
                                              display={"flex"}
                                              flexDirection={"row"}
                                              justifyContent={"space-between"}
                                              alignItems={"center"}
                                            >
                                              <Typography
                                                fontSize={"9px"}
                                                fontStyle={"italic"}
                                                color="GrayText"
                                              >
                                                {school._id}
                                              </Typography>
                                              {school.manager?.firstName && (
                                                <Chip
                                                  label={school.manager?.firstName}
                                                  color="info"
                                                  size="small"
                                                  sx={{
                                                    fontSize: "10px",
                                                    height: "15px",
                                                    width: "100px",
                                                  }}
                                                />
                                              )}
                                            </Box>
                                          </Container>
                                          <>
                                            <Button
                                              disableElevation
                                              variant="contained"
                                              color="orange"
                                              size="small"
                                              sx={{
                                                fontSize: "10px",
                                              }}
                                              disabled={disableLoggedInPupils.includes(
                                                (data as IPupilShort).pupilId
                                              )}
                                              onClick={() =>
                                                _createAttendance((data as IPupilShort).pupilId)
                                              }
                                            >
                                              Clock in
                                            </Button>
                                          </>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}{" "}
            </Grid>
            <Grid item xs={4}>
              <Attendance />
            </Grid>
          </Grid>
        </>
      )}
      <Suspense fallback={<PageLoadingSpinner />}>
        {openModal && (
          <CreateEditPupil open={openModal} handleClose={handleClose} editUser={editUser} />
        )}
      </Suspense>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openMonthAttendanceModal && (
          <MonthAttendance
            open={openMonthAttendanceModal}
            handleClose={handleMonthAttendanceClose}
          />
        )}
      </Suspense>
      <Suspense fallback={<PageLoadingSpinner />}>
        {singleAttendanceModal && (
          <SingleAttendance
            open={singleAttendanceModal}
            handleClose={handleSingleAttendance}
            student={student!}
          />
        )}
      </Suspense>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openMonthBreakfastAttendance && (
          <BreakfastClubAttendance
            open={openMonthBreakfastAttendance}
            handleClose={handleBreakfastClubAttendance}
          />
        )}
      </Suspense>
    </>
  );
};

export default Student;
