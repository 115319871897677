import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  CircularProgress,
  Button,
  TextField,
  MenuItem,
  Stack,
  Divider,
  Box,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";

import {
  downloadBankTransactions,
  groupedPeriodTransactions,
  groupedTransactions,
  isLoadingCategorizedBankTransactionsData,
  isLoadingGroupedPeriodTransactions,
  transactionCategories,
} from "../../slices/banking";
import { ICategorizedBankTransactionData, ITransactionCategoryEnum, financialPeriod } from "../../slices/interfaces";
import { formatNumber, getFinancialYear } from "../../utils";
import { eachMonthOfInterval, format } from "date-fns";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { showAlert } from "../../slices/alert";
import Papa from "papaparse";
import AccountPreview from "./AccountPreview";

const GroupedTransactionsTable = ({ month }: { month: number }) => {
  const dispatch = useAppDispatch();
  const allGroupedTransactions = useAppSelector(groupedTransactions);
  const allTransactionCategories = useAppSelector(transactionCategories);
  const isLoadingGroupedTransactions = useAppSelector(isLoadingGroupedPeriodTransactions);
  const [openAccountPreview, setOpenAccountPreview] = useState<boolean>(false);
  const isLoadingCategorizedBankTransactions = useAppSelector(isLoadingCategorizedBankTransactionsData);
  const financialPeriodData = getFinancialYear();
  const [period, setPeriod] = useState<string>("current");
  const currentFinancialYear = eachMonthOfInterval({
    start: financialPeriodData.current.currentFinancialStartDate,
    end: financialPeriodData.current.currentFinancialEndDate,
  });

  const accountSummary = useMemo(() => {
    const summary = {
      bankBalance: 0,
      totalRevenue: 0,
      totalExpense: 0,
    };
    const yearToDate: { [x: string]: number } = {};
    for (const monthTransaction of allGroupedTransactions) {
      const { groupedTransactions } = monthTransaction;
      for (const data of groupedTransactions) {
        yearToDate[data.category] = (yearToDate[data.category] ?? 0) + data.total;
        const transactionType = allTransactionCategories.find((x) => x.categoryName === data.category);
        if (transactionType?.transactionType === 1) {
          summary.totalRevenue += data.total;
        } else {
          summary.totalExpense += data.total;
        }
      }
    }
    summary.bankBalance = summary.totalRevenue - summary.totalExpense;

    return { summary, yearToDate };
  }, [allGroupedTransactions, allTransactionCategories]);

  const _accountPreview = () => {
    setOpenAccountPreview(true);
  };

  const _handleClose = () => {
    setOpenAccountPreview(false);
  };

  const _exportData = (data: string, fileName: string, type: string) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const _downloadBankTransactions = async () => {
    const ret = await dispatch(downloadBankTransactions({ period }));
    if (!downloadBankTransactions.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      const fileName = `${format(
        financialPeriodData[period as financialPeriod].currentFinancialStartDate,
        "yyyy"
      )}_${format(financialPeriodData[period as financialPeriod].currentFinancialEndDate, "yyyy")}.csv`;

      if (ret.payload.length === 0) {
        dispatch(
          showAlert({
            message: `No data available`,
            severity: "error",
          })
        );
      } else {
        try {
          const csv = Papa.unparse<ICategorizedBankTransactionData>(ret.payload);
          _exportData(csv, fileName, "text/csv;charset=utf-8;");
        } catch (e) {
          console.log(e);
          dispatch(
            showAlert({
              message: `${e}`,
              severity: "error",
            })
          );
        }
      }
    }
  };

  const periodDropdown = Object.keys(financialPeriodData)
    .sort()
    .reverse()
    .map((x) => (
      <MenuItem key={x.toString()} sx={{ fontSize: "12px" }} value={x}>
        {format(financialPeriodData[x as financialPeriod].currentFinancialStartDate, "yyyy")} -{" "}
        {format(financialPeriodData[x as financialPeriod].currentFinancialEndDate, "yyyy")}
      </MenuItem>
    ));

  useEffect(() => {
    dispatch(groupedPeriodTransactions());
  }, [dispatch]);
  return (
    <>
      {isLoadingGroupedTransactions ? (
        <ComponentLoadingSpinner />
      ) : (
        <>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            height={"35px"}
            sx={{ mb: 1, pl: 1, fontSize: "14px" }}
            columnSpacing={1}
          >
            <Grid item xs={12} container justifyContent={"flex-end"} columnGap={1}>
              <TextField
                id="period"
                value={period}
                select
                size="small"
                sx={{
                  textAlign: "center",
                }}
                InputProps={{
                  sx: { height: "25px", fontSize: "12px", p: 0, m: 0, width: "120px" },
                }}
                onChange={async (e) => {
                  setPeriod(e.target.value);
                }}
              >
                <MenuItem disabled value="" sx={{ fontSize: "12px" }}>
                  <em>Period</em>
                </MenuItem>
                {periodDropdown}
              </TextField>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                sx={{ height: "25px", fontSize: "12px", borderRadius: "5px" }}
                startIcon={<AccountBalanceIcon />}
                disabled={period !== "current"}
                onClick={() => _accountPreview()}
              >
                Account Preview
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="success"
                sx={{
                  height: "25px",
                  fontSize: "12px",
                  width: isLoadingCategorizedBankTransactions ? "150px" : "fit-content",
                  borderRadius: "5px",
                }}
                startIcon={isLoadingCategorizedBankTransactions ? <></> : <DownloadForOfflineIcon fontSize="small" />}
                disabled={isLoadingCategorizedBankTransactions}
                onClick={() => _downloadBankTransactions()}
              >
                {!isLoadingCategorizedBankTransactions ? "Download CSV" : <CircularProgress size={10} />}
              </Button>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              mr: "10px",
              position: "relative",
              height: "95%",
              overflow: "auto",
              td: { fontSize: "11px", paddingX: "7px", paddingY: "7px" },
              th: {
                position: "sticky",
                top: "0px",
                zIndex: 10,
                paddingX: "7px",
                paddingY: "7px",
              },
              "td:nth-of-type(n+2)": {
                textAlign: "right",
              },
            }}
          >
            <Table aria-label="transactionCategory" size="small">
              <TableHead
                sx={{
                  th: {
                    backgroundColor: "#14e6dd	",
                  },
                }}
              >
                <TableRow>
                  <TableCell width={"200px"}></TableCell>
                  {currentFinancialYear.map((x) => (
                    <TableCell key={x.toLocaleDateString()} sx={{ fontSize: "11px", textAlign: "right" }}>
                      {format(x, "MMM yy")}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allTransactionCategories.length > 0 &&
                  [...allTransactionCategories]
                    .sort(
                      (b, a) => a.transactionType - b.transactionType || b.categoryName.localeCompare(a.categoryName)
                    )
                    .map((category, i) => {
                      const type = Object.keys(ITransactionCategoryEnum)[category.transactionType];
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:hover": {
                              filter: "brightness(0.9) !important",
                              bgcolor: "#f2f2f2",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <TableCell size="small" key={category._id}>
                            <Typography fontSize={"11px"}>
                              {category.categoryName.substring(0, 17)}{" "}
                              {category.categoryName && category.categoryName.length >= 17 && "..."}
                            </Typography>
                          </TableCell>
                          {currentFinancialYear.map((date, i) => {
                            const groupedTransactions =
                              allGroupedTransactions.find(
                                (x) => new Date(x.period).toDateString() === date.toDateString()
                              )?.groupedTransactions ?? [];
                            const transaction = groupedTransactions.find(
                              (transaction) => transaction.category === category.categoryName
                            );
                            return (
                              <TableCell key={i} sx={{ bgcolor: month === date.getMonth() ? "#f2f2f2" : "white" }}>
                                {transaction?.total
                                  ? type.charAt(0) !== "R"
                                    ? `(${formatNumber(transaction.total)})`
                                    : formatNumber(transaction.total)
                                  : "-"}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
              </TableBody>
              <TableHead
                sx={{
                  th: {
                    // backgroundColor: "#14e6dd	",
                  },
                }}
              >
                <TableRow>
                  <TableCell width={"200px"}>Summary</TableCell>
                  {currentFinancialYear.map((date) => {
                    const groupedTransactions =
                      allGroupedTransactions.find((x) => new Date(x.period).toDateString() === date.toDateString())
                        ?.groupedTransactions ?? [];
                    const summary = {
                      revenue: 0,
                      expense: 0,
                    };

                    for (const transaction of groupedTransactions) {
                      const transactionType = allTransactionCategories.find(
                        (x) => x.categoryName === transaction.category
                      );
                      transactionType?.transactionType === 1
                        ? (summary.revenue += transaction.total)
                        : (summary.expense += transaction.total);
                    }
                    return (
                      <TableCell key={date.toLocaleDateString()} sx={{}}>
                        <Stack divider={<Divider flexItem />}>
                          <Box justifyContent={"space-between"} display={"flex"}>
                            <Typography display={"inline"} sx={{ fontSize: "11px" }}>
                              In
                            </Typography>
                            <Typography display={"inline"} sx={{ fontSize: "11px", color: "green", fontWeight: 600 }}>
                              {formatNumber(summary.revenue)}
                            </Typography>
                          </Box>
                          <Box justifyContent={"space-between"} display={"flex"}>
                            <Typography display={"inline"} sx={{ fontSize: "11px" }}>
                              Out
                            </Typography>
                            <Typography display={"inline"} sx={{ fontSize: "11px", color: "red", fontWeight: 600 }}>
                              {" "}
                              {formatNumber(summary.expense)}
                            </Typography>
                          </Box>
                        </Stack>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          {openAccountPreview && (
            <AccountPreview open={openAccountPreview} handleClose={_handleClose} data={accountSummary} />
          )}
        </>
      )}{" "}
    </>
  );
};

export default GroupedTransactionsTable;
