import { AccountTypeEnum, IAuth } from "../../slices/interfaces";
import { memo, useContext } from "react";
import { AdminBooking } from "./Admin";
import { ClientBooking } from "./Client";
import { AuthContext } from "../../modules/context";
import { BookingProvider } from "../../modules/provider/BookingProvider";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";

export function Booking() {
  const { dbUser } = useContext(AuthContext) as IAuth;

  return (
    <>
      {!dbUser ? (
        <ComponentLoadingSpinner />
      ) : (
        <>
          <BookingProvider>
            {dbUser.accountType === AccountTypeEnum.ADMIN ||
            dbUser.accountType === AccountTypeEnum.STAFF ? (
              <AdminBooking />
            ) : (
              <ClientBooking />
            )}
          </BookingProvider>
        </>
      )}
    </>
  );
}

export default memo(Booking);
