import { Box, Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator } from "firebase/auth";
import { Formik, Form, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { useAppDispatch } from "../../modules/hooks/store";
import { showAlert } from "../../slices/alert";
import { CustomizedTextField } from "../styledComponent";
import TwoFactorRegistration from "./TwoFactorRegistration";
import * as Yup from "yup";

export function TwoFactorEnrollment() {
  const [code, setCode] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClose = async () => {
    setOpen(false);
    try {
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(auth.currentUser!).enroll(multiFactorAssertion, displayName);
      dispatch(
        showAlert({
          message: "Two factor enrollment completed",
          severity: "success",
        })
      );
      navigate("/auth/register", { replace: true });
    } catch {
      console.log("error");
    }
  };

  const [open, setOpen] = useState(false);
  const [sendCode, setSendCode] = useState<boolean>(false);
  const [verificationId, setVerificationId] = useState<string>("");

  useEffect(() => {}, [sendCode]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "60vh" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"space-around"}
        alignItems="center"
        bgcolor="#e8fdfe"
        borderRadius="12px"
        boxShadow="2"
        width="50%"
        height="400px"
      >
        <Typography variant={"h5"}> Enroll Two factor enrollment</Typography>
        <div id="recaptcha-container"></div>
        <Formik
          initialValues={{
            phone: "",
            displayName: "",
          }}
          validationSchema={Yup.object().shape({
            displayName: Yup.string().required("A display name is required"),
            phone: Yup.string()
              .matches(
                /((\+44?)|(\+440?))7\d{3}(\s)?\d{6}/g,
                "A valid mobile number is required and must start with +44"
              )
              .required("A valid mobile number is required and must start with +44"),
          })}
          onSubmit={async (values) => {
            multiFactor(auth.currentUser!)
              .getSession()
              .then(async function (multiFactorSession) {
                // Specify the phone number and pass the MFA session.
                const phoneInfoOptions = {
                  phoneNumber: values.phone,
                  session: multiFactorSession,
                };

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                // Send SMS verification code.
                const verifyCode = await phoneAuthProvider.verifyPhoneNumber(
                  phoneInfoOptions,
                  window.recaptchaVerifier!
                );
                setDisplayName(values.displayName);
                setVerificationId(verifyCode);
                setSendCode(true);
                setOpen(true);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
          }: FormikProps<{ phone: string; displayName: string }>) => (
            <Container>
              <Form>
                <CustomizedTextField
                  id="displayName"
                  name="displayName"
                  placeholder="Display name"
                  label="Display name"
                  fullWidth
                  value={values.displayName}
                  onChange={handleChange}
                  error={touched.displayName && Boolean(errors.displayName)}
                  helperText={touched.displayName ? errors.displayName : ""}
                />
                <CustomizedTextField
                  id="phone"
                  name="phone"
                  placeholder="+4471234567890"
                  label="Telephone"
                  type="tel"
                  fullWidth
                  value={values.phone}
                  onChange={handleChange}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone ? errors.phone : ""}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: "50px",
                    borderRadius: "20px",
                    height: "50px",
                    bgcolor: "#a5d6a7",
                    color: "black",
                    fontSize: "20px",
                  }}
                >
                  {!isSubmitting ? "Submit" : <CircularProgress color="success" />}
                </Button>
              </Form>
            </Container>
          )}
        </Formik>
      </Box>
      {open && (
        <TwoFactorRegistration open={open} handleClose={handleClose} setPhoneNumber={setCode} />
      )}
    </Grid>
  );
}

export default TwoFactorEnrollment;
