import { Avatar, Box, Button, CircularProgress, Grid } from "@mui/material";
import {
  getMultiFactorResolver,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import { Formik, Form, FormikProps } from "formik";
import { useContext, useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { EmailPassType, IAuth } from "../../slices/interfaces";
import TwoFactorRegistration from "./TwoFactorRegistration";
import * as Yup from "yup";
import { useAppDispatch } from "../../modules/hooks/store";
import { showAlert } from "../../slices/alert";
import useAuth from "../../modules/hooks/auth";
import { AuthContext } from "../../modules/context";
import { Container } from "@mui/system";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CustomizedTextField } from "../styledComponent";

export function Login() {
  const { dbUser } = useContext(AuthContext) as IAuth;
  const { login } = useAuth();
  const navigate = useNavigate();
  const [sendCode, setSendCode] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [code, setCode] = useState<string>("");

  const [open, setOpen] = useState(false);
  const [verificationId, setVerificationId] = useState<string>("");
  const [resolver, setResolver] = useState<MultiFactorResolver>();
  const handleClose = async () => {
    try {
      setOpen(false);
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await resolver?.resolveSignIn(multiFactorAssertion);
      if (dbUser) {
        navigate(`/user/${dbUser?._id}`, { replace: true });
        dispatch(showAlert({ message: "Welcome back", severity: "success" }));
      }
    } catch (e: any) {
      dispatch(showAlert({ message: "Please enter a valid code", severity: "error" }));
      if (e.code === "auth/invalid-verification-code") {
        setSendCode(false);
      }
    } finally {
      setOpen(false);
    }
  };

  const requireTwoFactorAuthentication = useCallback(async () => {
    if (resolver && resolver!.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: resolver!.hints[0],
        session: resolver!.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      try {
        setOpen(true);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          window.recaptchaVerifier!
        );
        setVerificationId(verificationId);
      } catch (e) {
        console.log(e);
      }
    }
  }, [resolver]);

  useEffect(() => {
    if (sendCode) {
      requireTwoFactorAuthentication();
    }
  }, [requireTwoFactorAuthentication, sendCode]);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={"space-around"}
          alignItems="center"
          bgcolor="#e8fdfe"
          borderRadius="12px"
          width="90%"
          height="80vh"
        >
          <Avatar sx={{ m: 1, bgcolor: "#021d32" }} variant="rounded">
            <LockOutlinedIcon />
          </Avatar>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required("Required"),
              password: Yup.string()
                .required("No password provided.")
                .min(8, "Password is too short - should be 8 chars minimum.")
                .matches(/(?=.*[0-9])/, "Password must contain a number."),
            })}
            onSubmit={async (values: EmailPassType) => {
              try {
                await login({ ...values });
              } catch (e: any) {
                if (e.error.code === "auth/multi-factor-auth-required") {
                  setResolver(getMultiFactorResolver(auth, e.error));
                  setSendCode(true);
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
            }: FormikProps<EmailPassType>) => (
              <Container maxWidth={'sm'}>
                <Form>
                  <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <CustomizedTextField
                      id="email"
                      name="email"
                      placeholder="john@acme.com"
                      label="Email"
                      fullWidth
                      type="email"
                      autoComplete="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email ? errors.email : ""}
                    />
                    <CustomizedTextField
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      fullWidth
                      value={values.password}
                      autoComplete="current-password"
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password ? errors.password : ""}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      size="medium"
                      sx={{
                        mt: "20px",
                        borderRadius: "20px",
                        height: "40px",
                        bgcolor: "#a5d6a7",
                        color: "black",
                        fontSize: "18px",
                        width: "80%",
                      }}
                    >
                      {!isSubmitting && !sendCode ? (
                        "Sign In"
                      ) : (
                        <CircularProgress color="success" />
                      )}
                    </Button>
                  </Box>
                </Form>
                <Container
                  sx={{
                    textAlign: "center",
                    marginTop: "100px",
                  }}
                >
                  <Link to="/auth/forget-password">Forgot password?</Link>
                </Container>
              </Container>
            )}
          </Formik>
          <Grid item>
            <Link to="/auth/sign-up">Don't have an account? Sign Up</Link>
          </Grid>
        </Box>
      </Grid>
      {open && (
        <TwoFactorRegistration open={open} handleClose={handleClose} setPhoneNumber={setCode} />
      )}
    </>
  );
}

export default Login;
