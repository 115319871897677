import { Box, CardContent, CardMedia, Container, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { IBlog } from "../../slices/interfaces";
import logo from "../../images/hummingbird.png";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import MDEditor from "@uiw/react-md-editor";

const storage = getStorage();

const EachBlog: FC<IBlog & { reloadBlogImage?: boolean }> = (blog): JSX.Element => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const attachBlogImage = useCallback(async () => {
    if (blog.reloadBlogImage) {
      try {
        setIsLoadingImage(true);
        const url = await getDownloadURL(ref(storage, `blogs/${blog?._id}/blogImage.jpg`));
        setImageUrl(url);
        setIsLoadingImage(false);
      } catch (error) {
        setImageUrl(logo);
        setIsLoadingImage(false);
      }
    }
  }, [blog?._id, blog.reloadBlogImage]);

  useEffect(() => {
    attachBlogImage();
  }, [attachBlogImage]);
  return (
    <>
      {isLoadingImage ? (
        <ComponentLoadingSpinner height="300px" />
      ) : (
        <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
          <CardMedia
            sx={{ height: 300, m: 1, borderRadius: 5 }}
            image={imageUrl}
            title="blog image"
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "5%",
              right: "5%",
              borderRadius: 5,
              backgroundImage: "linear-gradient(to left, transparent, grey, transparent)",
              width: "50%",
              boxShadow: "1px 0 100px black",
              textAlign: "center",
            }}
          >
            <Typography color={"white"} ml={2}>
              {new Date(blog.createdAt).toLocaleDateString("en", {
                year: "numeric",
                month: "long",
              })}
            </Typography>
          </Box>
        </Container>
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {blog.title}
        </Typography>
        <MDEditor.Markdown
          source={blog.body}
          style={{
            whiteSpace: "pre-wrap",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            fontFamily: "Poppins",
            lineHeight: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "60px",
            fontWeight: 400,
            fontSize: "0.875rem",
          }}
        />{" "}
      </CardContent>
    </>
  );
};

export default EachBlog;
