import { Box, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export default function LockedAccount(): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="80vh"
      justifyContent={"space-around"}
    >
      <LockOutlinedIcon fontSize="large" color="error" />
      <Typography variant="h5" fontWeight={400} m={"100px"}>
        Your account is currently locked, kindly contact admin to reactivate it
      </Typography>
    </Box>
  );
}
