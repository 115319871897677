import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IDbUser, ISchool } from "./interfaces";

const initialState: {
  data: ISchool[];
  isLoading: boolean;
  managers: IDbUser[];
  errorMessage: string;
} = {
  data: [],
  isLoading: false,
  errorMessage: "",
  managers: [],
};

export const getSchools = createAsyncThunk<ISchool[], void, { rejectValue: string }>(
  "schools/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const ret = await Api.getSchools();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const editSchool = createAsyncThunk<ISchool[], ISchool, { rejectValue: string }>(
  "schools/edit",
  async (schoolData, { rejectWithValue }) => {
    try {
      const ret = await Api.editSchool(schoolData);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const deleteSchool = createAsyncThunk<ISchool[], string, { rejectValue: string }>(
  "schools/delete",
  async (schoolId, { rejectWithValue }) => {
    try {
      const ret = await Api.deleteSchool(schoolId);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const getSchoolManagers = createAsyncThunk<IDbUser[], void, { rejectValue: string }>(
  "schools/managers",
  async (_, { rejectWithValue }) => {
    try {
      const ret = await Api.getSchoolManagers();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const schoolSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchools.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getSchools.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(getSchools.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
    // Edit

    builder.addCase(editSchool.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.errorMessage = "";
    });

    builder.addCase(editSchool.pending, (state, _) => {
      state.errorMessage = "";
    });

    builder.addCase(editSchool.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
    });

    // Delete

    builder.addCase(deleteSchool.fulfilled, (state, action: PayloadAction<ISchool[]>) => {
      state.data = action.payload;
      state.errorMessage = "";
    });

    builder.addCase(deleteSchool.pending, (state, _) => {
      state.errorMessage = "";
    });

    builder.addCase(deleteSchool.rejected, (state, { payload }) => {
      state.errorMessage = payload as string;
    });

    builder.addCase(getSchoolManagers.fulfilled, (state, { payload }) => {
      state.managers = payload;
      state.errorMessage = "";
    });

    builder.addCase(getSchoolManagers.pending, (state, _) => {
      state.errorMessage = "";
    });

    builder.addCase(getSchoolManagers.rejected, (state, { payload }) => {
      state.errorMessage = payload as string;
    });
  },
});
export const isLoading = (state: RootState) => state.schools.isLoading;
export const data = (state: RootState) => state.schools.data;
export const errorMessage = (state: RootState) => state.schools.errorMessage;
export const managers = (state: RootState) => state.schools.managers;

export default schoolSlice.reducer;
