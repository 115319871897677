import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch } from "../../modules/hooks/store";
import { showAlert } from "../../slices/alert";
import { useState } from "react";

interface Props {
  open: boolean;
  handleClose: () => void;
  setPhoneNumber: (phoneNumber: string) => void;
}
export default function TwoFactorRegistration({ open, handleClose, setPhoneNumber }: Props) {
  const dispatch = useAppDispatch();

  const [charLength, setCharLength] = useState<string>("");
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Multi Factor Authentication</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter six digit code</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="code"
            inputProps={{
              maxLength: 6,
            }}
            fullWidth
            placeholder="123456"
            variant="standard"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setCharLength(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isNaN(parseInt(charLength)) || charLength.toString().length !== 6) {
                dispatch(showAlert({ message: "Enter 6 digit code", severity: "error" }));
                return;
              }
              handleClose();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
