import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { showAlert } from "../../slices/alert";
import { attendance, signOutAttendance } from "../../slices/attendance";
import { IAttendance } from "../../slices/interfaces";

const Attendance = () => {
  const dispatch = useAppDispatch();
  const totalAttendance = useAppSelector(attendance);
  const _signOut = async (pupil: IAttendance) => {
    const ret = await dispatch(signOutAttendance(pupil.pupilId));
    if (!signOutAttendance.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      dispatch(showAlert({ message: "Sign out successful", severity: "success" }));
    }
  };
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        position: "relative",
        height: "88vh",
        overflow: "auto",
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "4px",
      }}
    >
      <Table aria-label="term table" size="small">
        <TableHead
          sx={{
            th: {
              backgroundColor: "#14e6dd",
            },
          }}
        >
          <TableRow>
            <TableCell width={"200px"}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography>Today's Attendance</Typography>
                <Typography>{format(new Date(), "dd-MM-yy")}</Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {totalAttendance.length !== 0 &&
            totalAttendance
              .at(-1)!
              .attendance.filter((x) => !(x.breakfast && !x.signedOut))
              .map((pupil) => {
                return (
                  <TableRow key={pupil.pupilId}>
                    <TableCell component="th" scope="row">
                      <Box
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        display={"flex"}
                        width={"100%"}
                      >
                        {pupil.fullName}

                        <Button
                          disableElevation
                          variant="contained"
                          disabled={pupil.signedOut}
                          color={pupil.signedOut ? "success" : "info"}
                          size="small"
                          sx={{
                            fontSize: "10px",
                            bgcolor:
                              pupil.fullDay && pupil.signedOut ? "#FFC107 !important" : "info",
                            color: pupil.fullDay && pupil.signedOut ? "grey !important" : "info",
                          }}
                          onClick={pupil.signedOut ? undefined : () => _signOut(pupil)}
                        >
                          {pupil.signedOut ? "Signed out" : "sign out"}
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Attendance;
