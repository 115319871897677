import { useEffect, useState } from "react";
import { multiFactor, User } from "firebase/auth";
import { analytics, auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/store";

import { IDbUser, Maybe } from "../../slices/interfaces";
import { getUser } from "../../slices/user";
import { AuthContext } from "../context";
import AppInitiation from "../../components/AppInitiation";
import useAuth from "../hooks/auth";
import { setUserId } from "firebase/analytics";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { logOut } = useAuth();

  const [dbUser, setDbUser] = useState<Maybe<IDbUser>>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (firebaseUser) => {
        setUser(firebaseUser);
        setLoading(false);
        if (firebaseUser) setUserId(analytics, firebaseUser?.uid);
        if (firebaseUser && !dbUser) {
          const multiFactorUser = multiFactor(auth.currentUser!);
          dispatch(getUser())
            .unwrap()
            .then((user) => setDbUser(user[0]))
            .catch((e) => {
              if (e === "The Firebase ID token has been revoked.") {
                logOut();
              }
              if (!firebaseUser.emailVerified) {
                navigate("/auth/verify-account", { replace: true });
                setLoading(false);
              } else if (
                // Enroll user before setting up database account
                multiFactorUser.enrolledFactors.length === 0
              ) {
                setLoading(false);
                navigate("/auth/two-factor-enroll");
              } else {
                navigate("/auth/register", { replace: true });
                setLoading(false);
              }
            });
        }
      },
      (err) => {
        console.log(err);
        console.log("Refresh");
      }
    );

    return unsubscribe;
    // eslint-disable-next-line
  }, [dbUser, dispatch, navigate]);

  if (loading) {
    console.log("Loading ...");
    return <AppInitiation />;
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        dbUser,
        setDbUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
