import { Container, Grid, Typography } from "@mui/material";
import { CustomizedImageBoxNoBlur } from "./components/styledComponent";
import Footer from "./footer";
import Header from "./header";

const Policy = () => {
  return (
    <Container maxWidth={false} className="About-us" disableGutters sx={{ color: "#283e4a" }}>
      <Grid container rowGap={1}>
        <Grid item height={"60px"} xs={12} alignItems={"center"} container>
          <Header></Header>
        </Grid>
        <Grid item height={"40vh"} position={"relative"} xs={12}>
          <CustomizedImageBoxNoBlur className="policy-hero">
            <Grid item container height={"inherit"} justifyContent={"center"} alignItems={"center"}>
              <Typography fontSize={"80px"} color={"white"} textAlign={"center"} lineHeight={1}>
                Policy
              </Typography>
            </Grid>
          </CustomizedImageBoxNoBlur>
        </Grid>
        <Grid item container justifyContent={"center"} alignItems={"center"} mt={2}>
          <Container fixed maxWidth={"md"}>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Privacy Policy
            </Typography>

            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              Hummingbird out of School care built the hummingbird client app as a free app. This
              SERVICE is provided by us at no cost and is intended for use as is.
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              This page is used to inform website visitors regarding our policies with the
              collection, use, and disclosure of Personal Information if anyone decided to use our
              Service.
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              If you choose to use our Service, then you agree to the collection and use of
              information in relation with this policy. The Personal Information that we collect are
              used for providing and improving the Service. We will not use or share your
              information with anyone except as described in this Privacy Policy.{" "}
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              The terms used in this Privacy Policy have the same meanings as in our Terms and
              Conditions, which is accessible at Hummingbird Client APP, unless otherwise defined in
              this Privacy Policy.
            </Typography>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Information Collection and Use{" "}
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              For a better experience while using our Service, we may require you to provide us with
              certain personally identifiable information, including but not limited to users name,
              address, location. The information that we request is retained on your device and is
              not collected by us in any way and will be retained by us and used as described in
              this privacy policy.
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              The app does use third party services that may collect information used to identify
              you.
            </Typography>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Data
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              We want to inform you that whenever you use our Service, in case of an error in the
              app we may collect data and information (through third party products) on your phone
              called Log Data. This Log Data may include information such as your devices’s Internet
              Protocol (“IP”) address, device name, operating system version, configuration of the
              app when utilising our Service, the time and date of your use of the Service, and
              other statistics.
            </Typography>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Cookies{" "}
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              Cookies are files with small amount of data that is commonly used an anonymous unique
              identifier. These are sent to your browser from the website that you visit and are
              stored on your devices’s internal memory.
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              This Services does not uses these “cookies” explicitly. However, the app may use third
              party code and libraries that use “cookies” to collection information and to improve
              their services. You have the option to either accept or refuse these cookies, and know
              when a cookie is being sent to your device. If you choose to refuse our cookies, you
              may not be able to use some portions of this Service.
            </Typography>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Security
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              We value your trust in providing us your Personal Information, thus we are striving to
              use commercially acceptable means of protecting it. But remember that no method of
              transmission over the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </Typography>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Children’s Privacy
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              This Services do not address anyone under the age of 13. We do not knowingly collect
              personal identifiable information from children under 13. In the case we discover that
              a child under 13 has provided us with personal information, we immediately delete this
              from our servers. If you are a parent or guardian and you are aware that your child
              has provided us with personal information, please contact us so that we will be able
              to do necessary actions.
            </Typography>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Changes to This Privacy Policy
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              We may update our Privacy Policy from time to time. Thus, you are advised to review
              this page periodically for any changes. We will notify you of any changes by posting
              the new Privacy Policy on this page. These changes are effective immediately, after
              they are posted on this page.
            </Typography>
            <Typography fontSize={"30px"} fontWeight={"500"}>
              Contact Us
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"} pb={"10px"}>
              If you have any questions or suggestions about our Privacy Policy, do not hesitate to
              contact us.
            </Typography>
          </Container>
        </Grid>
      </Grid>
      <Footer></Footer>
    </Container>
  );
};

export default Policy;
