import { Box, Container, Grid, Typography } from "@mui/material";
import { CustomizedImageBoxNoBlur } from "./components/styledComponent";
import Footer from "./footer";
import Header from "./header";

const whoWeAre = "Who we";
const AboutUs = () => {
  return (
    <Container maxWidth={false} className="About-us" disableGutters sx={{ color: "#283e4a" }}>
      <Grid container rowGap={1}>
        <Grid item height={"60px"} xs={12} alignItems={"center"} container>
          <Header></Header>
        </Grid>
        <Grid item height={"40vh"} position={"relative"} xs={12}>
          <CustomizedImageBoxNoBlur className="about-us-hero">
            <Grid item container height={"inherit"} justifyContent={"center"} alignItems={"center"}>
              <Typography fontSize={"80px"} color={"white"} textAlign={"center"} lineHeight={1}>
                {whoWeAre}
                <br /> are?
              </Typography>
            </Grid>
          </CustomizedImageBoxNoBlur>
        </Grid>
        <Grid item container>
          {/* body */}
          <Container>
            <Grid container spacing={2}>
              <Grid xs={8} item alignItems={"flex-end"} container>
                <Typography textAlign={"justify"} fontSize={"15"} lineHeight={2}>
                  My name is Stephanie Beckett and I am the owner of Hummingbird out of School Care,
                  I have been a qualified childcare practitioner for 25 years, during that time I
                  have worked in many different establishments and learned so many different skills
                  and ways of learning and teaching children. <br></br>Starting my own childcare
                  business was a good decision as I thoroughly enjoy running my own business and
                  providing a service for so many different families.{" "}
                </Typography>
              </Grid>
              <Grid xs={4} item>
                <Box sx={{ borderRadius: "5px" }} minHeight={"400px"} className="about-me"></Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item container justifyContent={"center"} alignItems={"center"} mt={2}>
          <Container fixed maxWidth={"md"}>
            <Typography fontSize={"40px"} textAlign={"center"} color={"#283e4a"}>
              About us
            </Typography>
            <Typography fontSize={"20px"} lineHeight={2} textAlign={"justify"}>
              Hummingbird out of school care has been opened since 2016, we provide a service for
              school aged children Monday to Friday. We are located at Life Church,35 Moss St,
              Paisley. <br />
              The service is opened for Breakfast club 7am-9am. After school care 3pm-6pm and
              7am-6pm during all school holidays. We drop off and collect from Williamsburgh
              Primary, St John Ogilvie’s, West Primary, St Marys, St Charles, Lochfield, Mossvale
              primary and St James Primary. We provide snack for the children during term-time,
              carry out various activities including baking and crafts. <br />
              During school holidays parents must provide a packed lunch, we go out to many
              different places and have movie days in. We provide a quiet area for the children to
              read books and have rest time. <br />
              There is a large selection of games, jigsaws, construction, arts and crafts, home
              corner, role play and consoles. There is a separate room for the children to play
              table tennis and various other games.
              <br />
              We also use this room for our older children to play more age appropriate games, read
              books and play console games for their appropriate age. Our main priority when a child
              first joins Hummingbird is that both the child and parent/carer are happy with the
              service.
              <br /> We encourage as many visits as possible in order for your child to feel
              comfortable within the setting and become familiar with the staff.
            </Typography>
          </Container>
        </Grid>
      </Grid>
      <Footer></Footer>
    </Container>
  );
};

export default AboutUs;
