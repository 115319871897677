import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import { Formik, FormikProps, Form } from "formik";
import { useContext } from "react";
import { ProfileDetails } from "./ProfileDetails";
import formFields from "./formFields";
import { AddressDetails } from "./AddressDetails";
import { validationSchema } from "./validationSchema";
import { ArrowBackIos } from "@mui/icons-material";
import { IAuth, IDbUser } from "../../slices/interfaces";
import { useAppDispatch } from "../../modules/hooks/store";
import { createUser } from "../../slices/user";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../modules/context";
import { showAlert } from "../../slices/alert";

const steps = ["Update Profile", "Address details", "Submit"];

function _renderStepContent(step: number, props: FormikProps<any>) {
  switch (step) {
    case 0:
      return <ProfileDetails {...formFields} {...props} />;
    case 1:
      return <AddressDetails {...formFields} {...props} />;
    default:
      return (
        <Box
          height={"200px"}
          // alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          display={"flex"}
        >
          <Typography fontSize={"40px"}>Submit Application</Typography>
        </Box>
      );
  }
}

export default function Register() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const currentValidationSchema = validationSchema[activeStep];

  const { user } = useContext(AuthContext) as IAuth;
  const dispatch = useAppDispatch();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const navigate = useNavigate();

  const _handleSubmit = async (values: IDbUser) => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (isLastStep()) {
      try {
        await dispatch(createUser(values));
        navigate(`/user/${user?.uid}`, { replace: true });
        dispatch(
          showAlert({
            message: "Registration completed",
            severity: "success",
          })
        );
      } catch (e) {
        console.log(e);
        dispatch(
          showAlert({
            message: "An error occurred, pleas contact Admin",
            severity: "error",
          })
        );
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  return (
    <>
      <Container>
        <Grid
          container
          maxWidth={"xl"}
          justifyContent={"center"}
          alignItems={"center"}
          pt={"20px"}
          direction={"column"}
        >
          <Grid item>
            <Typography fontSize={"40px"} fontWeight={700}>
              Create profile
            </Typography>
          </Grid>
          <Grid item container>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                <Box height={"60vh"}>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: "70vh" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent={"center"}
                      alignItems="center"
                      bgcolor="#e8fdfe"
                      borderRadius="12px"
                      width="80%"
                      padding={"20px"}
                      height="500px"
                    >
                      <Formik
                        initialValues={
                          {
                            firstName: "",
                            lastName: "",
                            email: user?.email,
                            address: "",
                            postCode: "",
                            city: "",
                          } as IDbUser
                        }
                        validationSchema={currentValidationSchema}
                        onSubmit={_handleSubmit}
                      >
                        {(props: FormikProps<any>) => (
                          <Box width="80%">
                            <Form>
                              {_renderStepContent(activeStep, props)}

                              <Grid rowSpacing={10} container>
                                <Grid
                                  item
                                  md={6}
                                  display={{ md: activeStep !== 2 ? "block" : "none" }}
                                >
                                  <Button
                                    variant={"contained"}
                                    // fullWidth
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                    startIcon={<ArrowBackIos />}
                                  >
                                    Back
                                  </Button>
                                </Grid>
                                <Grid item md={activeStep !== 2 ? 6 : 12}>
                                  <Button
                                    variant={"contained"}
                                    fullWidth
                                    type="submit"
                                    disabled={props.isSubmitting}
                                    sx={{
                                      borderRadius: "20px",
                                    }}
                                  >
                                    {completedSteps() === totalSteps() - 1 ? (
                                      props.isSubmitting ? (
                                        <CircularProgress size={15} />
                                      ) : (
                                        "Submit"
                                      )
                                    ) : (
                                      "Complete Step"
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Form>
                          </Box>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
