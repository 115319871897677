import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import {
  IStudentData,
  IEvent,
  IPupilBooking,
  ICurrentMonthBooking,
  defaultErrorMessage,
} from "./interfaces";

const initialState: {
  students: IStudentData[];
  isLoading: boolean;
  isLoadingPupilBooking: boolean;
  isSavingPupilBooking: boolean;
  errorMessage: string;
  bankHolidays: IEvent[];
  pupilBooking: IPupilBooking[];
  todaysBooking: ICurrentMonthBooking[];
} = {
  students: [],
  isLoading: false,
  isLoadingPupilBooking: false,
  errorMessage: "",
  bankHolidays: [],
  pupilBooking: [],
  isSavingPupilBooking: false,
  todaysBooking: [],
};

export const getStudents = createAsyncThunk<
  { result: IStudentData[]; bankHolidays: IEvent[] },
  void,
  { rejectValue: string }
>("bookings/students", async (_, { rejectWithValue }) => {
  try {
    const ret = await Api.getBookings();
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const getPupilBooking = createAsyncThunk<IPupilBooking[], string, { rejectValue: string }>(
  "/pupil/booking",
  async (pupilId, { rejectWithValue }) => {
    try {
      const ret = await Api.getPupilBooking(pupilId);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const createBooking = createAsyncThunk<void, IPupilBooking, { rejectValue: string }>(
  "/pupil/createBooking",
  async (data, { rejectWithValue }) => {
    try {
      console.log("calling db.....");
      const ret = await Api.createBooking(data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);
export const getTodayBooking = createAsyncThunk<
  ICurrentMonthBooking[],
  boolean,
  { rejectValue: string }
>("/pupil/todayBooking", async (month, { rejectWithValue }) => {
  try {
    console.log("calling db.....");
    const ret = await Api.getTodayBooking(month);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    resetPupilBooking: (state) => {
      state.pupilBooking = [];
    },
  },
  extraReducers: (builder) => {
    // Get students
    builder.addCase(getStudents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.students = payload.result;
      state.bankHolidays = payload.bankHolidays;
    });
    builder.addCase(getStudents.pending, (state, _) => {
      state.students = [];
      state.isLoading = true;
    });
    builder.addCase(getStudents.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Get pupil booking
    builder.addCase(getPupilBooking.fulfilled, (state, { payload }) => {
      state.pupilBooking = payload;
      state.isLoadingPupilBooking = false;
    });
    builder.addCase(getPupilBooking.pending, (state, _) => {
      state.isLoadingPupilBooking = true;
    });
    builder.addCase(getPupilBooking.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingPupilBooking = false;
      console.log(state.errorMessage);
    });

    // Save pupil booking
    builder.addCase(createBooking.fulfilled, (state, { payload }) => {
      state.isSavingPupilBooking = false;
    });
    builder.addCase(createBooking.pending, (state, _) => {
      state.isSavingPupilBooking = true;
    });
    builder.addCase(createBooking.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isSavingPupilBooking = false;
      console.log(state.errorMessage);
    });

    // Get today's booking
    builder.addCase(getTodayBooking.fulfilled, (state, { payload }) => {
      state.isLoadingPupilBooking = false;
      state.todaysBooking = payload;
    });
    builder.addCase(getTodayBooking.pending, (state, _) => {
      console.log("Loading it");
      state.todaysBooking = [];
      state.isLoadingPupilBooking = true;
    });
    builder.addCase(getTodayBooking.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingPupilBooking = false;
      console.log(state.errorMessage);
    });
  },
});
export const isLoading = (state: RootState) => state.bookings.isLoading;
export const isLoadingPupilBooking = (state: RootState) => state.bookings.isLoadingPupilBooking;
export const studentsData = (state: RootState) => state.bookings.students;
export const errorMessage = (state: RootState) => state.bookings.errorMessage;
export const bankHolidays = (state: RootState) => state.bookings.bankHolidays;
export const pupilBooking = (state: RootState) => state.bookings.pupilBooking;
export const todaysBooking = (state: RootState) => state.bookings.todaysBooking;
export const isSavingPupilBooking = (state: RootState) => state.bookings.isSavingPupilBooking;

export const { resetPupilBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
