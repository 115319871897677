import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, Auth } from "firebase/auth";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
import config from "./config";
import { getStorage, connectStorageEmulator, FirebaseStorage } from "firebase/storage";

let auth: Auth;
const app = initializeApp(config.firebase);
let storage: FirebaseStorage;
const analytics = getAnalytics(app);
if (process.env.NODE_ENV === "development") {
  auth = getAuth();
  storage = getStorage(app);
  connectAuthEmulator(auth, "http://127.0.0.1:9091");
  connectStorageEmulator(storage, "localhost", 9199);
  setAnalyticsCollectionEnabled(analytics, true);
} else {
  auth = getAuth(app);
  storage = getStorage(app);
}

export { auth, analytics };
