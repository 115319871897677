import { Dialog, DialogTitle, DialogContent, CircularProgress, MenuItem, Box } from "@mui/material";
import { FC, useMemo } from "react";
import {
  editTransactionCategory,
  groupedPeriodTransactions,
  transactionCategories,
} from "../../slices/banking";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { IBankTransactionData } from "../../slices/interfaces";
import { Form, Formik, FormikProps } from "formik";
import { CustomizedTextField, CustomizedButton } from "../styledComponent";
import { showAlert } from "../../slices/alert";

interface EditTransactionCategoryProps {
  open: boolean;
  handleClose: () => void;
  transaction: IBankTransactionData | undefined;
}

const EditTransactionCategory: FC<EditTransactionCategoryProps> = ({
  open,
  handleClose,
  transaction,
}): JSX.Element => {
  const allTransactionCategories = useAppSelector(transactionCategories);
  const dispatch = useAppDispatch();

  const categoryDropdown = useMemo(() => {
    if (allTransactionCategories.length > 0) {
      return [...allTransactionCategories]
        .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
        .map((category) => (
          <MenuItem key={category._id} value={category._id} sx={{ fontSize: "12px" }}>
            {category.categoryName}
          </MenuItem>
        ));
    }
  }, [allTransactionCategories]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        fullWidth
        sx={{ textAlign: "center" }}
      >
        <DialogTitle variant="h5">Edit Transaction</DialogTitle>
        <DialogContent>
          {false ? (
            <CircularProgress color="success" />
          ) : (
            <Formik
              initialValues={{
                category: transaction?.category?._id ?? "",
                transactionId: transaction?._id ?? "",
              }}
              onSubmit={async (values: { category: string; transactionId: string }) => {
                const ret = await dispatch(
                  editTransactionCategory({
                    ...values,
                  })
                );
                if (!editTransactionCategory.fulfilled.match(ret)) {
                  dispatch(
                    showAlert({
                      message: `${ret.payload!}`,
                      severity: "error",
                    })
                  );
                } else {
                  dispatch(showAlert({ message: "Success", severity: "success" }));
                  dispatch(groupedPeriodTransactions());
                  handleClose();
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                isSubmitting,
              }: FormikProps<{ category: string; transactionId: string }>) => (
                <Form>
                  <Box display={"flex"} flexDirection={"column"}>
                    <CustomizedTextField
                      id="transactionDate"
                      name="transactionDate"
                      label="Transaction date"
                      disabled
                      size="small"
                      value={transaction?.transactionDate}
                    />
                    <CustomizedTextField
                      id="transactionDescription"
                      name="transactionDescription"
                      label="Transaction description"
                      disabled
                      multiline
                      minRows={4}
                      size="small"
                      value={transaction?.transactionDescription}
                    />
                    <CustomizedTextField
                      id="category"
                      value={values.category}
                      label="Category"
                      size="small"
                      select
                      sx={{
                        textAlign: "left",
                      }}
                      error={touched.category && Boolean(errors.category)}
                      onChange={(e) => {
                        setFieldValue("category", e.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select category</em>
                      </MenuItem>
                      {categoryDropdown}
                    </CustomizedTextField>
                    <CustomizedButton type="submit" variant="contained" color="success">
                      {!isSubmitting ? (
                        "Save"
                      ) : (
                        <CircularProgress size={20} sx={{ color: "white" }} />
                      )}
                    </CustomizedButton>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditTransactionCategory;
