import {
  Copyright,
  EmailRounded,
  Facebook,
  LinkedIn,
  LocationOnOutlined,
  MobileFriendly,
} from "@mui/icons-material";
import { Avatar, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Grid
      item
      container
      mt={10}
      alignItems={"center"}
      sx={{ backgroundColor: "#283e4a", color: "lightgray" }}
    >
      <Container fixed maxWidth={"md"} sx={{ p: "50px" }}>
        <Grid container spacing={5}>
          <Grid container item xs={12} justifyContent={"center"}>
            <Avatar
              onClick={() => console.log("LinkedIn")}
              sx={{ bgcolor: "white", color: "#0a66c2", mr: 1, size: "10px", cursor: "pointer" }}
            >
              <LinkedIn fontSize="small"></LinkedIn>
            </Avatar>
            <Link
              to="https://www.facebook.com/hbirdosc"
              style={{ color: "#0d86ee", cursor: "pointer" }}
            >
              <Avatar sx={{ bgcolor: "white", color: "#0d86ee", cursor: "pointer" }}>
                <Facebook fontSize="small"></Facebook>
              </Avatar>
            </Link>
          </Grid>
          <Grid container item xs={12} maxWidth={"md"}>
            <Grid item xs={12}>
              <Typography textAlign={"center"} variant={"h5"}>
                Contact Info
              </Typography>
              <Grid container item justifyContent={"center"}>
                <Grid item container>
                  <Grid item container alignItems={"end"} justifyContent={"center"}>
                    <LocationOnOutlined fontSize="medium" />
                    <Typography variant={"body1"}> | 35, Moss Street, Paisley</Typography>
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item container alignItems={"end"} justifyContent={"center"}>
                    <MobileFriendly fontSize="medium" />
                    <Typography variant={"body1"}> | 01415623827</Typography>
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item container alignItems={"end"} justifyContent={"center"}>
                    <EmailRounded fontSize="medium" />
                    <Typography variant={"body1"}> | info@hummingbirdosc.com</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign={"center"} color={"lightgray"} variant={"h6"}>
                Registered in Scotland SC507184
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} sx={{ bgcolor: "black" }}>
        <Container
          fixed
          maxWidth={"md"}
          sx={{
            textAlign: "center",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "grey",
          }}
        >
          <Copyright fontSize="small" />
          <Typography display={"inline"} fontSize={"20px"} ml={"10px"}>
            Website Designed & Developed by
          </Typography>
          <Link
            to="https://app.yamijo.com"
            style={{ textDecoration: "none", color: "grey", marginLeft: "5px" }}
          >
            <Typography display={"inline"} fontSize={"20px"} color={"white"}>
              {" "}
              Yamijo
            </Typography>
          </Link>
        </Container>
      </Container>
    </Grid>
  );
};

export default Footer;
