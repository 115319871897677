import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IAttendanceData, IBreakfastClubMonthHoliday, IBreakfastClub } from "./interfaces";

const initialState: {
  attendance: IAttendanceData[];
  isLoading: boolean;
  errorMessage: string;
  pupilAttendance?: IAttendanceData[];
  monthAttendance?: IAttendanceData[];
  monthBreakFastAttendance?: IBreakfastClub[];
  currentMonthHolidays?: string[];
  isLoadingMonthBreakFastAttendance: boolean;
} = {
  attendance: [],
  isLoading: false,
  errorMessage: "",
  pupilAttendance: [],
  monthAttendance: [],
  monthBreakFastAttendance: [],
  currentMonthHolidays: [],
  isLoadingMonthBreakFastAttendance: false,
};

export const createAttendance = createAsyncThunk<
  IAttendanceData[],
  { pupilId: string; dayOfMonth?: string; fullDay?: boolean },
  { rejectValue: string }
>("create", async ({ pupilId, dayOfMonth, fullDay }, { rejectWithValue }) => {
  try {
    const ret = await Api.createAttendance(pupilId, dayOfMonth, fullDay);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});
export const deleteAttendance = createAsyncThunk<
  IAttendanceData[],
  { pupilId: string; dayOfMonth?: string },
  { rejectValue: string }
>("create", async ({ pupilId, dayOfMonth }, { rejectWithValue }) => {
  try {
    const ret = await Api.deleteAttendance(pupilId, dayOfMonth);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const signOutAttendance = createAsyncThunk<IAttendanceData[], string, { rejectValue: string }>(
  "signOut",
  async (pupilId, { rejectWithValue }) => {
    try {
      const ret = await Api.signOutAttendance(pupilId);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);
interface IGetAttendance {
  month: boolean;
  pupilId?: string;
}

export const getAttendance = createAsyncThunk<IAttendanceData[], IGetAttendance, { rejectValue: string }>(
  "get",
  async ({ month, pupilId }, { rejectWithValue }) => {
    try {
      const ret = await Api.getAttendance(month, pupilId);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);
export const getBreakFastAttendance = createAsyncThunk<IBreakfastClubMonthHoliday, void, { rejectValue: string }>(
  "getBreakFastAttendance",
  async (_, { rejectWithValue }) => {
    try {
      const ret = await Api.getBreakFastAttendance();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const updateBreakFastAttendance = createAsyncThunk<
  IBreakfastClubMonthHoliday,
  { pupilId: string; date: string; isSignedIn: boolean },
  { rejectValue: string }
>("updateBreakFastAttendance", async ({ pupilId, date, isSignedIn }, { rejectWithValue }) => {
  try {
    const ret = await Api.updateBreakFastAttendance(pupilId, date, isSignedIn);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const attendanceSlice = createSlice({
  name: "attendances",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get students

    // Create attendance
    builder.addCase(createAttendance.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.meta.arg.pupilId && action.meta.arg.dayOfMonth) {
        state.pupilAttendance = action.payload;
      } else {
        state.attendance = action.payload;
      }
    });
    builder.addCase(createAttendance.pending, (state, action) => {
      if (!action.meta.arg.dayOfMonth) {
        state.isLoading = true;
      }
    });
    builder.addCase(createAttendance.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoading = false;
      console.log(state.errorMessage);
    });

    // Get today's attendance
    builder.addCase(getAttendance.fulfilled, (state, action) => {
      if (action.meta.arg.pupilId) {
        state.pupilAttendance = action.payload;
      } else if (action.meta.arg.month) {
        state.monthAttendance = action.payload;
      } else {
        state.attendance = action.payload;
      }
      state.isLoading = false;
    });
    builder.addCase(getAttendance.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(getAttendance.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoading = false;
      console.log(state.errorMessage);
    });
    //  Sign out attendance
    builder.addCase(signOutAttendance.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.attendance = payload;
    });
    builder.addCase(signOutAttendance.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(signOutAttendance.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoading = false;
      console.log(state.errorMessage);
    });

    builder.addCase(getBreakFastAttendance.fulfilled, (state, { payload }) => {
      state.isLoadingMonthBreakFastAttendance = false;
      state.monthBreakFastAttendance = payload.bookings;
      state.currentMonthHolidays = payload.currentMonthHolidays;
    });
    builder.addCase(getBreakFastAttendance.pending, (state, _) => {
      state.isLoadingMonthBreakFastAttendance = true;
    });
    builder.addCase(getBreakFastAttendance.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingMonthBreakFastAttendance = false;
      console.log(state.errorMessage);
    });

    builder.addCase(updateBreakFastAttendance.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.monthBreakFastAttendance = payload.bookings;
      state.currentMonthHolidays = payload.currentMonthHolidays;
    });
    // builder.addCase(updateBreakFastAttendance.pending, (state, _) => {
    //   // state.isLoading = true;
    // });
    builder.addCase(updateBreakFastAttendance.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoading = false;
      console.log(state.errorMessage);
    });
  },
});
export const isLoading = (state: RootState) => state.attendances.isLoading;
export const attendance = (state: RootState) => state.attendances.attendance;
export const monthAttendance = (state: RootState) => state.attendances.monthAttendance;
export const pupilAttendance = (state: RootState) => state.attendances.pupilAttendance;
export const monthBreakFastAttendance = (state: RootState) => state.attendances.monthBreakFastAttendance;
export const currentMonthHolidays = (state: RootState) => state.attendances.currentMonthHolidays;
export const isLoadingMonthBreakFastAttendance = (state: RootState) =>
  state.attendances.isLoadingMonthBreakFastAttendance;

export const errorMessage = (state: RootState) => state.bookings.errorMessage;

export default attendanceSlice.reducer;
