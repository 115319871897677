export const formFields = {
  firstName: {
    name: "firstName",
    label: "First name*",
    requiredErrorMsg: "First name is required",
  },
  lastName: {
    name: "lastName",
    label: "Last name*",
    requiredErrorMsg: "Last name is required",
  },
  address: {
    name: "address",
    label: "Address Line 1*",
    requiredErrorMsg: "Address is required",
  },
  postCode: {
    name: "postCode",
    label: "Post Code",
    requiredErrorMsg: "PostCode is required",
    invalidErrorMsg: "PostCode is required",
  },
  city: {
    name: "city",
    label: "City*",
    requiredErrorMsg: "City is required",
  },
  state: {
    name: "state",
    label: "State/Province/Region",
  },
  email: {
    name: "email",
    label: "Email*",
    requiredErrorMsg: "Email is required",
    invalidErrorMsg: "Email is required",
  },
  country: {
    name: "country",
    label: "Country*",
    requiredErrorMsg: "Country is required",
  },
  useAddressForPaymentDetails: {
    name: "useAddressForPaymentDetails",
    label: "Use this address for payment details",
  },
  nameOnCard: {
    name: "nameOnCard",
    label: "Name on card*",
    requiredErrorMsg: "Name on card is required",
  },
  cardNumber: {
    name: "cardNumber",
    label: "Card number*",
    requiredErrorMsg: "Card number is required",
    invalidErrorMsg: "Card number is not valid (e.g. 4111111111111)",
  },
  expiryDate: {
    name: "expiryDate",
    label: "Expiry date*",
    requiredErrorMsg: "Expiry date is required",
    invalidErrorMsg: "Expiry date is not valid",
  },
  cvv: {
    name: "cvv",
    label: "CVV*",
    requiredErrorMsg: "CVV is required",
    invalidErrorMsg: "CVV is invalid (e.g. 357)",
  },
};

export default formFields;
