import { Container, Grid, Typography } from "@mui/material";
import { CustomizedImageBoxNoBlur } from "./components/styledComponent";
import Footer from "./footer";
import Header from "./header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { getBlob, getStorage, ref } from "firebase/storage";

const storage = getStorage();

const Forms = () => {
  const _downloadPdf = async (fileName: string) => {
    try {
      getBlob(ref(storage, `forms/${fileName}.pdf`))
        .then((url) => {
          _exportData(url, `${fileName}.pdf`, "application/pdf");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const _exportData = (data: Blob, fileName: string, type: string) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Container maxWidth={false} className="About-us" disableGutters sx={{ color: "#283e4a" }}>
      <Grid container rowGap={1}>
        <Grid item height={"60px"} xs={12} alignItems={"center"} container>
          <Header></Header>
        </Grid>
        <Grid item height={"40vh"} position={"relative"} xs={12}>
          <CustomizedImageBoxNoBlur className="forms-hero">
            <Grid item container height={"inherit"} justifyContent={"center"} alignItems={"center"}>
              <Typography fontSize={"80px"} color={"white"} textAlign={"center"} lineHeight={1}>
                Forms
              </Typography>
            </Grid>
          </CustomizedImageBoxNoBlur>
        </Grid>
        <Container maxWidth={"md"}>
          <Grid item container xs={12} rowGap={1}>
            <Grid container item minHeight={"200px"} sx={{ border: "1px grey solid" }}>
              <Grid item xs={6} container sx={{ borderRight: "0px grey solid", p: 3 }}>
                <Typography fontSize={"30px"}>Booking</Typography>
                <Typography>
                  Our booking form allows you to state the days and sessions required, which school
                  your child attends and contact details. If you require flexible days then please
                  state this on the form. If you would like to book your child in for any extra days
                  then please contact us.
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent={"center"}
                alignItems={"center"}
                container
                direction={"column"}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size={"5x"}
                  onClick={() => _downloadPdf("booking")}
                  style={{ cursor: "pointer" }}
                />
                <Typography>Click to download</Typography>
              </Grid>
            </Grid>
            <Grid container item minHeight={"200px"} sx={{ border: "1px grey solid" }}>
              <Grid item xs={6} container sx={{ borderRight: "0px grey solid", p: 3 }}>
                <Typography fontSize={"30px"}>Client application</Typography>
                <Typography>
                  The application form allows hummingbird to gather the child’s important
                  information including Name, contact details, emergency contact likes/dislikes and
                  any allergies we need to know about. This will be followed on by a care plan which
                  is created by the children to let us know more about them, this is updated
                  regularly.
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent={"center"}
                alignItems={"center"}
                container
                direction={"column"}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size={"5x"}
                  onClick={() => _downloadPdf("application")}
                  style={{ cursor: "pointer" }}
                />
                <Typography>Click to download</Typography>
              </Grid>
            </Grid>
            <Grid container item minHeight={"200px"} sx={{ border: "1px grey solid" }}>
              <Grid item xs={6} container sx={{ borderRight: "0px grey solid", p: 2 }}>
                <Typography fontSize={"30px"}>Permission</Typography>
                <Typography>
                  The permission forms are for you to give permission for your child to use our
                  consoles with age appropriate games, watch movies with a U rating, have their
                  photographs taken, go on public transport during term time and school holidays,
                  have their photograph posted on social media, have sun cream applied, have
                  observations carried out on them, use our outdoor equipment, have their
                  information shared with other professional bodies if required.
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent={"center"}
                alignItems={"center"}
                container
                direction={"column"}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size={"5x"}
                  onClick={() => _downloadPdf("permission")}
                  style={{ cursor: "pointer" }}
                />
                <Typography>Click to download</Typography>
              </Grid>
            </Grid>
            <Grid container item minHeight={"200px"} sx={{ border: "1px grey solid" }}>
              <Grid item xs={6} container sx={{ borderRight: "0px grey solid", p: 3 }}>
                <Typography fontSize={"30px"}>Policy</Typography>
                <Typography>
                  Hummingbird out of school care have developed a number of policies and procedures
                  which are required and to be followed to run our out of school care. Policies and
                  procedures help and guide all staff working in the setting. They ensure and
                  endorse the well-being of all families, children, staff, volunteers and everyone
                  who is connected to the setting. If you would like a copy of these sent to you by
                  email please let us know.
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent={"center"}
                alignItems={"center"}
                container
                direction={"column"}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size={"5x"}
                  onClick={() => _downloadPdf("policy")}
                  style={{ cursor: "pointer" }}
                />
                <Typography>Click to download</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Footer></Footer>
    </Container>
  );
};

export default Forms;
