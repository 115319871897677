import { Avatar, Container } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth={"md"} disableGutters sx={{ color: "#283e4a" }}>
      <Avatar
        sx={{ bgcolor: "white", border: "1px green solid", cursor: "pointer" }}
        onClick={() => navigate("/", { replace: true })}
      >
        <HomeIcon color="success" sx={{ fontSize: "30px" }}></HomeIcon>
      </Avatar>
    </Container>
  );
};

export default Header;
