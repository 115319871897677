import { TextField } from "@mui/material";
import { FormikProps, ErrorMessage } from "formik";
import formFields from "./formFields";

export const ProfileDetails = ({
  firstName,
  lastName,
  email,
  values,
  handleChange,
  touched,
  errors,
}: typeof formFields & FormikProps<any>): JSX.Element => {
  return (
    <>
      <TextField
        id="email"
        name={email.name}
        placeholder="john@acme.com"
        label={email.label}
        type="email"
        fullWidth
        value={values.email}
        disabled
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        sx={{ marginY: "20px" }}
      />
      <TextField
        id={firstName.name}
        name={firstName.name}
        label={firstName.label}
        fullWidth
        value={values.firstName}
        onChange={handleChange}
        error={touched.firstName && Boolean(errors.firstName)}
        sx={{ marginY: "10px" }}
      />
      <ErrorMessage name="firstName">
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
      <TextField
        id={lastName.name}
        name={lastName.name}
        label={lastName.label}
        fullWidth
        value={values.lastName}
        onChange={handleChange}
        error={touched.lastName && Boolean(errors.lastName)}
        sx={{ marginY: "10px" }}
      />
      <ErrorMessage name="lastName">
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
    </>
  );
};
