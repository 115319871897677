import { TableCell, Avatar, Grid, IconButton, TextField, Stack } from "@mui/material";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { Datum } from "../../slices/interfaces";
import { showAlert } from "../../slices/alert";
import { useAppDispatch } from "../../modules/hooks/store";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { deleteInvoice, sendInvoice, updateInvoice } from "../../slices/invoice";
import { formatNumber } from "../../utils";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AutoModeIcon from "@mui/icons-material/AutoMode";

const TextFieldTravelState = ({
  invoice,
  setTravel,
  travel,
}: {
  invoice: Datum;
  setTravel: (val: number) => void;
  travel: number;
}) => {
  return (
    <TextField
      id="travel"
      value={travel}
      size="small"
      type="text"
      disabled={invoice.paid}
      sx={{
        textAlign: "left",
      }}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      InputProps={{
        sx: { height: "20px", fontSize: "12px", p: 0, m: 0 },
      }}
      fullWidth
      onChange={(e) => {
        const val = e.target.value === "" ? 0 : parseInt(e.target.value);
        setTravel(val);
      }}
    ></TextField>
  );
};

const TextFieldDiscountState = ({
  invoice,
  setDiscount,
  discount,
}: {
  invoice: Datum;
  setDiscount: (val: number) => void;
  discount: number;
}) => {
  return (
    <TextField
      id="discount"
      value={discount}
      size="small"
      type="text"
      disabled={invoice.paid}
      sx={{
        textAlign: "left",
      }}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      InputProps={{
        sx: { height: "20px", fontSize: "12px", p: 0, m: 0 },
      }}
      fullWidth
      onChange={(e) => {
        const val = e.target.value === "" ? 0 : parseInt(e.target.value);
        setDiscount(val);
      }}
    ></TextField>
  );
};
const TextFieldPartialPaymentState = ({
  invoice,
  setPartialPayment,
  partialPayment,
}: {
  invoice: Datum;
  setPartialPayment: (val: number) => void;
  partialPayment: number;
}) => {
  return (
    <TextField
      id="partialPayment"
      value={partialPayment}
      type="text"
      size="small"
      disabled={invoice.paid}
      sx={{
        textAlign: "left",
      }}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      InputProps={{
        sx: { height: "20px", fontSize: "12px", p: 0, m: 0 },
      }}
      fullWidth
      onChange={(e) => {
        const val = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
        setPartialPayment(val);
      }}
    ></TextField>
  );
};

const TableRowState = ({
  invoice,
  daysOfMonth,
  total,
  month,
  year,
  getInvoice,
}: {
  invoice: Datum;
  daysOfMonth: string[];
  total: number;
  month: number;
  year: number;
  getInvoice: (val: Datum) => void;
}) => {
  const [partialPayment, setPartialPayment] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [travel, setTravel] = useState<number>(0);
  const dispatch = useAppDispatch();

  const _saveHandler = async () => {
    const { pupilId } = invoice;
    if (discount + partialPayment >= total) {
      console.log("Mark as paid instead");
      dispatch(
        showAlert({
          message: `Partial payment & discount can not be higher than invoice\n. Mark invoice as paid instead`,
          severity: "error",
        })
      );
      return;
    }
    const ret = await dispatch(updateInvoice({ discount, partialPayment, pupilId, month, year, travel }));
    if (!updateInvoice.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      dispatch(
        showAlert({
          message: `Invoice for ${invoice.firstName} was successfully updated`,
          severity: "success",
        })
      );
    }
  };

  const _sendEmailHandler = async () => {
    const { pupilId } = invoice;

    const ret = await dispatch(sendInvoice({ pupilId, month, year }));
    if (!sendInvoice.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      dispatch(
        showAlert({
          message: `Invoice sent to ${invoice.firstName}`,
          severity: "success",
        })
      );
    }
  };
  const _deleteHandler = async () => {
    const { pupilId } = invoice;
    const ret = await dispatch(deleteInvoice({ pupilId, month, year }));
    if (!deleteInvoice.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      dispatch(
        showAlert({
          message: `Invoice deleted for ${invoice.firstName}`,
          severity: "success",
        })
      );
    }
    console.log("delete", { pupilId, month, year });
  };

  useEffect(() => {
    setPartialPayment(invoice.partialPayment);
    setDiscount(invoice.discount);
    setTravel(invoice.travel);
  }, [invoice.discount, invoice.partialPayment, invoice.travel]);

  return (
    <>
      <TableCell>
        <Stack direction={"row"}>
          <IconButton
            type="button"
            sx={{ p: 0 }}
            onClick={
              invoice.isAutoMarkedPaid && process.env.REACT_APP_FIREBASE_PROJECT_ID === "hummingbirdosc"
                ? undefined
                : () => getInvoice(invoice)
            }
          >
            {invoice.paid ? (
              invoice.isAutoMarkedPaid ? (
                <AutoModeIcon sx={{ color: "#ff4500" }} fontSize="small" />
              ) : (
                <CheckCircleOutlineIcon color="info" fontSize="small" />
              )
            ) : (
              <DoneIcon color={"disabled"} fontSize="small" />
            )}
          </IconButton>
          <MarkEmailReadIcon color={invoice.paid || invoice.read ? "success" : "disabled"} fontSize="small" />
        </Stack>
      </TableCell>
      <TableCell
        sx={{
          borderRight: "1px solid #ebebeb",
          color: invoice.paid ? "black" : invoice.partialPayment ? "#256479" : "red",
        }}
      >
        {invoice.firstName} {invoice.lastName}
      </TableCell>
      {daysOfMonth.map((date, i) => {
        const isSignedIn = invoice.record.some(
          (data) => format(parse(data.date, "dd/MM/yyyy", new Date()), "dd") === date
        );
        const isFullDay = invoice.record.some(
          (data) => format(parse(data.date, "dd/MM/yyyy", new Date()), "dd") === date && data.fullDay
        );
        return (
          <TableCell
            sx={{
              textAlign: "center",
              borderRight: "1px solid #ebebeb",
              p: 0,
            }}
            key={`${i}_${date}`}
          >
            <Avatar
              alt=""
              sx={{
                bgcolor: isSignedIn ? (isFullDay ? "yellow" : "#b2cee4") : "white",
                width: 12,
                height: 12,
                margin: "0 auto",
                border: isSignedIn ? "1px solid black" : "",
              }}
            >
              {""}
            </Avatar>
          </TableCell>
        );
      })}
      <TableCell
        sx={{
          textAlign: "center",
          borderRight: "1px solid #ebebeb",
        }}
      >
        <TextFieldTravelState invoice={invoice} travel={travel} setTravel={setTravel} />
      </TableCell>
      <TableCell
        sx={{
          textAlign: "center",
          borderRight: "1px solid #ebebeb",
        }}
      >
        <TextFieldDiscountState invoice={invoice} discount={discount} setDiscount={setDiscount} />
      </TableCell>
      <TableCell
        sx={{
          textAlign: "center",
          borderRight: "1px solid #ebebeb",
        }}
      >
        <TextFieldPartialPaymentState
          invoice={invoice}
          partialPayment={partialPayment}
          setPartialPayment={setPartialPayment}
        />
      </TableCell>
      <TableCell
        sx={{
          borderRight: "1px solid #ebebeb",
          textAlign: "right",
        }}
      >
        {formatNumber(total)}
      </TableCell>
      <TableCell
        sx={{
          borderRight: "1px solid #ebebeb",
        }}
      >
        <Grid container justifyContent={"space-around"}>
          <IconButton type="button" sx={{ p: 0 }} aria-label="search" onClick={_saveHandler}>
            <SaveOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
          <IconButton type="button" sx={{ p: 0 }} aria-label="sendMail" onClick={_sendEmailHandler}>
            <ForwardToInboxOutlinedIcon color="success" fontSize="small" />
          </IconButton>
          <IconButton type="button" sx={{ p: 0 }} aria-label="delete" onClick={_deleteHandler}>
            <DeleteForeverOutlinedIcon color="error" fontSize="small" />
          </IconButton>
        </Grid>
      </TableCell>
    </>
  );
};

export default TableRowState;
