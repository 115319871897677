import { parse } from "date-fns";
import { IBankTransactionData, financialPeriod } from "./slices/interfaces";

export const sortTransaction = (transformTransactions: IBankTransactionData[]) => {
  if (transformTransactions.length) {
    const copy = [...transformTransactions].sort(
      (a, b) =>
        parse(a.transactionDate, "dd/MM/yyyy", new Date()).getDate() -
        parse(b.transactionDate, "dd/MM/yyyy", new Date()).getDate()
    );
    const sortedOrder: IBankTransactionData[] = [];
    sortedOrder.push(copy[copy.length - 1]);
    const usedRowsIndex: number[] = [];
    for (let y = copy.length - 2; y >= 0; y--) {
      for (let x = copy.length - 2; x >= 0; x--) {
        if (!usedRowsIndex.includes(x)) {
          let thisRowIsDone = false;
          let diffVal = null;
          diffVal = copy[x].amount;
          let totalVal = null;
          totalVal = copy[x].balance;
          let topmostResContentBalance = null;
          topmostResContentBalance = sortedOrder[0].balance;
          let currentRowsDiffVal = null;
          currentRowsDiffVal = sortedOrder[sortedOrder.length - 1].amount;
          let lastResContentRowsBalance = null;
          lastResContentRowsBalance = sortedOrder[sortedOrder.length - 1].balance;
          if (parseFloat((totalVal + diffVal).toPrecision(6))) {
            sortedOrder.unshift(copy[x]);
            usedRowsIndex.push(x);
            thisRowIsDone = true;
            break;
          } else if (parseFloat((totalVal - diffVal).toPrecision(6)) === topmostResContentBalance) {
            sortedOrder.unshift(copy[x]);
            usedRowsIndex.push(x);
            thisRowIsDone = true;
            break;
          } else if (
            parseFloat((lastResContentRowsBalance! - currentRowsDiffVal).toPrecision(6)) ===
            totalVal
          ) {
            sortedOrder.push(copy[x]);
            usedRowsIndex.push(x);
            thisRowIsDone = true;
            break;
          } else if (
            parseFloat((lastResContentRowsBalance! + currentRowsDiffVal).toPrecision(6)) ===
            totalVal
          ) {
            sortedOrder.push(copy[x]);
            usedRowsIndex.push(x);
            thisRowIsDone = true;
            break;
          }
          if (thisRowIsDone) {
            thisRowIsDone = false;
            break;
          }
        }
      }
    }
    console.log(usedRowsIndex);
    console.log(sortedOrder);
  }
};

const FIN_START_MONTH = 6;
const FIN_END_MONTH = 5;

export const getFinancialYear = (): {
  [x in financialPeriod]: { currentFinancialStartDate: Date; currentFinancialEndDate: Date };
} => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  let currentFinancialYearStart = currentDate.getFullYear() - 1;
  let currentFinancialYearEnd = currentDate.getFullYear();
  if (currentMonth >= FIN_START_MONTH) {
    currentFinancialYearStart = currentDate.getFullYear();
    currentFinancialYearEnd = currentDate.getFullYear() + 1;
  }
  const currentFinancialStartDate = new Date(currentFinancialYearStart, FIN_START_MONTH, 1);
  const currentFinancialEndDate = new Date(
    currentFinancialYearEnd,
    FIN_END_MONTH + 1,
    0,
    23,
    59,
    59
  );
  return {
    current: {
      currentFinancialStartDate,
      currentFinancialEndDate,
    },
    previousOne: {
      currentFinancialStartDate: new Date(
        currentFinancialStartDate.getFullYear() - 1,
        currentFinancialStartDate.getMonth()
      ),
      currentFinancialEndDate: new Date(
        currentFinancialYearEnd - 1,
        FIN_END_MONTH + 1,
        0,
        23,
        59,
        59
      ),
    },
    previousTwo: {
      currentFinancialStartDate: new Date(
        currentFinancialStartDate.getFullYear() - 2,
        currentFinancialStartDate.getMonth()
      ),
      currentFinancialEndDate: new Date(
        currentFinancialYearEnd - 2,
        FIN_END_MONTH + 1,
        0,
        23,
        59,
        59
      ),
    },
  };
};

export const formatNumber = (value: number) => {
  return `${new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(value)}`;
};
