import {
  CssBaseline,
  Container,
  Collapse,
  Alert,
  Box,
  Avatar,
  Typography,
  Button,
  AlertColor,
} from "@mui/material";
import { useContext, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { IAuth } from "../../slices/interfaces";
import useAuth from "../../modules/hooks/auth";
import { AuthContext } from "../../modules/context";
import { Copyright } from "../copyRight";
import SendIcon from "@mui/icons-material/Send";

export function EmailVerification() {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [severity, setSeverity] = useState<AlertColor>("error");
  const { user } = useContext(AuthContext) as IAuth;
  const { verifyEmail } = useAuth();

  const onClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowAlert(true);
    setSeverity("success");
    setAlertMessage("Check your email");
    verifyEmail();
  };

  return (
    <Container component="main" sx={{ height: "100vh", alignContent: "center", display: "flex" }}>
      <CssBaseline />
      <Container
        style={{
          position: "absolute",
          top: 0,
          paddingTop: "50px",
        }}
      >
        {showAlert && (
          <Collapse in={showAlert}>
            {
              <Alert
                sx={{
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
                severity={severity}
                onClick={() => setShowAlert(false)}
              >
                {alertMessage}
              </Alert>
            }
          </Collapse>
        )}
      </Container>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h6"
          sx={{ width: "70%" }}
          fontWeight={400}
          fontSize={30}
          textAlign={"center"}
        >
          {`Hi ${user?.email}, thanks for registering. 
          Please check your email for the verification
          link or click the link below to send verification link to your email`}
        </Typography>
        <Button
          onClick={onClickHandler}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, width: "30%", textTransform: "none" }}
          endIcon={<SendIcon />}
        >
          <Typography>Send verification link again</Typography>
        </Button>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Container>
  );
}
