import * as Yup from "yup";
import formFields from "./formFields";
const { firstName, lastName, address, city, postCode } = formFields;

export const validationSchema = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [city.name]: Yup.string().nullable().required(`${city.requiredErrorMsg}`),
    [postCode.name]: Yup.string().required(`${postCode.requiredErrorMsg}`),
    // .test("len", `${postCode.invalidErrorMsg}`, (val) => val && val.length === 5),
    // [country.name]: Yup.string().nullable().required(`${country.requiredErrorMsg}`),
  }),
];
