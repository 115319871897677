import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IStudent, studentType } from "./interfaces";

const initialState: {
  data: IStudent[];
  isLoading: boolean;
  errorMessage: string;
} = {
  data: [],
  isLoading: false,
  errorMessage: "",
};

export const getStudents = createAsyncThunk<IStudent[], void, { rejectValue: string }>(
  "students/get",
  async (_, { rejectWithValue }) => {
    try {
      const ret = await Api.getStudents();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const createStudent = createAsyncThunk<IStudent[], studentType, { rejectValue: string }>(
  "students/create",
  async (data, { rejectWithValue }) => {
    try {
      const ret = await Api.createStudent(data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudents.fulfilled, (state, action: PayloadAction<IStudent[]>) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getStudents.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(getStudents.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Create Student
    builder.addCase(createStudent.fulfilled, (state, action: PayloadAction<IStudent[]>) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(createStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
  },
});
export const isLoading = (state: RootState) => state.students.isLoading;
export const data = (state: RootState) => state.students.data;
export const errorMessage = (state: RootState) => state.students.errorMessage;

export default studentSlice.reducer;
