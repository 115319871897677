import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import {
  adminUpdateUserAccountType,
  allUsers,
  deleteUser,
  getAllUsers,
  isLoadingParents,
  updateSuperAdminType,
} from "../../slices/user";
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams, GridRowsProp } from "@mui/x-data-grid";
import { Suspense, lazy, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AccountTypeEnum, IAuth, IDbUser } from "../../slices/interfaces";
import { Badge, Grid, MenuItem, Typography } from "@mui/material";
import { CustomizedDataGrid, CustomizedTextField } from "../styledComponent";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { showAlert } from "../../slices/alert";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../modules/context";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import PreviewIcon from "@mui/icons-material/Preview";
import PageLoadingSpinner from "../PageLoadingSpinner";
import ConfirmationDialog from "../ConfirmationDialog";
import { Delete } from "@mui/icons-material";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ClientDetails = lazy(() => import("./ClientDetails"));
const SuperAdminModal = lazy(() => import("./SuperAdminModal"));

type selectButtonProps = {
  params: GridRenderCellParams<IDbUser>;
  parentDropDown: JSX.Element[];
};
type summary = { [x in AccountTypeEnum | "REGISTERED"]: number };

const colors: string[] = ["orangeRed", "#2e7d32", "#1976d2", "#ededed", "#ffe9ec", "#e8f4f8"];

const sortOrder = Object.keys(AccountTypeEnum);

const GridComponent = ({
  title,
  value,
  color,
}: {
  title: AccountTypeEnum | "REGISTERED";
  value: number;
  color: string;
}) => (
  <Grid item xs={2} container bgcolor={color} sx={{ borderRadius: "5px" }}>
    <Grid item container justifyContent={"center"}>
      <Typography fontSize={"20px"} sx={{ textTransform: "capitalize" }}>
        {title.toLocaleLowerCase()}
      </Typography>
    </Grid>

    <Grid item container justifyContent={"center"}>
      <Typography fontSize={"20px"}> {value ?? 0}</Typography>
    </Grid>
  </Grid>
);

const SelectButton = ({ params, parentDropDown }: selectButtonProps) => {
  const [value, setValue] = useState<keyof typeof AccountTypeEnum>(params.row.accountType);
  const dispatch = useAppDispatch();

  return (
    <CustomizedTextField
      id="accountType"
      value={value}
      select
      size="small"
      sx={{
        textAlign: "left",
        backgroundColor: "white",
      }}
      InputProps={{
        sx: { height: "20px", fontSize: "12px", p: 0, m: 0 },
      }}
      fullWidth
      onChange={async (e) => {
        setValue(e.target.value as AccountTypeEnum);
        const ret = await dispatch(adminUpdateUserAccountType({ ...params.row, accountType: e.target.value }));
        if (!adminUpdateUserAccountType.fulfilled.match(ret)) {
          dispatch(
            showAlert({
              message: `${ret.payload!}`,
              severity: "error",
            })
          );
        } else {
          dispatch(showAlert({ message: "User account updated", severity: "success" }));
        }
      }}
    >
      <MenuItem disabled value="">
        <em>Account type</em>
      </MenuItem>
      {parentDropDown}
    </CustomizedTextField>
  );
};

const ClientDashboard = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(allUsers);
  const loadingParents = useAppSelector(isLoadingParents);
  const { dbUser } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSuperAdminModal, setOpenSuperAdminModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<IDbUser>();

  logEvent(analytics, "wClientDashboard", {
    firebase_screen_class: "wClientDashboard",
    page_title: "ClientDashboard",
  });

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const _handleCloseSuperAdminModal = useCallback(async () => {
    setOpenSuperAdminModal((prev) => !prev);
    setSelectedClient(undefined);
  }, []);

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState<boolean>(false);

  const accountSummary = useMemo(() => {
    const summary: summary = userData.reduce(
      (hash, user) => ({ ...hash, [user.accountType]: ++hash[user.accountType] || 1 }),
      {} as summary
    );
    summary.REGISTERED = userData.reduce(
      (acc, { createdAt }) =>
        acc +
        (new Date(createdAt!).getMonth() === new Date().getMonth() &&
        new Date(createdAt!).getFullYear() === new Date().getFullYear()
          ? 1
          : 0),
      0
    );
    return summary;
  }, [userData]);

  const _deleteUserAccount = async () => {
    if (selectedClient) {
      const { _id } = selectedClient;
      const ret = await dispatch(deleteUser(_id));
      if (!deleteUser.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
        setSelectedClient(undefined);
      } else {
        dispatch(
          showAlert({
            message: "User successfully deleted",
            severity: "success",
          })
        );
      }
      setIsDeleteUserModalOpen((prev) => !prev);
    }
  };

  async function _handleSuperAdminUpdate(): Promise<void> {
    if (selectedClient) {
      const { _id } = selectedClient;
      const ret = await dispatch(updateSuperAdminType(_id));
      if (!updateSuperAdminType.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
        setSelectedClient(undefined);
      } else {
        dispatch(
          showAlert({
            message: "User successfully updated to super admin",
            severity: "success",
          })
        );
      }
      setOpenSuperAdminModal((prev) => !prev);
    }
  }

  useEffect(() => {
    dispatch(getAllUsers());
    if (dbUser && dbUser.accountType !== AccountTypeEnum.ADMIN) {
      navigate("/", { replace: true });
    }
  }, [dispatch, dbUser, navigate]);

  const parentDropDown = useMemo(() => {
    if (userData.length > 0) {
      return Object.keys(AccountTypeEnum).map((type) => (
        <MenuItem key={type} value={type} sx={{ fontSize: "12px" }}>
          {type}
        </MenuItem>
      ));
    }
  }, [userData]);

  const rows: GridRowsProp<IDbUser> = [...userData!].sort(
    (a, b) => sortOrder.indexOf(a.accountType) - sortOrder.indexOf(b.accountType)
  );
  const columns: GridColumns = [
    {
      field: "Account",
      headerName: "",
      width: 30,
      renderCell: (params: GridRenderCellParams<IDbUser>) => {
        switch (params.row.accountType) {
          case AccountTypeEnum.ADMIN:
            return (
              <Badge
                badgeContent={params.row.appCounter ?? 0}
                max={999}
                showZero={true}
                sx={{
                  "& .MuiBadge-badge": {
                    bgcolor: "lightseagreen",
                    color: "white",
                    fontSize: "8px",
                    top: 5,
                    minWidth: "12px",
                    height: "12px",
                    padding: "0 4px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <AdminPanelSettingsIcon color="success" />
              </Badge>
            );
          case AccountTypeEnum.STAFF:
            return (
              <Badge
                badgeContent={params.row.appCounter ?? 0}
                showZero={true}
                max={999}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "8px",
                    bgcolor: "lightseagreen",
                    color: "white",
                    top: 5,
                    minWidth: "12px",
                    height: "12px",
                    padding: "0 4px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <GroupsIcon color="primary" />
              </Badge>
            );
          case AccountTypeEnum.CLIENT:
            return (
              <Badge
                badgeContent={params.row.appCounter ?? 0}
                showZero={true}
                max={999}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "8px",
                    bgcolor: "lightseagreen",
                    color: "white",
                    top: 5,
                    minWidth: "12px",
                    height: "12px",
                    padding: "0 4px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <PersonIcon sx={{ color: "orangeRed" }} />
              </Badge>
            );
          case AccountTypeEnum.LOCK:
            return (
              <Badge
                badgeContent={params.row.appCounter ?? 0}
                showZero={true}
                max={999}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "8px",
                    bgcolor: "lightseagreen",
                    color: "white",
                    top: 5,
                    minWidth: "12px",
                    height: "12px",
                    padding: "0 4px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <LockTwoToneIcon color="error" />
              </Badge>
            );
          default:
            return (
              <Badge
                badgeContent={params.row.appCounter ?? 0}
                showZero={true}
                max={999}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "8px",
                    bgcolor: "lightseagreen",
                    color: "white",
                    top: 5,
                    minWidth: "12px",
                    height: "12px",
                    padding: "0 4px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <NoAccountsIcon color="disabled" />
              </Badge>
            );
        }
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "kids",
      headerName: "Kids",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<IDbUser>) => (
        <Typography fontSize={"inherit"}>{params.row.kids?.length > 0 ? params.row.kids.length : "-"}</Typography>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      flex: 2,
      renderCell: (params: GridRenderCellParams<IDbUser>) => (
        <Typography fontSize={"inherit"}>
          {params.row.phoneNumber?.replace(/(\d{2})(\d{3})(\d{3})/, "($1) $2 $3")}
        </Typography>
      ),
    },
    {
      field: "accountType",
      headerName: "Account",
      width: 250,
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<IDbUser>) => (
        <SelectButton params={params} parentDropDown={parentDropDown!} />
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params: GridRowParams<IDbUser>) => [
        <GridActionsCellItem
          icon={
            params.row.accountType === AccountTypeEnum.ADMIN ? (
              <AddModeratorIcon color={"success"} />
            ) : (
              <PreviewIcon
                color={
                  params.row.accountType !== AccountTypeEnum.CLIENT || !params.row.kids?.length ? "disabled" : "success"
                }
              />
            )
          }
          label="Preview"
          disabled={![AccountTypeEnum.ADMIN, AccountTypeEnum.CLIENT].includes(params.row.accountType)}
          onClick={async () => {
            setSelectedClient(params.row);
            if (params.row.accountType === AccountTypeEnum.ADMIN) {
              setOpenSuperAdminModal((prev) => !prev);
            } else {
              setOpenModal(!openModal);
            }
          }}
          size={"large"}
        />,
        <GridActionsCellItem
          icon={<Delete color={params.row.accountType === AccountTypeEnum.ADMIN ? "disabled" : "error"} />}
          label="QR Code"
          onClick={
            params.row.accountType === AccountTypeEnum.ADMIN
              ? undefined
              : () => {
                  setIsDeleteUserModalOpen((prev) => !prev);
                  setSelectedClient(params.row);
                }
          }
          size={"large"}
        />,
      ],
    },
  ];

  return (
    <Grid container xl>
      <Grid item xs={12} mt={1} mb={1}>
        <Grid container sx={{ border: "1px solid #ebebeb", bgcolor: "white" }} columnGap={1} wrap="nowrap" p={1}>
          {[...Object.keys(AccountTypeEnum), "REGISTERED"].map((key, i) => (
            <GridComponent
              key={i}
              title={key as AccountTypeEnum | "REGISTERED"}
              value={accountSummary[key as AccountTypeEnum | "REGISTERED"]}
              color={colors[i]}
            ></GridComponent>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} height={"90vh"}>
        <CustomizedDataGrid
          rows={rows}
          columns={columns}
          rowHeight={35}
          loading={loadingParents && !isDeleteUserModalOpen}
          headerHeight={38}
          getRowId={(row) => row._id}
          sx={{ width: "100%", bgcolor: "white" }}
          getRowClassName={(params) => (params.row.accountType === AccountTypeEnum.LOCK ? `account-locked` : "")}
        />
      </Grid>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openModal && selectedClient && (
          <ClientDetails open={openModal} handleClose={handleCloseModal} client={selectedClient} />
        )}
      </Suspense>
      {isDeleteUserModalOpen && (
        <ConfirmationDialog
          open={isDeleteUserModalOpen}
          handleClose={() => {
            setIsDeleteUserModalOpen((prev) => !prev);
            setSelectedClient(undefined);
          }}
          message={`Do you want to delete ${selectedClient?.firstName}`}
          onSubmit={_deleteUserAccount}
          isUpdatepaymentModal
        />
      )}
      <Suspense fallback={<PageLoadingSpinner />}>
        {openSuperAdminModal && selectedClient && (
          <SuperAdminModal
            open={openSuperAdminModal}
            handleClose={_handleCloseSuperAdminModal}
            onSubmit={_handleSuperAdminUpdate}
            message={
              <>
                {`The action will update ${selectedClient.firstName}'s`}
                <WarningAmberIcon color="error" sx={{ py: 2, fontSize: 80, display: "block", m: "auto" }} />
                {`Super Admin status`}
              </>
            }
          />
        )}
      </Suspense>
    </Grid>
  );
};

export default ClientDashboard;
