import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IBlog } from "./interfaces";

const initialState: {
  data: IBlog[];
  isLoading: boolean;
  isCreatingBlog: boolean;
  errorMessage: string;
  blog: IBlog | undefined;
} = {
  data: [],
  isLoading: false,
  isCreatingBlog: false,
  errorMessage: "",
  blog: undefined,
};

export const getBlogs = createAsyncThunk<IBlog[], string | undefined, { rejectValue: string }>(
  "blogs/fetch",
  async (blogId, { rejectWithValue }) => {
    try {
      const ret = await Api.getBlogs(blogId);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);
export const createBlog = createAsyncThunk<IBlog, IBlog, { rejectValue: string }>(
  "blogs/create",
  async (data, { rejectWithValue }) => {
    try {
      const ret = await Api.createBlog(data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.data = action.payload;
    });
    builder.addCase(getBlogs.pending, (state, _) => {
      state.isLoading = true;
      state.errorMessage = "";
      state.data = [];
    });
    builder.addCase(getBlogs.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
    });

    builder.addCase(createBlog.fulfilled, (state, { payload }) => {
      state.isCreatingBlog = false;
      state.blog = payload;
    });
    builder.addCase(createBlog.pending, (state, _) => {
      state.isCreatingBlog = true;
      state.errorMessage = "";
    });
    builder.addCase(createBlog.rejected, (state, { payload }) => {
      state.isCreatingBlog = false;
      state.errorMessage = payload ?? defaultErrorMessage;
    });
  },
});
export const isLoading = (state: RootState) => state.blogs.isLoading;
export const data = (state: RootState) => state.blogs.data;
export const blog = (state: RootState) => state.blogs.blog;
export const errorMessage = (state: RootState) => state.blogs.errorMessage;
export const isCreatingBlog = (state: RootState) => state.blogs.isCreatingBlog;

export default blogSlice.reducer;
