import { AppBar, Avatar, Box, Card, Chip, Grid, Toolbar, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../images/hummingbird.png";
import { CustomizedImageBox, CustomizedImageTextBox, GlassmorphicBox } from "./styledComponent";
import Footer from "../footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppStore, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { analytics } from "../config/firebase";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import ZohoSalesIQ from "./SalesIQ";

export function Home() {
  logEvent(analytics, "wHome", { firebase_screen_class: "wHome", page_title: "Home" });
  const navigate = useNavigate();
  const routeChange = (path: string) => {
    navigate(path);
  };
  useEffect(() => {
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "hummingbirdosc") {
      ZohoSalesIQ();
    }
  }, []);
  return (
    <>
      <main>
        <div>
          <Container sx={{ flexGrow: 1 }} maxWidth="xl">
            <AppBar position="static" color="transparent" elevation={0}>
              <Toolbar disableGutters>
                <Box sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1 }} justifyContent="end">
                  <Chip
                    label="Login"
                    clickable
                    sx={{
                      mr: 3,
                      py: 1,
                      width: "150px",
                      color: "white",
                      backgroundColor: "#283e4a",
                      fontSize: "20px",
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#283e4a" },
                    }}
                    onClick={() => routeChange("/auth/login")}
                  />
                  <Chip
                    label="Sign up"
                    clickable
                    sx={{
                      py: 2,
                      width: "150px",
                      color: "white",
                      backgroundColor: "#283e4a",
                      fontSize: "20px",
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#283e4a" },
                    }}
                    onClick={() => routeChange("/auth/sign-up")}
                  />
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }} justifyContent="space-between">
                  <Chip
                    label="Login"
                    clickable
                    sx={{
                      mr: 3,
                      py: 1,
                      width: "150px",
                      color: "white",
                      backgroundColor: "#283e4a",
                      fontSize: "20px",
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#283e4a" },
                    }}
                    onClick={() => routeChange("/auth/login")}
                  />
                  <Chip
                    label="Sign up"
                    clickable
                    sx={{
                      py: 2,
                      width: "150px",
                      color: "white",
                      backgroundColor: "#283e4a",
                      fontSize: "20px",
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#283e4a" },
                    }}
                    onClick={() => routeChange("/auth/sign-up")}
                  />
                </Box>
              </Toolbar>
            </AppBar>
          </Container>

          <Container maxWidth={false}>
            <Grid item>
              <Card
                sx={{
                  borderRadius: "20px",
                  position: "relative",
                  height: "60vmin",
                  backgroundColor: "#edfced",
                  pb: 10,
                  pt: 5,
                }}
                className="landing-header"
                elevation={0}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} sm={7} alignItems={"center"} justifyContent={"center"} container>
                    <Grid item sx={{ position: "relative" }}>
                      <Grid
                        item
                        sx={{
                          width: "100px",
                          height: "100px",
                          backgroundImage: `url(${logo})`,
                          backgroundSize: "120px 120px",
                          zIndex: 1,
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></Grid>
                      <GlassmorphicBox
                        sx={{
                          padding: "50px",
                        }}
                      >
                        <Typography fontSize={"5.5vw"} color={"#283e4a"} fontWeight={"600"}>
                          HUMMINGBIRD
                        </Typography>
                        <Typography fontWeight={"500"} fontSize={"4.5vw"} color={"green"}>
                          Out of School Care
                        </Typography>
                        <Typography fontWeight={"500"} fontSize={"2.5vw"} color={"green"}>
                          Ltd
                        </Typography>
                      </GlassmorphicBox>
                    </Grid>
                    <Grid item xs={12} pb={5} container justifyContent={"space-between"}>
                      <Grid item xs={6} container justifyContent={"center"}>
                        <Link
                          target={"_blank"}
                          style={{ textDecoration: "none" }}
                          to="https://play.google.com/store/apps/details?id=com.hummingbirdosc.hummingbirdapp"
                        >
                          <Grid container direction={"column"} alignItems={"center"}>
                            <Avatar
                              sx={{ width: 50, height: 50, bgcolor: "#293d4a", boxShadow: "20" }}
                              variant="rounded"
                            >
                              <FontAwesomeIcon icon={faGooglePlay} fontSize={"30"} />
                            </Avatar>
                            <Typography color={"#293d4a"}>Android</Typography>
                          </Grid>
                        </Link>
                      </Grid>
                      <Grid item xs={6} container justifyContent={"center"} alignItems={"center"}>
                        <Link
                          target={"_blank"}
                          style={{ textDecoration: "none" }}
                          to="https://apps.apple.com/gb/app/hummingbird-osc/id1633461102"
                        >
                          <Grid container direction={"column"} alignItems={"center"}>
                            <Avatar
                              sx={{ width: 50, height: 50, bgcolor: "#293d4a", boxShadow: "20" }}
                              variant="rounded"
                            >
                              <FontAwesomeIcon icon={faAppStore} fontSize={"30"} />
                            </Avatar>
                            <Typography color={"#293d4a"}>App Store</Typography>
                          </Grid>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={1}
                    sm={5}
                    alignItems={"center"}
                    justifyContent={"center"}
                    p={5}
                    sx={{ display: { xs: "none", sm: "flex" } }}
                  >
                    <Grid item xs={3} container alignItems={"center"}>
                      <Grid item xs={12}>
                        <Box className="landing-pod-one" sx={{ mb: 1 }}></Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="landing-pod-three" sx={{ mb: 1 }}></Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="landing-pod-four" sx={{ mb: 1 }}></Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={7} container height={"100%"}>
                      <Grid item xs={12}>
                        <Box height={"100%"} className="landing-badge-two"></Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} height={"60%"}>
                      <Box height={"100%"} className="landing-badge-three"></Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Container
              maxWidth={"xl"}
              sx={{
                height: "350px",
                mt: 1,
                p: 5,
                display: { xs: "none", md: "flex" },
              }}
              disableGutters
            >
              <Swiper
                spaceBetween={50}
                slidesPerView={2}
                modules={[Pagination, Autoplay, EffectFade]}
                pagination={{ clickable: true }}
                style={{ height: "100%", borderRadius: 20 }}
                effect="fade"
                centeredSlides={true}
                autoplay={{
                  delay: 10000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <Grid
                    container
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"inherit"}
                    wrap="nowrap"
                    columnSpacing={1}
                  >
                    <Grid item xs={3} height={"inherit"}>
                      <Box className={"swiper-box"}>
                        <Typography fontSize={35}>After school</Typography>
                        <Typography fontSize={20}>3pm - 6pm</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} height={"inherit"}>
                      <Box className={"swiper-box"}>
                        {" "}
                        <Typography fontSize={35}>Breakfast club</Typography>
                        <Typography fontSize={20}>7am - 9am</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} height={"inherit"}>
                      <Box className={"swiper-box"}>
                        {" "}
                        <Typography fontSize={35}>School holidays</Typography>
                        <Typography fontSize={20}>9am - 6pm</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} height={"inherit"}>
                      <Box className={"swiper-box"}>
                        {" "}
                        <Typography fontSize={35}>Childcare</Typography>
                        <Typography fontSize={20}>coming soon ...</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </SwiperSlide>
                <SwiperSlide style={{ width: "100px" }}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"inherit"}
                    className="landing-pod-two"
                  ></Grid>
                </SwiperSlide>
                <SwiperSlide style={{ width: "100px" }}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"inherit"}
                    className="landing-pod-three"
                  ></Grid>
                </SwiperSlide>
                <SwiperSlide style={{ width: "100px" }}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"inherit"}
                    className="landing-pod-four"
                  ></Grid>
                </SwiperSlide>
              </Swiper>
            </Container>
            <Container maxWidth={"xl"}>
              <Grid
                item
                container
                height={"500px"}
                columnSpacing={1}
                marginTop={5}
                position={"relative"}
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                <Container id="video" maxWidth={false} sx={{ borderRadius: "20px" }}></Container>
                <Grid item xs={3} position={"relative"}>
                  <CustomizedImageTextBox onClick={() => routeChange("/about-us")}>
                    <Typography>About us</Typography>
                  </CustomizedImageTextBox>
                  <CustomizedImageBox className=""></CustomizedImageBox>
                </Grid>
                <Grid item xs={3} position={"relative"}>
                  <CustomizedImageTextBox onClick={() => routeChange("/download-forms")}>
                    <Typography>Forms</Typography>
                  </CustomizedImageTextBox>
                  <CustomizedImageBox className=""></CustomizedImageBox>
                </Grid>
                <Grid item xs={3} position={"relative"}>
                  <CustomizedImageTextBox>
                    <Typography>Location</Typography>
                  </CustomizedImageTextBox>
                  <CustomizedImageBox className=""></CustomizedImageBox>
                </Grid>
                <Grid item xs={3} position={"relative"}>
                  <CustomizedImageTextBox>
                    <Typography>Request Demo</Typography>
                  </CustomizedImageTextBox>
                  <CustomizedImageBox className=""></CustomizedImageBox>
                </Grid>
              </Grid>

              <Grid
                item
                container
                height={"500px"}
                rowSpacing={2}
                marginTop={5}
                sx={{ display: { xs: "flex", md: "none" } }}
                direction={"column"}
              >
                <Grid item xs={3} position={"relative"} onClick={() => routeChange("/about-us")}>
                  <CustomizedImageTextBox width={"90% !important"}>
                    <Typography width={"100%"}>About us</Typography>
                  </CustomizedImageTextBox>
                </Grid>
                <Grid item xs={3} position={"relative"} onClick={() => routeChange("/download-forms")}>
                  <CustomizedImageTextBox width={"90% !important"}>
                    <Typography>Forms</Typography>
                  </CustomizedImageTextBox>
                </Grid>
                <Grid item xs={3} position={"relative"}>
                  <CustomizedImageTextBox width={"90% !important"}>
                    <Typography>Location</Typography>
                  </CustomizedImageTextBox>
                </Grid>
                <Grid item xs={3} position={"relative"}>
                  <CustomizedImageTextBox width={"90% !important"}>
                    <Typography>Request Demo</Typography>
                  </CustomizedImageTextBox>
                </Grid>
              </Grid>
            </Container>

            <Container maxWidth={"lg"}>
              <Grid
                item
                container
                height={"640px"}
                columnSpacing={1}
                marginTop={5}
                alignItems={"center"}
                px={5}
                pb={4}
                position={"relative"}
                justifyContent={"center"}
                sx={{
                  display: { xs: "none", md: "flex" },
                  bgcolor: "white",
                  borderRadius: "20px",
                }}
              >
                <Grid item container justifyContent={"center"} xs={12}>
                  <Typography fontSize={30} mt={1}>
                    Mobile app{" "}
                  </Typography>
                </Grid>
                <Grid item container direction={"column"} xs justifyContent={"center"}>
                  <Grid item>
                    <Typography textAlign={"center"} fontSize={25}>
                      Attendance
                    </Typography>
                  </Grid>
                  <Grid item className="home-carousel"></Grid>
                </Grid>
                <Grid item container direction={"column"} xs justifyContent={"center"}>
                  <Grid item>
                    <Typography textAlign={"center"} fontSize={25}>
                      Invoice
                    </Typography>
                  </Grid>
                  <Grid item className="invoice-carousel"></Grid>
                </Grid>
                <Grid item container direction={"column"} xs justifyContent={"center"}>
                  <Grid item className="landing-carousel"></Grid>
                </Grid>
                <Grid item container direction={"column"} xs justifyContent={"center"}>
                  <Grid item>
                    <Typography textAlign={"center"} fontSize={25}>
                      Blogs
                    </Typography>
                  </Grid>
                  <Grid item className="blog-carousel"></Grid>
                </Grid>

                <Grid item container direction={"column"} xs justifyContent={"center"}>
                  <Grid item>
                    <Typography textAlign={"center"} fontSize={25}>
                      Qr Code
                    </Typography>
                  </Grid>
                  <Grid item className="qr-carousel"></Grid>
                </Grid>
              </Grid>
            </Container>
          </Container>
          <Footer></Footer>
        </div>
      </main>
    </>
  );
}
