import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { getStudents, isLoading, resetPupilBooking, studentsData } from "../../slices/booking";
import { lazy, memo, Suspense, useCallback, useEffect, useState } from "react";
import PageLoadingSpinner from "../PageLoadingSpinner";
import { getTerms } from "../../slices/term";
import { getParentInvoice, parentInvoice as invoiceData } from "../../slices/invoice";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import { formatNumber } from "../../utils";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Datum } from "../../slices/interfaces";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";

const PupilBooking = lazy(() => import("./PupilBooking"));
const Receipt = lazy(() => import("../pdf/receipt"));

type monthCost = {
  fullDay: number;
  term: number;
};
export function ClientBooking() {
  const loading = useAppSelector(isLoading);
  const parentInvoice = useAppSelector(invoiceData);
  const dispatch = useAppDispatch();
  const students = useAppSelector(studentsData);
  const [openPupilModal, setOpenPupilModal] = useState<boolean>(false);
  const [pupilId, setPupilId] = useState<string>("");

  const [openReceiptModal, setOpenReceiptModal] = useState<boolean>(false);

  const [receiptData, setReceiptData] = useState<Datum & monthCost>();
  logEvent(analytics, "wClientBooking", {
    firebase_screen_class: "wClientBooking",
    page_title: "ClientBooking",
  });

  const handlePupilModalClose = (): void => {
    setOpenPupilModal(!openPupilModal);
    setPupilId("");
    dispatch(resetPupilBooking());
  };
  const openPupilBooking = useCallback((id: string) => {
    setPupilId(id);
    setOpenPupilModal(true);
  }, []);

  useEffect(() => {
    dispatch(getStudents());
    dispatch(getTerms());
    dispatch(getParentInvoice());
  }, [dispatch]);

  function _downloadReceipt(pupilInvoice: Datum, fullDay: number, term: number): void {
    setReceiptData({ ...pupilInvoice, fullDay, term });
    setOpenReceiptModal(true);
  }

  const handleReceiptModalClose = (): void => {
    setOpenReceiptModal(!openReceiptModal);
    setReceiptData(undefined);
  };

  return (
    <>
      {loading ? (
        <PageLoadingSpinner />
      ) : (
        <Container maxWidth={false} sx={{ height: "90vh", textAlign: "center" }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Term Bookings</Typography>
              <TableContainer component={Paper}>
                <Table
                  aria-label="client"
                  size="small"
                  sx={{
                    th: {
                      backgroundColor: "#14e6dd",
                    },
                    "td:nth-of-type(4), th:nth-of-type(4)": {
                      textAlign: "center",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Surname</TableCell>
                      <TableCell>School</TableCell>
                      <TableCell width={"20%"}>Booking</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students &&
                      students.map((student) => (
                        <TableRow
                          key={student._id}
                          sx={{
                            bgcolor: student.isActive ? "" : "#ffe9ec",
                            color: "green",
                          }}
                        >
                          <TableCell>{student.firstName}</TableCell>
                          <TableCell>{student.lastName}</TableCell>
                          <TableCell>{student.school.name}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              disabled={!student.isActive}
                              sx={{
                                m: "0px !important",
                                fontSize: "10px",
                                width: student.isActive ? "fit-content" : "150px",
                              }}
                              onClick={() => openPupilBooking(student._id!)}
                            >
                              {!student.isActive && (
                                <LockTwoToneIcon
                                  color="error"
                                  // fontSize="small"
                                  sx={{ fontSize: "12px" }}
                                />
                              )}{" "}
                              {student.isActive ? "View or Manage Booking" : "Contact admin"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} height={"90vh"}>
              <Typography variant="h6">Invoices for the last six months</Typography>
              <Box component={Paper}>
                <TableContainer component={Paper}>
                  <Table
                    aria-label="client"
                    size="small"
                    sx={{
                      th: {
                        backgroundColor: "#14e6dd",
                      },
                      "td:nth-of-type(n+3)": {
                        textAlign: "right",
                      },
                      "th:nth-of-type(n+3)": {
                        textAlign: "right",
                      },
                      "th:last-child": {
                        textAlign: "center",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Term time</TableCell>
                        <TableCell>Holidays</TableCell>
                        <TableCell>Travel</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Part Payment</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Payment status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parentInvoice.length > 0 &&
                        parentInvoice.map((monthInvoice) => {
                          return monthInvoice.data.map((pupilInvoice) => {
                            const normalDaysIn = pupilInvoice.record.filter(
                              (day) => !day.fullDay
                            ).length;
                            const fullDaysIn = pupilInvoice.record.length - normalDaysIn;
                            const total =
                              normalDaysIn * monthInvoice.normalCharge +
                              fullDaysIn * monthInvoice.fullDayCharge +
                              (pupilInvoice.travel ?? 0);

                            return (
                              <TableRow key={`${pupilInvoice.pupilId}_${monthInvoice.period}`}>
                                <TableCell>{monthInvoice.period}</TableCell>
                                <TableCell>{pupilInvoice.firstName}</TableCell>
                                <TableCell>
                                  {normalDaysIn !== 0
                                    ? `${normalDaysIn} * ${monthInvoice.normalCharge}`
                                    : normalDaysIn}
                                </TableCell>
                                <TableCell>
                                  {fullDaysIn !== 0
                                    ? `${fullDaysIn} * ${monthInvoice.fullDayCharge}`
                                    : fullDaysIn}
                                </TableCell>
                                <TableCell>{formatNumber(pupilInvoice.travel ?? 0)}</TableCell>
                                <TableCell>{formatNumber(pupilInvoice.discount)}</TableCell>
                                <TableCell>{formatNumber(pupilInvoice.partialPayment)}</TableCell>
                                <TableCell>{`${formatNumber(
                                  total - pupilInvoice.discount - pupilInvoice.partialPayment
                                )}`}</TableCell>
                                <TableCell>
                                  <Box display={"flex"} justifyContent={"space-between"}>
                                    {pupilInvoice.paid ? "Paid" : "Outstanding"}
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      onClick={
                                        pupilInvoice.paid
                                          ? () =>
                                              _downloadReceipt(
                                                pupilInvoice,
                                                monthInvoice.fullDayCharge,
                                                monthInvoice.normalCharge
                                              )
                                          : undefined
                                      }
                                    >
                                      <ReceiptIcon
                                        fontSize="inherit"
                                        color={pupilInvoice.paid ? "success" : "disabled"}
                                      />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          });
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      <Suspense fallback={<PageLoadingSpinner />}>
        {openPupilModal && (
          <PupilBooking id={pupilId} handleClose={handlePupilModalClose} open={openPupilModal} />
        )}
      </Suspense>

      <Suspense fallback={<PageLoadingSpinner />}>
        {openReceiptModal && (
          <Receipt
            pupilData={receiptData}
            handleClose={handleReceiptModalClose}
            open={openReceiptModal}
          />
        )}
      </Suspense>
    </>
  );
}

export default memo(ClientBooking);
