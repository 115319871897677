import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes from "./config/routes";
import AuthRoute from "./modules/auth/AuthRoute";
import { useContext, useEffect } from "react";
import { IAuth } from "./slices/interfaces";
import Flash from "./components/Flash";
import { useSelector } from "react-redux";
import { alert } from "./slices/alert";
import { AuthContext } from "./modules/context";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "./config/firebase";

function App() {
  const { user, dbUser } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const alertSelector = useSelector(alert);

  useEffect(() => {
    if (
      !auth.currentUser &&
      pathname !== "/auth/login" &&
      pathname !== "/auth/register" &&
      pathname !== "/auth/sign-up" &&
      pathname !== "/auth/forget-password" &&
      pathname !== "/about-us" &&
      pathname !== "/download-forms" &&
      pathname !== "/policy"
    ) {
      navigate(`/`);
    }
    if (pathname === "/auth/login" || pathname === "/" || pathname === "/auth/register") {
      if (user && dbUser) {
        navigate(`/user/${user.uid}`);
      }
    }
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          { size: "invisible", callBack: () => console.log("captcha solved") },
          auth
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [dbUser, pathname, navigate, user]);
  return (
    <>
      <Flash
        showAlert={alertSelector.showAlert}
        severity={alertSelector.severity}
        alertMessage={alertSelector.alertMessage}
      />
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.protected ? (
                <AuthRoute>
                  <route.component {...route.extra}></route.component>
                </AuthRoute>
              ) : (
                <route.component />
              )
            }
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
