import Account from "../components/authentication/Account";
import Dashboard from "../components/dashboards/Index";
import { Home } from "../components/Landing";
import Login from "../components/authentication/Login";
import Page404 from "../components/PageNotFound";
import Register from "../components/authentication/Register";
import { EmailVerification } from "../components/authentication/EmailVerification";
import SignUp from "../components/authentication/SignUp";
import TwoFactorEnrollment from "../components/authentication/TwoFactorEnrollment";
import { IRoute } from "../slices/interfaces";
import Pupil from "../components/attendance/Pupil";
import { Booking } from "../components/booking/Index";
import { SendPasswordReset } from "../components/authentication/SendPasswordReset";
import { Invoice } from "../components/invoice/Index";
import ClientDashboard from "../components/clients/Index";
import Banking from "../components/banking/Index";
import Blogs from "../components/blogs/Index";
import BlogDetails from "../components/blogs/Details";
import AboutUs from "../about-us";
import Forms from "../forms";
import Policy from "../policy";

const routes: IRoute[] = [
  {
    path: "/",
    component: Home,
    name: "Home",
    protected: false,
  },
  {
    path: "/auth/login",
    component: Login,
    name: "Login",
    protected: false,
  },
  {
    path: "/user/:id",
    component: Dashboard,
    name: "Dashboard",
    extra: {
      title: "Dashboard",
    },
    protected: true,
  },
  {
    path: "/auth/sign-up",
    component: SignUp,
    name: "Sign up",
    protected: false,
  },
  {
    path: "/auth/two-factor-enroll",
    component: TwoFactorEnrollment,
    name: "2FA",
    protected: true,
  },
  {
    path: "/auth/register",
    component: Register,
    name: "Register",
    protected: true,
  },
  {
    path: "/invoices",
    component: Invoice,
    name: "Invoice",
    protected: true,
  },
  {
    path: "/clients",
    component: ClientDashboard,
    name: "Clients",
    protected: true,
  },
  {
    path: "/banking-finance",
    component: Banking,
    name: "Banking",
    extra: {
      title: "Banking",
    },
    protected: true,
  },
  {
    path: "/booking",
    component: Booking,
    name: "Booking",
    extra: {
      title: "Booking",
    },
    protected: true,
  },
  {
    path: "/auth/verify-account",
    component: EmailVerification,
    name: "Verify",
    protected: true,
  },
  {
    path: "/account",
    component: Account,
    name: "Account",
    protected: true,
    extra: {
      title: "Account",
    },
  },
  {
    path: "/auth/forget-password",
    component: SendPasswordReset,
    name: "Account",
    protected: false,
    extra: {
      title: "Forget Password",
    },
  },
  {
    path: "/pupils",
    component: Pupil,
    name: "Pupil",
    protected: true,
    extra: {
      title: "Pupils",
    },
  },
  {
    path: "/blogs",
    component: Blogs,
    name: "Blogs",
    protected: true,
    extra: {
      title: "Blogs",
    },
  },
  {
    path: "/blogs/:blogId",
    component: BlogDetails,
    name: "Blogs",
    protected: true,
    extra: {
      title: "Blogs",
    },
  },
  {
    path: "/about-us",
    component: AboutUs,
    name: "About us",
    protected: false,
    extra: {
      title: "About us",
    },
  },
  {
    path: "/policy",
    component: Policy,
    name: "Policy",
    protected: false,
    extra: {
      title: "Policy",
    },
  },
  {
    path: "/download-forms",
    component: Forms,
    name: "Forms",
    protected: false,
    extra: {
      title: "Forms",
    },
  },
  {
    path: "*",
    component: Page404,
    name: "Not Found",
    protected: false,
  },
];

export default routes;
