import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { AccountTypeEnum, IDbUser, PageProps } from "../../slices/interfaces";
import { editUser, isLoading, selectUser } from "../../slices/user";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import PageLoadingSpinner from "../PageLoadingSpinner";
import { CustomizedTextField } from "../styledComponent";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import formFields from "./formFields";
import useAuth from "../../modules/hooks/auth";
import { showAlert } from "../../slices/alert";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { getDownloadURL, getStorage, ref, StorageReference } from "firebase/storage";
import logo from "../../images/hummingbird.png";
import { auth } from "../../config/firebase";
import { IdTokenResult } from "firebase/auth";
const { firstName, lastName, address, postCode } = formFields;

const ImageUploader = lazy(() => import("./ImageUploader"));
const storage = getStorage();

export function Account({ title }: PageProps) {
  const userData = useAppSelector(selectUser);
  const loading = useAppSelector(isLoading);
  const { userSendPasswordResetEmail } = useAuth();
  const [imgUrl, setImageUrl] = useState<string>("");
  const dispatch = useAppDispatch();
  const [openImageUploader, setOpenImageUploader] = useState<boolean>(false);
  const [idTokenResultData, setIdTokenResultData] = useState<IdTokenResult>();
  const [accountType, setAccountType] = useState<AccountTypeEnum>();

  const downloadProfileUrl = useCallback((ref: StorageReference) => {
    try {
      getDownloadURL(ref)
        .then((url) => {
          setImageUrl(url);
        })
        .catch((error) => {
          console.log(error);
          setImageUrl(logo);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const authData = useCallback(async () => {
    setIdTokenResultData(await auth.currentUser?.getIdTokenResult(true));
  }, []);

  useEffect(() => {
    document.title = title;
    downloadProfileUrl(ref(storage, `profile/${userData?._id}/avatar.jpg`));
    authData();
    setAccountType(userData?.accountType);
  }, [authData, downloadProfileUrl, title, userData?._id, userData?.accountType]);

  const handleChange = async () => {
    const changeAccount =
      userData?.accountType === AccountTypeEnum.ADMIN ? AccountTypeEnum.STAFF : AccountTypeEnum.ADMIN;
    if (idTokenResultData?.claims.admin && accountType)
      try {
        const ret = await dispatch(editUser({ ...userData!, accountType: changeAccount, viewAs: true }));
        if (editUser.fulfilled.match(ret)) {
          dispatch(
            showAlert({
              message: `${ret.payload!}`,
              severity: "error",
            })
          );
          dispatch(showAlert({ message: "Success", severity: "success" }));
          setAccountType(userData?.accountType);
          window.location.reload();
        } else {
          dispatch(
            showAlert({
              message: `${ret.payload}`,
              severity: "error",
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
  };

  const _handleSubmit = async (values: IDbUser) => {
    try {
      const phoneNumber =
        values.phoneNumber && values.phoneNumber.startsWith("+44")
          ? values.phoneNumber.substring(3)
          : values.phoneNumber;
      const ret = await dispatch(editUser({ ...values, phoneNumber: `+44${phoneNumber}` }));
      if (editUser.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
        dispatch(showAlert({ message: "Success", severity: "success" }));
      } else {
        dispatch(
          showAlert({
            message: `${ret.payload}`,
            severity: "error",
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const _resetPassword = async () => {
    const email = userData?.email!;
    try {
      userSendPasswordResetEmail(email);
      dispatch(
        showAlert({
          message: "Password reset email sent successfully",
          severity: "success",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const _handleClose = () => {
    setOpenImageUploader(false);
    downloadProfileUrl(ref(storage, `profile/${userData?._id}/avatar.jpg`));
  };

  const _handleImageUpload = () => {
    setOpenImageUploader(true);
  };

  return (
    <>
      {loading || !userData ? (
        <PageLoadingSpinner />
      ) : (
        <Container maxWidth="xl">
          <Grid container height={"auto"} columnSpacing={2}>
            <Grid item xs={3} height={"40vh"} textAlign="center">
              <Grid container component={Paper} height={"inherit"}>
                <Grid
                  item
                  position={"relative"}
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  xs={12}
                  sx={{
                    backgroundColor: "grey",
                  }}
                >
                  <Avatar
                    alt="Cindy Baker"
                    src={`${imgUrl}`}
                    sx={{
                      width: "25vh",
                      height: "25vh",
                      position: "absolute",
                      top: "20%",
                      border: "2px solid white",
                      bgcolor: "#14e6dd",
                    }}
                  />
                  <IconButton
                    name="details"
                    onClick={_handleImageUpload}
                    sx={{
                      top: "100%",
                      position: "absolute",
                    }}
                  >
                    <PhotoCameraIcon color="action" fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item xs={12} justifyContent={"center"} alignItems={"end"} container>
                  <Grid item>
                    <Box>
                      <Typography paddingBottom={"10px"} fontWeight={500}>
                        {userData.firstName} {userData.lastName}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item pt={2} container justifyContent={"center"}>
                {idTokenResultData?.claims.admin && (
                  <Grid item>
                    <Switch
                      color="warning"
                      checked={userData.accountType !== AccountTypeEnum.ADMIN}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                <Button variant="contained" onClick={_resetPassword}>
                  Reset password
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={9} container>
              <Grid container component={Paper} p={3} rowGap={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">General Information</Typography>
                </Grid>
                <Formik
                  initialValues={
                    {
                      firstName: userData?.firstName,
                      lastName: userData?.lastName,
                      email: userData?.email,
                      address: userData?.address ?? "",
                      postCode: userData?.postCode ?? "",
                      phoneNumber:
                        userData?.phoneNumber && userData.phoneNumber.startsWith("+44")
                          ? userData.phoneNumber.substring(3)
                          : userData.phoneNumber ?? "",
                    } as IDbUser
                  }
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required(`${firstName.requiredErrorMsg}`),
                    lastName: Yup.string().required(`${lastName.requiredErrorMsg}`),
                    address: Yup.string().required(`${address.requiredErrorMsg}`),
                    postCode: Yup.string().required(`${postCode.requiredErrorMsg}`),
                    // phoneNumber: Yup.string()
                    //   .nullable()
                    //   .matches(
                    //     /((\+44?)|(\+440?))7\d{3}(\s)?\d{6}/g,
                    //     "Valid Mobile number is required"
                    //   ),
                  })}
                  onSubmit={_handleSubmit}
                >
                  {({ values, errors, touched, handleChange, isSubmitting }: FormikProps<IDbUser>) => (
                    <Box width={"100%"}>
                      <Form>
                        <Grid item xs={12} container width={"100%"} height={"80vh"}>
                          <Grid item xs={12} container height={"fit-content"} columnSpacing={1}>
                            <Grid item xs={6}>
                              <CustomizedTextField
                                id="email"
                                name="email"
                                placeholder="john@acme.com"
                                label="Email"
                                disabled
                                type="email"
                                fullWidth
                                value={values.email}
                                onChange={handleChange}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email ? errors.email : ""}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomizedTextField
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Phone Number"
                                disabled
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+44</InputAdornment>,
                                }}
                                fullWidth
                                value={values.phoneNumber}
                                onChange={handleChange}
                                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                helperText={touched.phoneNumber ? errors.phoneNumber : ""}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomizedTextField
                                id="firstName"
                                name="firstName"
                                placeholder="John Beckett"
                                label="First name"
                                fullWidth
                                value={values.firstName}
                                onChange={handleChange}
                                error={touched.firstName && Boolean(errors.firstName)}
                                helperText={touched.firstName ? errors.firstName : ""}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomizedTextField
                                id="lastName"
                                name="lastName"
                                placeholder="John Beckett"
                                label="Last name"
                                fullWidth
                                value={values.lastName}
                                onChange={handleChange}
                                error={touched.lastName && Boolean(errors.lastName)}
                                helperText={touched.lastName ? errors.lastName : ""}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomizedTextField
                                id="address"
                                name="address"
                                label="Address"
                                fullWidth
                                value={values.address}
                                onChange={handleChange}
                                error={touched.address && Boolean(errors.address)}
                                helperText={touched.address ? errors.address : ""}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomizedTextField
                                id="postCode"
                                name="postCode"
                                label="Post code"
                                fullWidth
                                value={values.postCode}
                                onChange={handleChange}
                                error={touched.postCode && Boolean(errors.postCode)}
                                helperText={touched.postCode ? errors.postCode : ""}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container alignItems={"flex-end"}>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{
                                mt: "50px",
                                borderRadius: "20px",
                                height: "50px",
                                bgcolor: "#a5d6a7",
                                color: "black",
                                fontSize: "20px",
                              }}
                            >
                              {!isSubmitting ? "Save" : <CircularProgress color="success" />}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    </Box>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
          <Suspense fallback={<PageLoadingSpinner />}>
            {openImageUploader && <ImageUploader handleClose={_handleClose} open={openImageUploader} />}
          </Suspense>
        </Container>
      )}
    </>
  );
}

export default Account;
