import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material";
import { useAppDispatch } from "../modules/hooks/store";
import { reset } from "../slices/alert";
import CloseIcon from "@mui/icons-material/Close";

type AlertProps = {
  showAlert: boolean;
  severity: AlertColor;
  alertMessage: string;
};

export function Flash({ showAlert, severity, alertMessage }: AlertProps) {
  const dispatch = useAppDispatch();
  return (
    <>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            width: "700px",
          }}
          onClose={() => {
            dispatch(reset());
          }}
        >
          <Alert
            sx={{
              "& .MuiAlert-message": {
                width: "100%",
                display: "flex",
                justifyContent: "center",
              },
              width: "100%",
            }}
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(reset());
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Flash;
