/* eslint-disable no-throw-literal */
import { FirebaseError } from "firebase/app";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { showAlert } from "../../slices/alert";
import { EmailPassType, IAuth } from "../../slices/interfaces";
import { reset } from "../../slices/user";
import { AuthContext } from "../context";
import { useAppDispatch } from "./store";

function useAuth() {
  const { user, setDbUser, setLoading } = useContext(AuthContext) as IAuth;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const errorDetails = (e: FirebaseError): any => {
    if (e.code !== "auth/multi-factor-auth-required")
      dispatch(
        showAlert({
          message: "An error occurred",
          severity: "error",
        })
      );
    if (e.message === "auth/invalid-email") {
      return "Invalid Email Address";
    } else if (e.code === "auth/wrong-password") {
      return "Incorrect Password";
    } else if (e.code === "auth/too-many-requests") {
      return "Too many attempts, try later.";
    } else if (e.code === "auth/user-not-found") {
      return "Email not found, please create new account.";
    } else if (e.code === "auth/email-already-in-use") {
      return "Email already in use.";
    } else if (e.code === "auth/multi-factor-auth-required") {
      console.log(e);
      return e;
    } else {
      return "An error occurred";
    }
  };

  const login = async ({ email, password }: EmailPassType): Promise<Record<string, string>> => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      console.log(e);
      if (e instanceof FirebaseError) {
        throw { error: errorDetails(e) };
      }
    }
    return {};
  };
  const signUp = async ({ email, password }: EmailPassType): Promise<Record<string, string>> => {
    try {
      const user = (await createUserWithEmailAndPassword(auth, email, password)).user;
      await sendEmailVerification(user);
      navigate("/auth/login");
    } catch (e) {
      if (e instanceof FirebaseError) {
        throw { error: errorDetails(e) };
      }
    }
    return {};
  };
  const logOut = async () => {
    console.log("logged out");
    await signOut(auth);
    dispatch(reset());
    setDbUser(null);
    navigate("/auth/login");
    setLoading && setLoading(false);
  };
  const userSendPasswordResetEmail = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (e) {
      throw { error: "Email not found" };
    }
  };
  const verifyEmail = async () => {
    if (user) await sendEmailVerification(user);
  };

  return {
    login,
    logOut,
    signUp,
    userSendPasswordResetEmail,
    verifyEmail,
  };
}

export default useAuth;
