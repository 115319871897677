import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

type AlertProps = {
  showAlert: boolean;
  severity: AlertColor;
  alertMessage: string;
};

const initialState: AlertProps = {
  showAlert: false,
  severity: "success",
  alertMessage: "",
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (
      state,
      action: PayloadAction<{ message: string; severity: AlertColor }>
    ) => {
      state.showAlert = true;
      state.alertMessage = action.payload.message;
      state.severity = action.payload.severity;
    },
    reset: () => initialState,
  },
});

export const alert = (state: RootState) => state.alert;
export const { showAlert, reset } = alertSlice.actions;

export default alertSlice.reducer;
