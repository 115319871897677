import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, ITerm } from "./interfaces";

const initialState: {
  term: ITerm[];
  isLoadingTerms: boolean;
  errorMessage: string;
} = {
  term: [],
  isLoadingTerms: false,
  errorMessage: "",
};

export const getTerms = createAsyncThunk<ITerm[], void, { rejectValue: string }>(
  "getTerms",
  async (_, { rejectWithValue }) => {
    try {
      console.log("calling db.....");
      const ret = await Api.getTerms();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);
export const createTerm = createAsyncThunk<ITerm[], ITerm, { rejectValue: string }>(
  "createTerm",
  async (data, { rejectWithValue }) => {
    try {
      console.log("calling db.....");
      const ret = await Api.createTerm(data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const deleteTerm = createAsyncThunk<ITerm[], string, { rejectValue: string }>(
  "deleteTerm",
  async (id, { rejectWithValue }) => {
    try {
      console.log("calling db.....");
      const ret = await Api.deleteTerm(id);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const editTerm = createAsyncThunk<
  ITerm[],
  Omit<ITerm, "termStart |termEnd">,
  { rejectValue: string }
>("editTerm", async (data, { rejectWithValue }) => {
  try {
    const ret = await Api.editTerm(data);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const termSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTerms.fulfilled, (state, { payload }) => {
      console.log("got term....");
      state.isLoadingTerms = false;
      state.term = payload;
    });
    builder.addCase(getTerms.pending, (state, _) => {
      console.log("pending terms....");
      state.isLoadingTerms = true;
    });
    builder.addCase(getTerms.rejected, (state, { payload }) => {
      state.isLoadingTerms = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Create
    builder.addCase(createTerm.fulfilled, (state, { payload }) => {
      console.log("creating term....");
      state.isLoadingTerms = false;
      state.term = payload;
    });
    builder.addCase(createTerm.pending, (state, _) => {
      console.log("pending creating terms....");
      state.isLoadingTerms = true;
    });
    builder.addCase(createTerm.rejected, (state, { payload }) => {
      state.isLoadingTerms = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Edit
    builder.addCase(editTerm.fulfilled, (state, { payload }) => {
      console.log("finished editing term....");
      state.isLoadingTerms = false;
      state.term = payload;
    });
    builder.addCase(editTerm.pending, (state, _) => {
      console.log("editing terms....");
      state.isLoadingTerms = true;
    });
    builder.addCase(editTerm.rejected, (state, { payload }) => {
      state.isLoadingTerms = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
    // Delete
    builder.addCase(deleteTerm.fulfilled, (state, { payload }) => {
      console.log("finished editing term....");
      state.isLoadingTerms = false;
      state.term = payload;
    });
    builder.addCase(deleteTerm.pending, (state, _) => {
      console.log("editing terms....");
      state.isLoadingTerms = true;
    });
    builder.addCase(deleteTerm.rejected, (state, { payload }) => {
      state.isLoadingTerms = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
  },
});
export const terms = (state: RootState) => state.terms.term;
export const isLoadingTerms = (state: RootState) => state.terms.isLoadingTerms;
export const errorMessage = (state: RootState) => state.terms.errorMessage;

export default termSlice.reducer;
