import { Box, CircularProgress, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
export default function PendingAccount(): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="80vh"
      justifyContent={"space-around"}
    >
      <LockOutlinedIcon fontSize="large" color="error" />
      <Typography variant="h5" fontWeight={400}>
        We are busy building your profile....😊
      </Typography>
      <CircularProgress color="success" value={100} thickness={5} size={100} />
    </Box>
  );
}
