import styled from "@emotion/styled";
import {
  Box,
  Button,
  Chip,
  ListItemButton,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { NavLink } from "react-router-dom";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "10px",
    border: "1px solid #dadde9",
  },
}));

export const CustomizedAvatar = styled(Box)`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  height: 180px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  background-color: #14e6dd;
`;

export const CustomizedDataGrid = styled(DataGrid)`
  & .MuiDataGrid-row:hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }
  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .inactive-pupil {
    color: red;
  }
  & .MuiDataGrid-columnSeparator {
    display: none;
  }
  .MuiDataGrid-columnHeaders {
    background-color: #14e6dd;
  }
  background-color: white;
  & .account-locked {
    background-color: #ffe9ec !important;
  }
`;
export const CustomizedTextField = styled(TextField)`
  margin: 10px;
`;

export const CustomizedButton = styled(Button)`
  margin: 10px;
`;

export const CustomizedImageBox = styled(Box)`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  &:hover {
    // transform: scale(1.005);
  }
`;

export const CustomizedImageBoxNoBlur = styled(Box)`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;
export const CustomizedImageTextBox = styled(Box)`
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  padding: 20px;
  text-align: center;
  cursor: pointer;
`;

export const GlassmorphicBox = styled(Box)`
  margin: 10px;
  background: rgba(0, 128, 0, 0.1);
  border-radius: 16px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  // border: 1px solid rgba(0, 128, 0, 0.3);
`;
export const CustomizedChip = styled(Chip)`
  font-size: 18px;
  width: 200px;
  background-color: #283e4a;
  color: white;
  font-weight: 500;
  &:hover {
    color: black;
  }
`;
export const CustomizedStaticDatePicker = styled(StaticDatePicker<Date>)`
  & .MuiPickersCalendarHeader-labelContainer {
    width: 100%;
    justify-content: center;
    font-size: 15px;
  }
  & .MuiPickersArrowSwitcher-root {
    display: none;
  }
  & .MuiButtonBase-root {
    font-size: 13px;
  }
`;

export const CustomDataListItemButton = styled(ListItemButton)`
  border-radius: 50px;
  padding: 0px;
  margin-right: 10px;
  &.Mui-selected {
    background-color: #14e6dd;
    color: #161e24;
    border-radius: 100px;
  }
`;
export const CustomizedNavLink = styled(NavLink)`
  text-decoration: none;
  color: white;
  display: block;
  padding: 5px;
  margin: 5px 5px;
  &.active {
    color: #14e6dd;
    border: 1px solid #1d8bc7;
    border-radius: 5px;
  }
`;
