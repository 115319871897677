import { FC, useEffect } from "react";
import { AccountTypeEnum } from "../../slices/interfaces";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { getStudents, isLoading, studentsData } from "../../slices/booking";
import { Avatar, Card, Container, Grid, Typography } from "@mui/material";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";

const AdminDashboard: FC<{ accountType: AccountTypeEnum }> = ({ accountType }) => {
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  const students = useAppSelector(studentsData);
  useEffect(() => {
    if (accountType === AccountTypeEnum.CLIENT) {
      dispatch(getStudents());
    }
  }, [accountType, dispatch]);

  return (
    <>
      {loading ? (
        <ComponentLoadingSpinner />
      ) : (
        <Container maxWidth={false} sx={{ height: "90vh", textAlign: "center" }}>
          {}
          <Grid container spacing={3} justifyContent={"center"}>
            {students.length > 0 && accountType === AccountTypeEnum.CLIENT ? (
              students.map((student) => {
                return (
                  <Grid item key={student._id} height={400} xs={12} md={3} lg={3} mb={5}>
                    <Card
                      sx={{
                        height: 390,
                        bgcolor: "#fff",
                        p: 2,
                        mb: 4,
                        boxShadow: "1px 1px 100px #e6ecf6",
                      }}
                      elevation={0}
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        direction={"column"}
                        alignItems={"center"}
                        spacing={1}
                        color={"#78a5e8"}
                        height={"inherit"}
                      >
                        <Grid item>
                          <Avatar
                            sx={{
                              width: 150,
                              height: 170,
                              bgcolor: "lightseagreen",
                              boxShadow: "1px 1px 10px lightseagreen",
                            }}
                            variant="rounded"
                          ></Avatar>
                        </Grid>
                        <Grid item>
                          <Typography
                            fontSize={30}
                            fontWeight={"500"}
                            pt={2}
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "1",
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {student.firstName} {student.lastName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={15} fontWeight={"300"}>
                            School
                          </Typography>
                          <Typography>{student.school.name}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={15} fontWeight={"300"}>
                            Date of birth
                          </Typography>
                          <Typography>{new Date(student.birthDay).toDateString()}</Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default AdminDashboard;
