import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { data, getBlogs, isLoading, errorMessage } from "../../slices/blog";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import logo from "../../images/hummingbird.png";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import MDEditor from "@uiw/react-md-editor";
import { showAlert } from "../../slices/alert";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
const storage = getStorage();
const BlogDetails = () => {
  let { blogId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(isLoading);
  const blogs = useAppSelector(data);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const error = useAppSelector(errorMessage);

  const attachBlogImage = useCallback(async () => {
    try {
      setIsLoadingImage(true);
      const url = await getDownloadURL(ref(storage, `blogs/${blogId}/blogImage.jpg`));
      setImageUrl(url);
      setIsLoadingImage(false);
    } catch (error) {
      setImageUrl(logo);
      setIsLoadingImage(false);
    }
  }, [blogId]);

  if (blogs.length > 0) {
    logEvent(analytics, "wBlogsDetails", {
      firebase_screen_class: "wBlogsDetails",
      page_title: `_${blogs[0].title}`,
    });
  }

  useEffect(() => {
    dispatch(getBlogs(blogId));
    attachBlogImage();
    if (error.length > 0) {
      dispatch(showAlert({ message: error, severity: "error" }));
      navigate(`/blogs`);
    }
  }, [attachBlogImage, blogId, dispatch, error, navigate]);
  if (loading || blogs.length === 0) return <ComponentLoadingSpinner height="70vh" />;
  return (
    <Container maxWidth={"md"}>
      <Card sx={{ width: "100%", minHeight: "100vh", pb: "50px" }} elevation={0}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {blogs[0].title}
          </Typography>
        </CardContent>
        {isLoadingImage ? (
          <ComponentLoadingSpinner height="300px" />
        ) : (
          <CardMedia sx={{ height: 300 }} image={imageUrl} title="blog image" />
        )}
        <CardContent>
          <MDEditor.Markdown
            source={blogs[0].body}
            style={{
              whiteSpace: "pre-wrap",
              fontFamily: "Poppins",
              lineHeight: 2,
              overflow: "hidden",
              fontWeight: 400,
              fontSize: "1.1rem",
              textAlign: "justify",
            }}
          />{" "}
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            variant="contained"
            onClick={() => navigate(`/blogs`)}
            startIcon={<ArrowCircleLeftIcon />}
          >
            Back to blogs
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default BlogDetails;
