import React, { lazy, Suspense, useContext, useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../images/hummingbird.png";
import { useState } from "react";
import { AccountTypeEnum, IAuth } from "../../slices/interfaces";
import { useNavigate } from "react-router-dom";
import PageLoadingSpinner from "../PageLoadingSpinner";
import useAuth from "../../modules/hooks/auth";
import { AuthContext } from "../../modules/context";
import { CustomizedNavLink } from "../styledComponent";

const School = lazy(() => import("./School"));

const settings = ["Setting", "Logout"];
const pages = ["Blogs"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { dbUser } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();
  // Menu bar based on Account status
  const updateMenuBar = useMemo(() => {
    if (!dbUser) return pages;
    if (dbUser.accountType === AccountTypeEnum.ADMIN || dbUser.accountType === AccountTypeEnum.STAFF) {
      return ["Booking", "Pupils", ...(dbUser.accountType === AccountTypeEnum.ADMIN ? ["Invoices"] : []), ...pages];
    } else {
      return [
        ...(dbUser.accountType !== AccountTypeEnum.UNDEFINED && dbUser.accountType !== AccountTypeEnum.LOCK
          ? ["Booking & Invoices"]
          : []),
        ...pages,
      ];
    }
  }, [dbUser]);
  const { logOut } = useAuth();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (title: string) => {
    if (title === "Schools") setOpenSchoolModal(true);
    if (title === "Pupils") navigate("/pupils");
    if (title === "Booking & Invoices") navigate("/booking");
    if (title === "Invoice") navigate("/invoices");
    if (title === "Blogs") navigate("/blogs");
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting: string) => {
    switch (setting) {
      case "Logout":
        logOut();
        break;
      case "Setting":
        navigate("/account");
        break;
      case "Clients":
        navigate("/clients");
        break;
      case "Banking":
        navigate("/banking-finance");
        break;
      default:
        break;
    }
    setAnchorElUser(null);
  };

  const [openSchoolModal, setOpenSchoolModal] = useState(false);
  const handleClose = async () => {
    setOpenSchoolModal(false);
    try {
      console.log("close");
    } catch (e: any) {}
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor:
            process.env.NODE_ENV === "development"
              ? "black"
              : process.env.REACT_APP_FIREBASE_PROJECT_ID === "hummingbird-stage"
              ? "#033053"
              : "#283e4a",
          mb: 2,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Avatar
              alt="Logo"
              src={logo}
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, cursor: "pointer" }}
              onClick={
                !dbUser ||
                dbUser?.accountType === AccountTypeEnum.LOCK ||
                dbUser?.accountType === AccountTypeEnum.UNDEFINED
                  ? undefined
                  : () => navigate(`/user/${dbUser?._id}`)
              }
            />
            {(process.env.REACT_APP_BRANCH_NAME === "staging" ||
              process.env.REACT_APP_BRANCH_NAME === "development") && (
              <Typography fontSize={"12px"}>{`${process.env.REACT_APP_BRANCH_NAME.toUpperCase()} - ${
                process.env.REACT_APP_SHORT_SHA
              }`}</Typography>
            )}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-app-bar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-app-bar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {updateMenuBar &&
                  updateMenuBar.map((page) => (
                    <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
              }}
            >
              {updateMenuBar &&
                updateMenuBar.map((page) => (
                  <CustomizedNavLink key={page} to={`/${page.startsWith("Booking") ? "booking" : page.toLowerCase()}`}>
                    {page}
                  </CustomizedNavLink>
                ))}
              {dbUser?.accountType === AccountTypeEnum.ADMIN && (
                <MenuItem onClick={() => handleCloseNavMenu("Schools")}>
                  <Typography textAlign="center">{"Schools"}</Typography>
                </MenuItem>
              )}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ backgroundColor: "orangeRed" }}>{dbUser && dbUser?.firstName.charAt(0)}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-app-bar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {dbUser &&
                  dbUser.accountType === AccountTypeEnum.ADMIN && [
                    <MenuItem onClick={() => handleCloseUserMenu("Clients")} key="client">
                      <Typography textAlign="center">Clients</Typography>
                    </MenuItem>,
                    <MenuItem onClick={() => handleCloseUserMenu("Banking")} key="banking">
                      <Typography textAlign="center">Banking & Finance</Typography>
                    </MenuItem>,
                  ]}
                {settings.map((setting) => {
                  return (
                    <MenuItem
                      key={setting}
                      onClick={() => handleCloseUserMenu(setting)}
                      disabled={
                        (!dbUser ||
                          dbUser?.accountType === AccountTypeEnum.LOCK ||
                          dbUser?.accountType === AccountTypeEnum.UNDEFINED) &&
                        setting === "Setting"
                      }
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Suspense fallback={<PageLoadingSpinner />}>
        {openSchoolModal && <School open={openSchoolModal} handleClose={handleClose} />}
      </Suspense>
    </>
  );
};
export default ResponsiveAppBar;
