import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { defaultErrorMessage, IBankTransactionData, IInvoice } from "./interfaces";
import { RootState } from "./store";

const initialState: {
  isSubmitting: boolean;
  errorMessage: string;
  isLoading: boolean;
  isLoadingInvoice: boolean;
  invoice: IInvoice[];
  sendingInvoice: boolean;
  parentInvoice: IInvoice[];
  bankTransactions: { transactionDate: string; transactions: IBankTransactionData[] }[];
} = {
  isSubmitting: false,
  errorMessage: "",
  isLoading: false,
  isLoadingInvoice: false,
  invoice: [],
  sendingInvoice: false,
  parentInvoice: [],
  bankTransactions: [],
};

export const createInvoice = createAsyncThunk<
  void,
  { normalCharge: number; fullDayCharge: number; breakfastClubCharge: number },
  { rejectValue: string }
>(
  "invoices/create",
  async ({ normalCharge, fullDayCharge, breakfastClubCharge }, { rejectWithValue }) => {
    try {
      const ret = await Api.createInvoice(normalCharge, fullDayCharge, breakfastClubCharge);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const getBankTransaction = createAsyncThunk<
  { transactionDate: string; transactions: IBankTransactionData[] }[],
  { period: number; invoice: boolean | undefined },
  { rejectValue: string }
>("invoices/getBankTransaction", async ({ period, invoice }, { rejectWithValue }) => {
  try {
    const ret = await Api.getBankTransaction(period, invoice);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const getInvoice = createAsyncThunk<
  IInvoice[],
  { month: number; year: number },
  { rejectValue: string }
>("invoices/getInvoice", async ({ month, year }, { rejectWithValue }) => {
  try {
    const ret = await Api.getInvoice(month, year);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});
export const updatePaidStatus = createAsyncThunk<
  IInvoice[],
  { pupilId: string; paidStatus: boolean; month: number; year: number },
  { rejectValue: string }
>("invoices/update", async ({ pupilId, paidStatus, month, year }, { rejectWithValue }) => {
  try {
    const ret = await Api.updatePaidStatus(pupilId, paidStatus, month, year);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const updateInvoice = createAsyncThunk<
  IInvoice[],
  {
    pupilId: string;
    month: number;
    year: number;
    discount: number;
    partialPayment: number;
    travel: number;
  },
  { rejectValue: string }
>("invoices/updateInvoice", async (data, { rejectWithValue }) => {
  try {
    const ret = await Api.updateInvoice(data);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const sendInvoice = createAsyncThunk<
  void,
  { pupilId?: string; month: number; year: number },
  { rejectValue: string }
>("invoices/deleteInvoice", async ({ pupilId, month, year }, { rejectWithValue }) => {
  try {
    const ret = await Api.sendInvoice(pupilId, month, year);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const deleteInvoice = createAsyncThunk<
  IInvoice[],
  { pupilId: string; month: number; year: number },
  { rejectValue: string }
>("invoices/sendInvoice", async ({ pupilId, month, year }, { rejectWithValue }) => {
  try {
    const ret = await Api.deleteInvoice(pupilId, month, year);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});
export const getParentInvoice = createAsyncThunk<IInvoice[], void, { rejectValue: string }>(
  "invoices/parentInvoice",
  async (parentId, { rejectWithValue }) => {
    try {
      const ret = await Api.parentInvoice();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create invoice
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.isSubmitting = false;
    });
    builder.addCase(createInvoice.pending, (state, action) => {
      state.isSubmitting = true;
    });
    builder.addCase(createInvoice.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isSubmitting = false;
      console.log(state.errorMessage);
    });

    // Get invoice
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.isLoadingInvoice = false;
      state.invoice = action.payload;
    });
    builder.addCase(getInvoice.pending, (state, action) => {
      state.isLoadingInvoice = true;
    });
    builder.addCase(getInvoice.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingInvoice = false;
      console.log(state.errorMessage);
    });

    // Update invoice
    builder.addCase(updateInvoice.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoice = payload;
    });
    builder.addCase(updateInvoice.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updateInvoice.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoading = false;
      console.log(state.errorMessage);
    });

    // Update paid status
    builder.addCase(updatePaidStatus.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoice = payload;
    });
    builder.addCase(updatePaidStatus.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(updatePaidStatus.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoading = false;
      console.log(state.errorMessage);
    });
    // Send Invoice
    builder.addCase(sendInvoice.fulfilled, (state, { payload }) => {
      state.sendingInvoice = false;
    });
    builder.addCase(sendInvoice.pending, (state, _) => {
      state.sendingInvoice = true;
    });
    builder.addCase(sendInvoice.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.sendingInvoice = false;
      console.log(state.errorMessage);
    });

    // Send Invoice
    builder.addCase(getParentInvoice.fulfilled, (state, { payload }) => {
      state.parentInvoice = payload;
    });
    builder.addCase(getParentInvoice.pending, (state, _) => {});
    builder.addCase(getParentInvoice.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Delete Invoice
    builder.addCase(deleteInvoice.fulfilled, (state, { payload }) => {
      state.invoice = payload;
    });
    builder.addCase(deleteInvoice.pending, (state, _) => {});
    builder.addCase(deleteInvoice.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Get bank transactions
    builder.addCase(getBankTransaction.fulfilled, (state, { payload }) => {
      state.bankTransactions = payload;
    });
    builder.addCase(getBankTransaction.pending, (state, _) => {});
    builder.addCase(getBankTransaction.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
  },
});
export const isSubmitting = (state: RootState) => state.invoices.isSubmitting;
export const errorMessage = (state: RootState) => state.invoices.errorMessage;
export const isLoading = (state: RootState) => state.invoices.isLoading;
export const isLoadingInvoice = (state: RootState) => state.invoices.isLoadingInvoice;
export const invoiceData = (state: RootState) => state.invoices.invoice;
export const isSendingInvoice = (state: RootState) => state.invoices.sendingInvoice;
export const parentInvoice = (state: RootState) => state.invoices.parentInvoice;
export const bankTransactions = (state: RootState) => state.invoices.bankTransactions;

export default invoiceSlice.reducer;
