import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IBreakfastClub, IBreakfastClubMonthHoliday } from "./interfaces";

const initialState: {
  errorMessage: string;
  monthBreakfastClubBooking?: IBreakfastClub[];
  currentMonthHolidays?: string[];
  isLoadingMonthBreakFastClubBooking: boolean;
} = {
  isLoadingMonthBreakFastClubBooking: false,
  errorMessage: "",
  monthBreakfastClubBooking: [],
  currentMonthHolidays: [],
};

export const updateMorningBooking = createAsyncThunk<
  IBreakfastClubMonthHoliday,
  { pupilId: string; date: string; isSignedIn: boolean },
  { rejectValue: string }
>("updateMorningBooking", async ({ pupilId, date, isSignedIn }, { rejectWithValue }) => {
  try {
    const ret = await Api.updateMorningBooking(pupilId, date, isSignedIn);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const getMorningBooking = createAsyncThunk<IBreakfastClubMonthHoliday, void, { rejectValue: string }>(
  "getMorningBooking",
  async (_, { rejectWithValue }) => {
    try {
      const ret = await Api.getMorningBooking();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const morningBookingSlice = createSlice({
  name: "breakfastClubBooking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get breakfast bookings
    builder.addCase(getMorningBooking.fulfilled, (state, { payload }) => {
      state.isLoadingMonthBreakFastClubBooking = false;
      state.monthBreakfastClubBooking = payload.bookings;
      state.currentMonthHolidays = payload.currentMonthHolidays;
    });
    builder.addCase(getMorningBooking.pending, (state, _) => {
      state.isLoadingMonthBreakFastClubBooking = true;
    });
    builder.addCase(getMorningBooking.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingMonthBreakFastClubBooking = false;
      console.log(state.errorMessage);
    });

    builder.addCase(updateMorningBooking.fulfilled, (state, { payload }) => {
      state.isLoadingMonthBreakFastClubBooking = false;
      state.monthBreakfastClubBooking = payload.bookings;
      state.currentMonthHolidays = payload.currentMonthHolidays;
    });
    // builder.addCase(updateMorningBooking.pending, (state, _) => {
    //   //   state.isLoadingMonthBreakFastClubBooking = true;
    // });
    builder.addCase(updateMorningBooking.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingMonthBreakFastClubBooking = false;
      console.log(state.errorMessage);
    });
  },
});

export const monthBreakfastClubBooking = (state: RootState) => state.morningBookings.monthBreakfastClubBooking;
export const currentMonthHolidays = (state: RootState) => state.morningBookings.currentMonthHolidays;
export const isLoadingMonthBreakFastClubBooking = (state: RootState) =>
  state.morningBookings.isLoadingMonthBreakFastClubBooking;

export const errorMessage = (state: RootState) => state.morningBookings.errorMessage;

export default morningBookingSlice.reducer;
