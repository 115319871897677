import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import {
  defaultErrorMessage,
  IBankTransactionData,
  ICategorizedBankTransactionData,
  IFinancialData,
  IGroupedTransactions,
  ITransactionCategory,
} from "./interfaces";

const initialState: {
  isLoadingGroupedPeriodTransactions: boolean;
  isLoadingHistoricFinancialData: boolean;
  isLoadingMonthBankTransactionsData: boolean;
  isLoadingCategorizedBankTransactionsData: boolean;
  isUploadingBankCSV: boolean;
  isLoadingTransactionCategory: boolean;
  errorMessage: string;
  transactionCategory: ITransactionCategory[];
  bankTransactions: { transactionDate: string; transactions: IBankTransactionData[] }[];
  monthBankTransactions: { transactionDate: string; transactions: IBankTransactionData[] }[];
  groupedPeriodTransactions: IGroupedTransactions[];
  historicFinancialStat: IFinancialData[];
  categorizedBankTransactions: ICategorizedBankTransactionData[];
} = {
  isLoadingGroupedPeriodTransactions: false,
  isLoadingHistoricFinancialData: false,
  isLoadingMonthBankTransactionsData: false,
  isLoadingCategorizedBankTransactionsData: false,
  isUploadingBankCSV: false,
  isLoadingTransactionCategory: false,
  errorMessage: "",
  transactionCategory: [],
  bankTransactions: [],
  monthBankTransactions: [],
  groupedPeriodTransactions: [],
  historicFinancialStat: [],
  categorizedBankTransactions: [],
};

export const createTransactionCategory = createAsyncThunk<
  ITransactionCategory[],
  ITransactionCategory,
  { rejectValue: string }
>("banking/createTransactionCategory", async (data, { rejectWithValue }) => {
  try {
    const ret = await Api.createTransactionCategory(data);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const getTransactionCategory = createAsyncThunk<
  ITransactionCategory[],
  string | undefined,
  { rejectValue: string }
>("banking/getTransactionCategory", async (transactionCategoryId, { rejectWithValue }) => {
  try {
    const ret = await Api.getTransactionCategory(transactionCategoryId);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const uploadBankTransaction = createAsyncThunk<
  void,
  IBankTransactionData[],
  { rejectValue: string }
>("banking/uploadBankTransaction", async (transactions, { rejectWithValue }) => {
  try {
    const ret = await Api.uploadBankTransaction(transactions);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});
export const getBankTransaction = createAsyncThunk<
  { transactionDate: string; transactions: IBankTransactionData[] }[],
  number,
  { rejectValue: string }
>("banking/getBankTransaction", async (period, { rejectWithValue }) => {
  try {
    const ret = await Api.getBankTransaction(period);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const editTransactionCategory = createAsyncThunk<
  void,
  { category: string; transactionId: string },
  { rejectValue: string }
>("banking/editTransactionCategory", async (data, { rejectWithValue }) => {
  try {
    const ret = await Api.editTransactionCategory(data);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const groupedPeriodTransactions = createAsyncThunk<
  IGroupedTransactions[],
  void,
  { rejectValue: string }
>("banking/groupedPeriodTransactions", async (_, { rejectWithValue }) => {
  try {
    const ret = await Api.groupedPeriodTransactions();
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const historicFinancialStat = createAsyncThunk<
  IFinancialData[],
  void,
  { rejectValue: string }
>("banking/historicFinancialStat", async (_, { rejectWithValue }) => {
  try {
    const ret = await Api.historicFinancialStat();
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const downloadBankTransactions = createAsyncThunk<
  ICategorizedBankTransactionData[],
  { period: string },
  { rejectValue: string }
>("banking/downloadBankTransactions", async (data, { rejectWithValue }) => {
  try {
    const ret = await Api.downloadBankTransactions(data);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const bankingSlice = createSlice({
  name: "banking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactionCategory.fulfilled, (state, { payload }) => {
      state.isLoadingTransactionCategory = false;
      state.transactionCategory = payload;
    });
    builder.addCase(getTransactionCategory.pending, (state, _) => {
      state.isLoadingTransactionCategory = true;
    });
    builder.addCase(getTransactionCategory.rejected, (state, { payload }) => {
      state.isLoadingTransactionCategory = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    builder.addCase(createTransactionCategory.fulfilled, (state, { payload }) => {
      // state.isLoading = false;
      state.transactionCategory = payload;
    });
    builder.addCase(createTransactionCategory.pending, (state, _) => {
      // state.isLoading = true;
    });
    builder.addCase(createTransactionCategory.rejected, (state, { payload }) => {
      // state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    builder.addCase(uploadBankTransaction.fulfilled, (state) => {
      state.isUploadingBankCSV = false;
    });
    builder.addCase(uploadBankTransaction.pending, (state) => {
      state.isUploadingBankCSV = true;
    });
    builder.addCase(uploadBankTransaction.rejected, (state, { payload }) => {
      state.isUploadingBankCSV = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Get transactions for a period
    builder.addCase(getBankTransaction.fulfilled, (state, action) => {
      if (action.meta.arg === -1) {
        state.bankTransactions = action.payload;
      } else {
        state.isLoadingMonthBankTransactionsData = false;
        state.monthBankTransactions = action.payload;
      }
    });
    builder.addCase(getBankTransaction.pending, (state, action) => {
      if (action.meta.arg !== -1) {
        state.isLoadingMonthBankTransactionsData = true;
      }
    });
    builder.addCase(getBankTransaction.rejected, (state, { payload }) => {
      state.isLoadingMonthBankTransactionsData = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
    // Edit transaction category
    builder.addCase(editTransactionCategory.fulfilled, (state, action) => {
      // state.isLoading = false;
    });
    builder.addCase(editTransactionCategory.pending, (state, _) => {
      // state.isLoading = true;
    });
    builder.addCase(editTransactionCategory.rejected, (state, { payload }) => {
      // state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    builder.addCase(groupedPeriodTransactions.fulfilled, (state, { payload }) => {
      state.isLoadingGroupedPeriodTransactions = false;
      state.groupedPeriodTransactions = payload;
    });
    builder.addCase(groupedPeriodTransactions.pending, (state, _) => {
      state.isLoadingGroupedPeriodTransactions = true;
    });
    builder.addCase(groupedPeriodTransactions.rejected, (state, { payload }) => {
      state.isLoadingGroupedPeriodTransactions = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Get historic data
    builder.addCase(historicFinancialStat.fulfilled, (state, { payload }) => {
      state.isLoadingHistoricFinancialData = false;
      state.historicFinancialStat = payload;
    });
    builder.addCase(historicFinancialStat.pending, (state, _) => {
      state.isLoadingHistoricFinancialData = true;
    });
    builder.addCase(historicFinancialStat.rejected, (state, { payload }) => {
      state.isLoadingHistoricFinancialData = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });

    // Get categorized bank transactions data
    builder.addCase(downloadBankTransactions.fulfilled, (state, { payload }) => {
      state.isLoadingCategorizedBankTransactionsData = false;
      state.categorizedBankTransactions = payload;
    });
    builder.addCase(downloadBankTransactions.pending, (state, _) => {
      state.isLoadingCategorizedBankTransactionsData = true;
    });
    builder.addCase(downloadBankTransactions.rejected, (state, { payload }) => {
      state.isLoadingCategorizedBankTransactionsData = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
  },
});

// isUploadingBankCSV
export const isUploadingBankCSV = (state: RootState) => state.banking.isUploadingBankCSV;
export const isLoadingHistoricFinancialData = (state: RootState) =>
  state.banking.isLoadingHistoricFinancialData;
export const isLoadingGroupedPeriodTransactions = (state: RootState) =>
  state.banking.isLoadingGroupedPeriodTransactions;
export const isLoadingMonthBankTransactionsData = (state: RootState) =>
  state.banking.isLoadingMonthBankTransactionsData;
export const transactionCategories = (state: RootState) => state.banking.transactionCategory;
export const bankTransactions = (state: RootState) => state.banking.bankTransactions;
export const monthBankTransactions = (state: RootState) => state.banking.monthBankTransactions;
export const groupedTransactions = (state: RootState) => state.banking.groupedPeriodTransactions;
export const historicFinancialData = (state: RootState) => state.banking.historicFinancialStat;
export const categorizedBankTransactions = (state: RootState) =>
  state.banking.categorizedBankTransactions;
export const isLoadingCategorizedBankTransactionsData = (state: RootState) =>
  state.banking.isLoadingCategorizedBankTransactionsData;
export const isLoadingTransactionCategory = (state: RootState) =>
  state.banking.isLoadingTransactionCategory;

//
export default bankingSlice.reducer;
