import { Dialog, DialogTitle, DialogContent, Box, CircularProgress, MenuItem } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import { FC, memo } from "react";
import { CustomizedTextField, CustomizedButton } from "../styledComponent";
import * as Yup from "yup";
import { ITransactionCategory, ITransactionCategoryEnum } from "../../slices/interfaces";
import { createTransactionCategory, getTransactionCategory } from "../../slices/banking";
import { showAlert } from "../../slices/alert";
import { useAppDispatch } from "../../modules/hooks/store";

interface ITransactionCategoryProps {
  open: boolean;
  handleClose: () => void;
}

const TransactionCategory: FC<ITransactionCategoryProps> = ({ open, handleClose }): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        fullWidth
        sx={{ textAlign: "center" }}
      >
        <DialogTitle variant="h6">Transaction category</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={
              {
                categoryName: "",
                transactionType: "",
              } as unknown as ITransactionCategory
            }
            validationSchema={Yup.object().shape({
              categoryName: Yup.string().required("Category name is required"),
              transactionType: Yup.number().required("Transaction type is required"),
            })}
            onSubmit={async (values: ITransactionCategory) => {
              const ret = await dispatch(
                createTransactionCategory({
                  ...values,
                })
              );
              if (!createTransactionCategory.fulfilled.match(ret)) {
                dispatch(
                  showAlert({
                    message: `${ret.payload!}`,
                    severity: "error",
                  })
                );
                dispatch(getTransactionCategory());
              } else {
                dispatch(showAlert({ message: "Success", severity: "success" }));
                handleClose();
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              isSubmitting,
            }: FormikProps<ITransactionCategory>) => (
              <Form>
                <Box display={"flex"} flexDirection={"column"}>
                  <CustomizedTextField
                    id="categoryName"
                    name="categoryName"
                    placeholder="Category name"
                    label="Category name"
                    size="small"
                    value={values.categoryName}
                    onChange={handleChange}
                    error={touched.categoryName && Boolean(errors.categoryName)}
                    helperText={touched.categoryName ? errors.categoryName : ""}
                  />
                  <CustomizedTextField
                    id="transactionType"
                    value={values.transactionType}
                    label="Transaction type"
                    select
                    size="small"
                    sx={{
                      textAlign: "left",
                    }}
                    error={touched.transactionType && Boolean(errors.transactionType)}
                    onChange={(e) => {
                      setFieldValue("transactionType", e.target.value);
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Select Type</em>
                    </MenuItem>
                    {Object.keys(ITransactionCategoryEnum).map((type, i) => (
                      <MenuItem key={type} value={i}>
                        {type}
                      </MenuItem>
                    ))}
                  </CustomizedTextField>
                  <CustomizedButton type="submit" variant="contained" color="success">
                    {!isSubmitting ? (
                      "Save"
                    ) : (
                      <CircularProgress size={20} sx={{ color: "white" }} />
                    )}
                  </CustomizedButton>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(TransactionCategory);
