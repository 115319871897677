import { Avatar, Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import { Formik, Form, FormikProps } from "formik";
import { EmailPassType } from "../../slices/interfaces";
import * as Yup from "yup";
import useAuth from "../../modules/hooks/auth";
import { Link } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CustomizedTextField } from "../styledComponent";

export function SignUp() {
  const { signUp } = useAuth();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"space-around"}
        alignItems="center"
        bgcolor="#e8fdfe"
        borderRadius="12px"
        width="80%"
        height="80vh"
      >
        <Avatar sx={{ m: 1, bgcolor: "#021d32" }} variant="rounded">
          <LockOutlinedIcon />
        </Avatar>
        <Formik
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required("Required"),
            password: Yup.string()
              .required("No password provided.")
              .min(8, "Password is too short - should be 8 chars minimum.")
              .matches(/(?=.*[0-9])/, "Password must contain a number."),
            confirmPassword: Yup.string()
              .required("Please retype your password.")
              .oneOf([Yup.ref("password")], "Your passwords do not match."),
          })}
          onSubmit={async (values: EmailPassType) => {
            await signUp({ ...values });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
          }: FormikProps<{
            email: string;
            password: string;
            confirmPassword: string;
          }>) => (
            <Container maxWidth={'sm'}>
              <Form> 
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <CustomizedTextField
                    id="email"
                    name="email"
                    placeholder="john@acme.com"
                    label="Email"
                    type="email"
                    fullWidth
                    value={values.email}
                    autoComplete="email"
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ""}
                    sx={{ marginY: "10px" }}
                  />

                  <CustomizedTextField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    fullWidth
                    autoComplete="new-password"
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    sx={{ marginY: "20px" }}
                    helperText={touched.password ? errors.password : ""}
                  />

                  <CustomizedTextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    autoComplete="new-password"
                    fullWidth
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    sx={{
                      mt: "20px",
                      borderRadius: "20px",
                      height: "40px",
                      bgcolor: "#a5d6a7",
                      color: "black",
                      fontSize: "18px",
                      width: "80%",
                    }}
                  >
                    {!isSubmitting ? "Sign Up" : <CircularProgress color="success" />}
                  </Button>
                </Box>
              </Form>
            </Container>
          )}
        </Formik>
        <Grid item>
          <Link to="/auth/login">Already have an account? Sign in</Link>
        </Grid>
      </Box>
    </Grid>
  );
}

export default SignUp;
