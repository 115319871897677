import {
  Avatar,
  Box,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { lazy, Suspense, useContext, useEffect, useMemo, useState } from "react";
import TransactionCategory from "./TransactionCategory";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import {
  getBankTransaction,
  getTransactionCategory,
  groupedTransactions,
  isLoadingMonthBankTransactionsData,
  isLoadingTransactionCategory,
  monthBankTransactions,
  transactionCategories,
} from "../../slices/banking";
import { AccountTypeEnum, IAuth, IBankTransactionData } from "../../slices/interfaces";
import PageLoadingSpinner from "../PageLoadingSpinner";
import TransactionCategoryChip from "./TransactionCategoryChip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditTransactionCategory from "./EditTransactionCategory";
import GroupedTransactionsTable from "./GroupedTransactionsTable";
import { eachMonthOfInterval, format } from "date-fns";
import { formatNumber, getFinancialYear } from "../../utils";
import HistoricStat from "./HistoricStat";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import GridViewIcon from "@mui/icons-material/GridView";
import { AuthContext } from "../../modules/context";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";

const Transaction = lazy(() => import("./Transaction"));

const Banking = () => {
  const [openCategory, setOpenCategory] = useState<boolean>(false);
  const [openTransaction, setOpenTransaction] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const allTransactionCategories = useAppSelector(transactionCategories);
  const [file, setFile] = useState<File>();
  const [csvData, setCSV] = useState<string>("");
  const monthBankTransactionsData = useAppSelector(monthBankTransactions);
  const isLoadingMonthBankTransactions = useAppSelector(isLoadingMonthBankTransactionsData);
  const isLoadingCategories = useAppSelector(isLoadingTransactionCategory);
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [editTransaction, setEditTransaction] = useState<boolean>(false);
  const [refreshCategoriesList, setRefreshCategoriesList] = useState<boolean>(true);
  const [updateTransactions, setUpdateTransactions] = useState<boolean>(true);
  const allGroupedTransactions = useAppSelector(groupedTransactions);
  const { dbUser } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();

  const [selectedTransaction, setSelectedTransaction] = useState<IBankTransactionData>();

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const _fileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && setFile(e.target.files[0]);
    setUpdateTransactions(false);
  };
  const _handleClose = (update: boolean) => {
    setUpdateTransactions(update);
    setFile(undefined);
    setOpenTransaction(false);
    setRefreshCategoriesList(false);
  };
  logEvent(analytics, "wBanking", { firebase_screen_class: "wBanking", page_title: "Banking" });

  const transformTransactions = useMemo(() => {
    const ret = monthBankTransactionsData.reduce<IBankTransactionData[]>(
      (acc, curr) => [...acc, ...curr.transactions],
      []
    );
    return ret;
  }, [monthBankTransactionsData]);

  const financialPeriod = getFinancialYear();

  const currentFinancialYear = eachMonthOfInterval({
    start: financialPeriod.current.currentFinancialStartDate,
    end: financialPeriod.current.currentFinancialEndDate,
  });

  const accountSummary = useMemo(() => {
    const summary = {
      bankBalance: 0,
      totalRevenue: 0,
      totalExpense: 0,
    };
    const yearToDate: { [x: string]: number } = {};
    for (const monthTransaction of allGroupedTransactions) {
      const { groupedTransactions } = monthTransaction;
      for (const data of groupedTransactions) {
        yearToDate[data.category] = (yearToDate[data.category] ?? 0) + data.total;
        const transactionType = allTransactionCategories.find(
          (x) => x.categoryName === data.category
        );
        if (transactionType?.transactionType === 1) {
          summary.totalRevenue += data.total;
        } else {
          summary.totalExpense += data.total;
        }
      }
    }
    summary.bankBalance = summary.totalRevenue - summary.totalExpense;

    return { summary, yearToDate };
  }, [allGroupedTransactions, allTransactionCategories]);

  useEffect(() => {
    if (file) {
      let fileReader,
        isCancel = false;
      if (file) {
        fileReader = new FileReader();
        fileReader.onload = (e) => {
          const { result } = e.target as FileReader;
          if (result && !isCancel) {
            const decoder = new TextDecoder("utf-8");
            const csv = decoder.decode(result as ArrayBuffer);
            var lines = csv.split(/[\r\n]+/g);
            if (lines[0].endsWith(",")) lines[0] = lines[0].slice(0, -1);
            const correctedCSV = lines.map((field) => field + "\n").join("");
            setCSV(correctedCSV);
            setOpenTransaction(true);
            dispatch(getBankTransaction(-1));
          }
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
    if (dbUser && dbUser.accountType !== AccountTypeEnum.ADMIN) {
      navigate("/", { replace: true });
    }
    if (updateTransactions === true && !openTransaction) dispatch(getBankTransaction(month));
    if (!file && refreshCategoriesList) dispatch(getTransactionCategory());
  }, [
    csvData,
    dispatch,
    file,
    month,
    openTransaction,
    refreshCategoriesList,
    updateTransactions,
    dbUser,
    navigate,
  ]);

  const _editTransactionCategory = async (transaction: IBankTransactionData) => {
    setSelectedTransaction(transaction);
    setEditTransaction(!editTransaction);
  };

  const _openCreateCategoryDialog = () => {
    setOpenCategory(true);
  };

  const _handleEditTransactionClose = () => {
    setEditTransaction(!editTransaction);
    setSelectedTransaction(undefined);
    dispatch(getBankTransaction(month));
  };
  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} mt={1}>
          <Grid
            container
            sx={{ border: "1px solid #ebebeb", bgcolor: "white" }}
            columnGap={1}
            wrap="nowrap"
            p={1}
          >
            <Grid item xs={4} container bgcolor={"#7082383d"} sx={{ borderRadius: "5px" }}>
              <Grid item container justifyContent={"center"}>
                <Typography fontSize={"20px"}>Revenue</Typography>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Typography fontSize={"15px"}>
                  {" "}
                  {formatNumber(accountSummary.summary.totalRevenue)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} container bgcolor={"#fcbe8554"} sx={{ borderRadius: "5px" }}>
              <Grid item container justifyContent={"center"}>
                <Typography fontSize={"20"}>Expense</Typography>
              </Grid>

              <Grid item container justifyContent={"center"}>
                <Typography fontSize={"15"}>
                  {" "}
                  {formatNumber(accountSummary.summary.totalExpense)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} container bgcolor={"#e8f4f8"} sx={{ borderRadius: "5px" }}>
              <Grid item container justifyContent={"center"}>
                <Typography fontSize={"20"}>Balance</Typography>
              </Grid>

              <Grid item container justifyContent={"center"}>
                <Typography fontSize={"15px"}>
                  {" "}
                  {formatNumber(accountSummary.summary.bankBalance)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          mt={checked ? 1 : 0}
          sx={{ border: !checked ? "none" : "1px solid #ebebeb", bgcolor: "white" }}
        >
          <Tooltip title="View transaction categories">
            <IconButton
              color="secondary"
              onClick={handleChange}
              size="small"
              sx={{ position: "absolute", top: 70, left: 10, zIndex: 5 }}
            >
              <Avatar sx={{ bgcolor: "orangered" }}>
                {checked ? <HighlightOffIcon /> : <GridViewIcon />}
              </Avatar>
            </IconButton>
          </Tooltip>

          <Tooltip title="File upload">
            <IconButton
              size="small"
              component="label"
              aria-label="file upload"
              sx={{ position: "absolute", top: 70, right: 10 }}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                ((event.target as any).value = null)
              }
            >
              <input
                hidden
                accept="application/vnd.ms-excel, text/csv"
                type="file"
                onChange={_fileSelected}
              />
              <Avatar sx={{ bgcolor: "green" }}>
                <UploadFileIcon />
              </Avatar>
            </IconButton>
          </Tooltip>

          <Collapse in={checked}>
            {isLoadingCategories ? (
              <ComponentLoadingSpinner height="100px" />
            ) : (
              <TransactionCategoryChip
                allTransactionCategories={allTransactionCategories}
                openCreateCategory={_openCreateCategoryDialog}
              ></TransactionCategoryChip>
            )}
          </Collapse>
        </Grid>
        <Grid item xs={12} mt={1}>
          <HistoricStat />
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid
            container
            sx={{ border: "1px solid #ebebeb", bgcolor: "white" }}
            p={1}
            alignItems={"flex-start"}
            height={"90vh"}
            mb={5}
            pb={2}
          >
            <Grid item xs={3} height={"100%"} pr={"10px"}>
              <Grid
                item
                xs={12}
                height={"43px"}
                alignItems={"center"}
                justifyContent={"center"}
                container
              >
                <TextField
                  id="month"
                  value={month}
                  select
                  size="small"
                  label="Month"
                  sx={{
                    height: "35px",
                    fontSize: "12px",
                    p: 0,
                    m: 0,
                  }}
                  InputProps={{
                    sx: { fontSize: "12px", p: 0, m: 0, height: "30px" },
                  }}
                  fullWidth
                  onChange={(e) => {
                    setMonth(parseInt(e.target.value));
                    setRefreshCategoriesList(false);
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Month</em>
                  </MenuItem>
                  {currentFinancialYear.map((x, i) => (
                    <MenuItem
                      key={x.toLocaleDateString()}
                      value={x.getMonth()}
                      sx={{ fontSize: "12px" }}
                    >
                      {format(x, "MMM yy")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} height="95%">
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{
                    position: "relative",
                    height: "100%",
                    overflow: "auto",
                    td: { fontSize: "11px" },
                    th: {
                      position: "sticky",
                      top: "0px",
                      zIndex: 10,
                      p: 1,
                    },
                  }}
                >
                  {isLoadingMonthBankTransactions ? (
                    <ComponentLoadingSpinner />
                  ) : (
                    <Table
                      aria-label="transactionCategory"
                      size="small"
                      sx={{
                        td: {
                          p: "3px",
                        },
                      }}
                    >
                      <TableHead
                        sx={{
                          th: {
                            backgroundColor: "#14e6dd	",
                            fontSize: "11px",
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell width={"2px"}>Date</TableCell>
                          <TableCell width={"50%"}>Description</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell colSpan={2} align="center">
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transformTransactions.length > 0 ? (
                          transformTransactions
                            .sort((a, b) => b.transactionDate.localeCompare(a.transactionDate))
                            .map((transaction) => {
                              return (
                                <TableRow
                                  key={transaction._id}
                                  className={
                                    transaction.category?.transactionType === 0
                                      ? "RowDebit"
                                      : "RowCredit"
                                  }
                                >
                                  <TableCell>{transaction.transactionDate.slice(0, 2)}</TableCell>
                                  <TableCell
                                    title={
                                      transaction.transactionDescription.length > 34
                                        ? transaction.transactionDescription
                                        : undefined
                                    }
                                  >
                                    {transaction.transactionDescription.substring(0, 18)}{" "}
                                    {transaction.transactionDescription.length >= 18 && "..."}
                                  </TableCell>
                                  <TableCell>
                                    {transaction.category?.categoryName.substring(0, 5)}{" "}
                                    {transaction.category?.categoryName &&
                                      transaction.category?.categoryName.length >= 5 &&
                                      "..."}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "right" }}>
                                    {transaction.amount.toFixed(2)}
                                  </TableCell>
                                  <TableCell sx={{ px: "5px" }}>
                                    <ModeEditIcon
                                      fontSize="small"
                                      color="info"
                                      onClick={() => _editTransactionCategory(transaction)}
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align={"center"}>
                              <Box
                                height={"78vh"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                display={"flex"}
                                overflow={"hidden"}
                              >
                                No Data Available
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Grid>
            </Grid>
            <Grid item xs={9} height={"100%"}>
              <GroupedTransactionsTable month={month} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {editTransaction && (
        <EditTransactionCategory
          open={editTransaction}
          handleClose={_handleEditTransactionClose}
          transaction={selectedTransaction}
        />
      )}
      {openCategory && (
        <TransactionCategory open={openCategory} handleClose={() => setOpenCategory(false)} />
      )}
      <Suspense fallback={<PageLoadingSpinner />}>
        {openTransaction && (
          <Transaction open={openTransaction} handleClose={_handleClose} csvData={csvData} />
        )}
      </Suspense>
    </>
  );
};

export default Banking;
