import { Box } from "@mui/material";
import { useEffect } from "react";
import anime from "animejs";

function AppInitiation() {
  useEffect(() => {
    anime({
      targets: ".line-drawing-demo .lines path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: function (_, i: number) {
        return i * 100;
      },
      direction: "alternate",
      loop: true,
    });
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className="line-drawing-demo">
        <svg
          width="724"
          height="109.101"
          viewBox="0 0 724 120.101"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            fill="none"
            fillRule="evenodd"
            stroke="#1d8bc7"
            strokeWidth="0.9mm"
            className="lines"
            strokeLinecap="round"
          >
            <path
              d="M 43.5 11 L 63.1 11 L 63.1 81.5 L 43.5 81.5 L 43.5 53.2 L 19.6 53.2 L 19.6 81.5 L 0 81.5 L 0 11 L 19.6 11 L 19.6 37.5 L 43.5 37.5 L 43.5 11 Z"
              id="0"
            />
            <path
              d="M 112 25.4 L 131.7 25.4 L 131.7 81.5 L 112 81.5 L 112 73.5 A 18.016 18.016 0 0 1 106.493 78.84 A 21.836 21.836 0 0 1 105.1 79.65 A 19.893 19.893 0 0 1 98.145 81.809 A 25.198 25.198 0 0 1 95 82 A 23.959 23.959 0 0 1 88.234 81.093 A 18.086 18.086 0 0 1 79.55 75.55 A 21.733 21.733 0 0 1 74.943 66.681 Q 74.063 63.381 73.925 59.47 A 41.82 41.82 0 0 1 73.9 58 L 73.9 25.4 L 93.4 25.4 L 93.4 55.5 A 14.609 14.609 0 0 0 93.701 58.558 Q 94.128 60.553 95.16 62.061 A 8.567 8.567 0 0 0 95.95 63.05 A 8.46 8.46 0 0 0 101.428 65.733 A 11.739 11.739 0 0 0 102.7 65.8 A 10.831 10.831 0 0 0 105.672 65.414 A 8.045 8.045 0 0 0 109.5 63.05 Q 112 60.3 112 55.5 L 112 25.4 Z"
              id="1"
            />
            <path
              d="M 238.9 48.8 L 238.9 81.5 L 219.3 81.5 L 219.3 51.4 A 13.008 13.008 0 0 0 219.008 48.558 Q 218.578 46.637 217.515 45.223 A 7.742 7.742 0 0 0 216.8 44.4 A 8.311 8.311 0 0 0 212.541 42.151 A 12.241 12.241 0 0 0 210 41.9 Q 205.7 41.9 203.2 44.4 A 8.179 8.179 0 0 0 201.081 48.19 Q 200.748 49.461 200.706 50.966 A 15.683 15.683 0 0 0 200.7 51.4 L 200.7 81.5 L 181.1 81.5 L 181.1 51.4 A 13.279 13.279 0 0 0 180.817 48.573 Q 180.267 46.051 178.65 44.4 Q 176.2 41.9 171.9 41.9 Q 167.522 41.9 165.025 44.375 A 8.008 8.008 0 0 0 165 44.4 A 8.179 8.179 0 0 0 162.881 48.19 Q 162.548 49.461 162.506 50.966 A 15.683 15.683 0 0 0 162.5 51.4 L 162.5 81.5 L 142.9 81.5 L 142.9 25.4 L 162.5 25.4 L 162.5 32.8 A 18.897 18.897 0 0 1 169.234 27.059 A 21.544 21.544 0 0 1 169.25 27.05 A 19.086 19.086 0 0 1 175.528 25.113 A 24.445 24.445 0 0 1 178.8 24.9 Q 185 24.9 189.85 27.6 Q 194.7 30.3 197.5 35.2 Q 200.5 30.6 205.4 27.75 Q 210.3 24.9 216.2 24.9 A 28.251 28.251 0 0 1 223.231 25.727 A 19.655 19.655 0 0 1 232.85 31.3 Q 238.753 37.545 238.896 48.264 A 40.108 40.108 0 0 1 238.9 48.8 Z"
              id="2"
            />
            <path
              d="M 345.6 48.8 L 345.6 81.5 L 326 81.5 L 326 51.4 A 13.008 13.008 0 0 0 325.708 48.558 Q 325.278 46.637 324.215 45.223 A 7.742 7.742 0 0 0 323.5 44.4 A 8.311 8.311 0 0 0 319.241 42.151 A 12.241 12.241 0 0 0 316.7 41.9 Q 312.4 41.9 309.9 44.4 A 8.179 8.179 0 0 0 307.781 48.19 Q 307.448 49.461 307.406 50.966 A 15.683 15.683 0 0 0 307.4 51.4 L 307.4 81.5 L 287.8 81.5 L 287.8 51.4 A 13.279 13.279 0 0 0 287.517 48.573 Q 286.967 46.051 285.35 44.4 Q 282.9 41.9 278.6 41.9 Q 274.222 41.9 271.725 44.375 A 8.008 8.008 0 0 0 271.7 44.4 A 8.179 8.179 0 0 0 269.581 48.19 Q 269.248 49.461 269.206 50.966 A 15.683 15.683 0 0 0 269.2 51.4 L 269.2 81.5 L 249.6 81.5 L 249.6 25.4 L 269.2 25.4 L 269.2 32.8 A 18.897 18.897 0 0 1 275.934 27.059 A 21.544 21.544 0 0 1 275.95 27.05 A 19.086 19.086 0 0 1 282.228 25.113 A 24.445 24.445 0 0 1 285.5 24.9 Q 291.7 24.9 296.55 27.6 Q 301.4 30.3 304.2 35.2 Q 307.2 30.6 312.1 27.75 Q 317 24.9 322.9 24.9 A 28.251 28.251 0 0 1 329.931 25.727 A 19.655 19.655 0 0 1 339.55 31.3 Q 345.453 37.545 345.596 48.264 A 40.108 40.108 0 0 1 345.6 48.8 Z"
              id="3"
            />
            <path
              d="M 356.3 25.4 L 375.9 25.4 L 375.9 81.5 L 356.3 81.5 L 356.3 25.4 Z M 362.606 19.804 A 10.656 10.656 0 0 1 357.85 17.35 A 9.188 9.188 0 0 1 354.709 10.666 A 11.426 11.426 0 0 1 354.7 10.2 Q 354.7 5.8 357.85 2.9 A 10.699 10.699 0 0 1 363.008 0.313 A 15.507 15.507 0 0 1 366.2 0 A 14.592 14.592 0 0 1 369.853 0.434 A 10.567 10.567 0 0 1 374.45 2.9 A 9.446 9.446 0 0 1 377.593 9.787 A 12.003 12.003 0 0 1 377.6 10.2 A 9.587 9.587 0 0 1 376.711 14.36 A 9.531 9.531 0 0 1 374.45 17.35 A 10.787 10.787 0 0 1 369.197 19.922 A 15.395 15.395 0 0 1 366.2 20.2 A 15.403 15.403 0 0 1 362.606 19.804 Z"
              id="4"
            />
            <path
              d="M 444.9 48.8 L 444.9 81.5 L 425.3 81.5 L 425.3 51.4 Q 425.3 46.6 422.8 43.85 A 8.261 8.261 0 0 0 417.494 41.182 A 11.603 11.603 0 0 0 416.1 41.1 A 11.332 11.332 0 0 0 413.183 41.456 A 8.059 8.059 0 0 0 409.2 43.85 Q 406.7 46.6 406.7 51.4 L 406.7 81.5 L 387.1 81.5 L 387.1 25.4 L 406.7 25.4 L 406.7 33.4 Q 409.3 29.6 413.7 27.25 A 19.893 19.893 0 0 1 420.655 25.092 A 25.198 25.198 0 0 1 423.8 24.9 A 23.512 23.512 0 0 1 430.704 25.865 A 18.273 18.273 0 0 1 439.2 31.35 A 21.795 21.795 0 0 1 443.941 40.542 Q 444.78 43.82 444.885 47.685 A 41.132 41.132 0 0 1 444.9 48.8 Z"
              id="5"
            />
            <path
              d="M 492.9 33.2 L 492.9 25.4 L 512.5 25.4 L 512.5 80.9 A 35.873 35.873 0 0 1 511.551 89.298 A 30.126 30.126 0 0 1 509.5 95.15 A 23.192 23.192 0 0 1 500.348 105.181 A 27.883 27.883 0 0 1 500.15 105.3 Q 493.8 109.1 484.1 109.1 Q 473.587 109.1 466.475 105.112 A 24.984 24.984 0 0 1 463.3 103 A 23.017 23.017 0 0 1 454.518 87.375 A 30.553 30.553 0 0 1 454.4 86.4 L 473.7 86.4 A 6.416 6.416 0 0 0 476.607 90.541 A 8.052 8.052 0 0 0 476.7 90.6 A 9.2 9.2 0 0 0 479.353 91.702 Q 480.941 92.1 482.9 92.1 A 12.745 12.745 0 0 0 487.038 91.485 Q 492.671 89.554 492.891 81.56 A 23.986 23.986 0 0 0 492.9 80.9 L 492.9 73.7 A 16.425 16.425 0 0 1 486.874 79.607 A 19.64 19.64 0 0 1 486.35 79.9 A 18.82 18.82 0 0 1 480.31 81.903 A 24.793 24.793 0 0 1 476.4 82.2 Q 469.7 82.2 464.25 78.7 A 22.916 22.916 0 0 1 457.145 71.382 A 28.852 28.852 0 0 1 455.65 68.65 A 31.202 31.202 0 0 1 453.02 60.114 A 41.594 41.594 0 0 1 452.5 53.4 A 40.587 40.587 0 0 1 453.186 45.758 A 30.339 30.339 0 0 1 455.65 38.2 A 26.245 26.245 0 0 1 459.646 32.141 A 22.242 22.242 0 0 1 464.25 28.2 Q 469.7 24.7 476.4 24.7 A 23.902 23.902 0 0 1 481.425 25.206 A 18.406 18.406 0 0 1 486.35 27 A 17.157 17.157 0 0 1 491.181 30.813 A 15.999 15.999 0 0 1 492.9 33.2 Z M 490.584 45.637 A 9.737 9.737 0 0 0 489.95 44.9 A 9.589 9.589 0 0 0 482.984 41.804 A 11.746 11.746 0 0 0 482.7 41.8 A 10.389 10.389 0 0 0 478.791 42.515 A 9.582 9.582 0 0 0 475.4 44.85 A 10.014 10.014 0 0 0 473.026 49.161 Q 472.5 51.059 472.5 53.4 A 16.251 16.251 0 0 0 472.866 56.955 Q 473.417 59.411 474.793 61.225 A 9.944 9.944 0 0 0 475.4 61.95 A 9.446 9.446 0 0 0 482.287 65.093 A 12.003 12.003 0 0 0 482.7 65.1 A 9.594 9.594 0 0 0 489.753 62.203 A 11.581 11.581 0 0 0 489.95 62 Q 492.746 59.063 492.892 53.97 A 19.82 19.82 0 0 0 492.9 53.4 Q 492.9 48.616 490.584 45.637 Z"
              id="6"
            />
            <path
              d="M 543.3 73.7 L 543.3 81.5 L 523.7 81.5 L 523.7 7.5 L 543.3 7.5 L 543.3 33.2 Q 545.6 29.2 549.8 26.95 Q 554 24.7 559.7 24.7 Q 566.4 24.7 571.85 28.2 A 23.08 23.08 0 0 1 579.075 35.684 A 28.847 28.847 0 0 1 580.45 38.2 A 30.788 30.788 0 0 1 583.08 46.695 A 41.441 41.441 0 0 1 583.6 53.4 A 40.734 40.734 0 0 1 582.908 61.084 A 30.751 30.751 0 0 1 580.45 68.65 A 26.36 26.36 0 0 1 576.522 74.672 A 22.089 22.089 0 0 1 571.85 78.7 Q 566.4 82.2 559.7 82.2 A 24.32 24.32 0 0 1 554.764 81.722 A 18.311 18.311 0 0 1 549.8 79.95 Q 545.6 77.7 543.3 73.7 Z M 563.7 53.4 A 16.497 16.497 0 0 0 563.367 49.982 Q 562.709 46.876 560.75 44.85 A 9.662 9.662 0 0 0 553.767 41.803 A 11.95 11.95 0 0 0 553.5 41.8 A 9.594 9.594 0 0 0 546.447 44.698 A 11.581 11.581 0 0 0 546.25 44.9 A 10.302 10.302 0 0 0 543.732 49.603 Q 543.3 51.333 543.3 53.4 Q 543.3 57.942 545.311 60.847 A 9.587 9.587 0 0 0 546.25 62 A 9.589 9.589 0 0 0 553.216 65.097 A 11.746 11.746 0 0 0 553.5 65.1 A 9.508 9.508 0 0 0 560.47 62.239 A 11.61 11.61 0 0 0 560.75 61.95 A 10.539 10.539 0 0 0 563.285 57.134 Q 563.674 55.532 563.698 53.652 A 19.484 19.484 0 0 0 563.7 53.4 Z"
              id="7"
            />
            <path
              d="M 591.7 25.4 L 611.3 25.4 L 611.3 81.5 L 591.7 81.5 L 591.7 25.4 Z M 598.006 19.804 A 10.656 10.656 0 0 1 593.25 17.35 A 9.188 9.188 0 0 1 590.109 10.666 A 11.426 11.426 0 0 1 590.1 10.2 Q 590.1 5.8 593.25 2.9 A 10.699 10.699 0 0 1 598.408 0.313 A 15.507 15.507 0 0 1 601.6 0 A 14.592 14.592 0 0 1 605.253 0.434 A 10.567 10.567 0 0 1 609.85 2.9 A 9.446 9.446 0 0 1 612.993 9.787 A 12.003 12.003 0 0 1 613 10.2 A 9.587 9.587 0 0 1 612.111 14.36 A 9.531 9.531 0 0 1 609.85 17.35 A 10.787 10.787 0 0 1 604.597 19.922 A 15.395 15.395 0 0 1 601.6 20.2 A 15.403 15.403 0 0 1 598.006 19.804 Z"
              id="8"
            />
            <path
              d="M 660.2 24.9 L 660.2 45.8 L 654.7 45.8 A 26.272 26.272 0 0 0 651.361 45.998 Q 647.428 46.504 645.2 48.3 Q 642.1 50.8 642.1 57 L 642.1 81.5 L 622.5 81.5 L 622.5 25.4 L 642.1 25.4 L 642.1 35.3 A 26.027 26.027 0 0 1 648.111 29.021 A 24.424 24.424 0 0 1 650.1 27.7 Q 654.8 24.9 660.2 24.9 Z"
              id="9"
            />
            <path
              d="M 704.3 33.1 L 704.3 7.5 L 724 7.5 L 724 81.5 L 704.3 81.5 L 704.3 73.7 A 16.582 16.582 0 0 1 698.061 79.755 A 19.63 19.63 0 0 1 697.8 79.9 A 18.393 18.393 0 0 1 691.964 81.877 A 24.534 24.534 0 0 1 687.9 82.2 Q 681.2 82.2 675.75 78.7 A 22.916 22.916 0 0 1 668.645 71.382 A 28.852 28.852 0 0 1 667.15 68.65 A 31.202 31.202 0 0 1 664.52 60.114 A 41.594 41.594 0 0 1 664 53.4 A 40.587 40.587 0 0 1 664.686 45.758 A 30.339 30.339 0 0 1 667.15 38.2 Q 670.3 31.7 675.8 28.2 Q 681.3 24.7 688 24.7 Q 693.4 24.7 697.65 26.95 Q 701.9 29.2 704.3 33.1 Z M 702.084 45.637 A 9.737 9.737 0 0 0 701.45 44.9 A 9.589 9.589 0 0 0 694.484 41.804 A 11.746 11.746 0 0 0 694.2 41.8 A 10.389 10.389 0 0 0 690.291 42.515 A 9.582 9.582 0 0 0 686.9 44.85 A 10.014 10.014 0 0 0 684.526 49.161 Q 684 51.059 684 53.4 A 16.251 16.251 0 0 0 684.366 56.955 Q 684.917 59.411 686.293 61.225 A 9.944 9.944 0 0 0 686.9 61.95 A 9.446 9.446 0 0 0 693.787 65.093 A 12.003 12.003 0 0 0 694.2 65.1 A 9.594 9.594 0 0 0 701.253 62.203 A 11.581 11.581 0 0 0 701.45 62 Q 704.246 59.063 704.392 53.97 A 19.82 19.82 0 0 0 704.4 53.4 Q 704.4 48.616 702.084 45.637 Z"
              id="10"
            />
          </g>
        </svg>
      </Box>
    </Box>
  );
}

export default AppInitiation;
