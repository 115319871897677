import { createContext } from "react";
import { IAuth, IBooking } from "../../slices/interfaces";

export const AuthContext = createContext<IAuth>({
  dbUser: null,
  user: null,
  setDbUser: () => {},
  setLoading: () => {},
});

export const BookingContext = createContext<IBooking>({
  startYear: new Date(),
  setStartYear: () => {},
  filterHolidays: [],
  setCalendarPeriod: () => {},
  calendarPeriod: [],
  selectedOffDays: [],
  setSelectedOffDays: () => {},
  saveTerm: async (editMode: boolean, termId?: string) => {},
  setCurrentSchoolTermHolidays: () => {},
  currentSchoolTermHolidays: [],
  bookedDays: [],
  setBookedDays: () => {},
  savePupilBooking: async (pupilId: string) => {},
});
