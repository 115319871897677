import { Container } from "@mui/material";
import { memo, ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import ResponsiveAppBar from "../../components/appBar/Index";
import { useAppSelector } from "../hooks/store";
import { IAuth } from "../../slices/interfaces";
import { isLoading } from "../../slices/user";
import { AuthContext } from "../context";
import AppInitiation from "../../components/AppInitiation";

interface IAuthRouteProps {
  children: ReactNode;
}
const AuthRoute: React.FC<IAuthRouteProps> = (props) => {
  const { children } = props;
  const { user, dbUser } = useContext(AuthContext) as IAuth;
  const loading = useAppSelector(isLoading);

  if (user && user.emailVerified && !dbUser) {
    <Navigate to={"/auth/register"} />;
  }
  return (
    <>
      {loading ? (
        <AppInitiation />
      ) : (
        <>
          <ResponsiveAppBar />
          <Container maxWidth={false} sx={{ height: "90vh" }}>
            {children}
          </Container>
        </>
      )}
    </>
  );
};

export default memo(AuthRoute);
