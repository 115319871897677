import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Grid } from "@mui/material";
import { formatNumber, getFinancialYear } from "../../utils";
import { eachMonthOfInterval, format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { historicFinancialData, historicFinancialStat, isLoadingHistoricFinancialData } from "../../slices/banking";
import { Fragment, useEffect, useMemo } from "react";
import { IHistoricData, financialPeriod } from "../../slices/interfaces";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import { Legend, Tooltip, XAxis, YAxis, CartesianGrid, AreaChart, Area, ResponsiveContainer } from "recharts";
const HistoricStat = () => {
  const financialPeriodData = getFinancialYear();
  const dispatch = useAppDispatch();
  const historicData = useAppSelector(historicFinancialData);
  const isLoading = useAppSelector(isLoadingHistoricFinancialData);
  const currentFinancialYear = eachMonthOfInterval({
    start: financialPeriodData.current.currentFinancialStartDate,
    end: financialPeriodData.current.currentFinancialEndDate,
  });

  const graphData = useMemo(() => {
    const financialObj: {
      [x in financialPeriod]: Omit<IHistoricData, "paidInvoiceTotal" | "partPaidInvoice">[];
    } = {
      current: [],
      previousOne: [],
      previousTwo: [],
    };
    if (financialPeriodData && historicData.length > 0) {
      Object.keys(financialPeriodData)
        .sort()
        .reverse()
        .forEach((y) => {
          const formattedData: Omit<IHistoricData, "paidInvoiceTotal" | "partPaidInvoice">[] = [];
          let data = Object.values(historicData.find((i) => Object.keys(i)[0] === y)!)[0];
          const getFinancialPeriod = eachMonthOfInterval({
            start: financialPeriodData[y as financialPeriod].currentFinancialStartDate,
            end: financialPeriodData[y as financialPeriod].currentFinancialEndDate,
          });
          getFinancialPeriod.forEach((x) => {
            const monthData = data.find(
              (eachMonth) => format(new Date(eachMonth.period), "MMM yy") === format(x, "MMM yy")
            );
            formattedData.push(
              monthData
                ? Object.assign(
                    {},
                    {
                      ...monthData,
                      period: format(x, "MMM"),
                      paidInvoiceTotal: undefined,
                      partPaidInvoice: undefined,
                    }
                  )
                : {
                    monthInvoiceTotal: 0,
                    period: format(x, "MMM"),
                  }
            );
            financialObj[y as financialPeriod] = formattedData;
          });
        });
    }
    return financialObj;
  }, [financialPeriodData, historicData]);
  useEffect(() => {
    dispatch(historicFinancialStat());
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <ComponentLoadingSpinner />
      ) : (
        <Grid container sx={{ border: "1px solid #ebebeb", bgcolor: "white", p: 1 }}>
          <Grid item xs={5}>
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                position: "relative",
                height: "100%",
                overflow: "auto",
                th: { color: "white" },
                "td:nth-of-type(n+2), th:nth-of-type(n+2)": {
                  textAlign: "right",
                },
                "th:nth-of-type(n+4)": {
                  backgroundColor: "#FF6E00",
                },
                "th:nth-of-type(3)": {
                  backgroundColor: "#708238",
                },
                "td:nth-of-type(3)": {
                  backgroundColor: "#7082383d",
                },
                "th:nth-of-type(2)": {
                  backgroundColor: "#0040ff",
                },
                "th:nth-of-type(1)": {
                  borderLeft: "1px solid white",
                },
                "td:nth-of-type(2)": {
                  backgroundColor: "#0040ff26",
                },
                "th, td": {
                  fontSize: "11px",
                  borderRight: "1px solid #ebebeb",
                  borderLeft: "1px solid #ebebeb",
                },
                "td:nth-of-type(4)": {
                  borderLeft: "1px solid red",
                },
                "td:nth-of-type(1)": {
                  backgroundColor: "#14e6dd	",
                  color: "black",
                },
                "td:nth-of-type(n+4)": {
                  backgroundColor: "#fcbe8554",
                },
              }}
            >
              <Table aria-label="transactionCategory" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.keys(financialPeriodData)
                      .sort()
                      .reverse()
                      .map((x) => (
                        <TableCell key={x.toString()}>
                          {format(financialPeriodData[x as financialPeriod].currentFinancialStartDate, "yyyy")} -{" "}
                          {format(financialPeriodData[x as financialPeriod].currentFinancialEndDate, "yyyy")}
                        </TableCell>
                      ))}
                    <TableCell>Paid</TableCell>
                    <TableCell>Outstanding</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentFinancialYear.map((x) => {
                    return (
                      <TableRow key={x.toLocaleDateString()}>
                        <TableCell>{format(x, "MMMM")}</TableCell>
                        {historicData.length !== 0 &&
                          Object.keys(financialPeriodData)
                            .sort()
                            .reverse()
                            .map((y) => {
                              let data = Object.values(historicData.find((i) => Object.keys(i)[0] === y)!)[0];
                              const monthData = data.find((eachMonth) => {
                                return format(new Date(eachMonth.period), "MMM") === format(x, "MMM");
                              });

                              if (y !== "current") {
                                return <TableCell key={y}>{formatNumber(monthData?.monthInvoiceTotal ?? 0)}</TableCell>;
                              } else {
                                return (
                                  <Fragment key={y}>
                                    <TableCell>{formatNumber(monthData?.monthInvoiceTotal ?? 0)}</TableCell>
                                    <TableCell>
                                      {formatNumber(
                                        (monthData?.paidInvoiceTotal ?? 0) +
                                          (monthData?.partPaidInvoice ?? 0) +
                                          (monthData?.paidTravelInvoice ?? 0)
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {formatNumber(
                                        (monthData?.monthInvoiceTotal ?? 0) -
                                          (monthData?.paidInvoiceTotal ?? 0) -
                                          (monthData?.partPaidInvoice ?? 0) -
                                          (monthData?.paidTravelInvoice ?? 0)
                                      )}
                                    </TableCell>
                                  </Fragment>
                                );
                              }
                            })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={7} container>
            <Grid item xs={12}>
              <ResponsiveContainer>
                <AreaChart margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="1" />
                  <XAxis dataKey="period" allowDuplicatedCategory={false} fontSize={11} />
                  <YAxis fontSize={11} />
                  <Tooltip />
                  <Legend verticalAlign="top" height={30} wrapperStyle={{ fontSize: 12 }} />
                  {Object.keys(graphData)
                    .sort()
                    .reverse()
                    .map((s) => {
                      let fill,
                        stroke: string = "";
                      const title = `${format(
                        financialPeriodData[s as financialPeriod].currentFinancialStartDate,
                        "yyyy"
                      )} -   ${format(financialPeriodData[s as financialPeriod].currentFinancialEndDate, "yyyy")}`;
                      switch (s) {
                        case "current":
                          fill = "rgba(255, 128, 0, 0.4)";
                          stroke = "rgba(255, 128, 0, 1)";
                          break;
                        case "previousOne":
                          fill = "rgba(0,255,0,0.3)";
                          stroke = "#708238";
                          break;
                        case "previousTwo":
                          fill = "rgba(0, 64, 255, 0.4)";
                          stroke = "rgba(0, 64, 255, 1)";
                          break;
                      }
                      return (
                        <Area
                          dataKey="monthInvoiceTotal"
                          data={graphData[s as financialPeriod]}
                          name={title}
                          key={s}
                          type={"monotone"}
                          stroke={stroke}
                          fill={fill}
                          fontSize={10}
                        />
                      );
                    })}
                </AreaChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HistoricStat;
