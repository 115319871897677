import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { createBlog, getBlogs, isCreatingBlog } from "../../slices/blog";
import { showAlert } from "../../slices/alert";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { IBlog } from "../../slices/interfaces";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";

interface IBlogProps {
  open: boolean;
  handleClose: () => void;
  blog?: IBlog;
}
const storage = getStorage();
const Blog: FC<IBlogProps> = ({ open, handleClose, blog }): JSX.Element => {
  const [value, setValue] = useState<string>();
  const [file, setFile] = useState<File>();
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const [previousImage, setPreviousImage] = useState<string>();
  const isSaving = useAppSelector(isCreatingBlog);
  const dispatch = useAppDispatch();

  const attachBlogImage = useCallback(async () => {
    try {
      setIsLoadingImage(true);
      const url = await getDownloadURL(ref(storage, `blogs/${blog?._id}/blogImage.jpg`));
      setPreviousImage(url);
      setIsLoadingImage(false);
    } catch (error) {
      setIsLoadingImage(false);
    }
  }, [blog?._id]);

  const _editMode = (e: string | undefined) => {
    if (e) {
      setValue(e);
    }
  };
  useEffect(() => {
    if (blog?._id) {
      setValue(blog.body);
      setTitle(blog.title);
      attachBlogImage();
    } else {
      setIsLoadingImage(false);
    }
    if (file) {
      let fileReader,
        isCancel = false;
      if (file) {
        fileReader = new FileReader();
        fileReader.onload = (e) => {
          const { result } = e.target as FileReader;
          if (result && !isCancel) {
            setFileDataURL(result as string);
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
  }, [attachBlogImage, blog, file, previousImage]);

  const _fileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && setFile(e.target.files[0]);
  };

  const _saveBlog = async () => {
    if (value && title) {
      const ret = await dispatch(
        createBlog({
          title,
          body: value!,
          blogId: blog?._id,
        })
      );
      if (!createBlog.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
      } else {
        if (file) {
          const storage = getStorage();
          const metadata = {
            contentType: "image/jpeg",
            cacheControl: "public,max-age=15552000",
          };
          const storageRef = ref(storage, `blogs/${ret.payload._id}/blogImage.jpg`);
          try {
            await uploadBytes(storageRef, file!, metadata);
            dispatch(showAlert({ message: "Success", severity: "success" }));
            dispatch(getBlogs());
            handleClose();
          } catch (e) {
            dispatch(showAlert({ message: "An error occurred", severity: "error" }));
          }
        } else {
          dispatch(showAlert({ message: "Success", severity: "success" }));
          dispatch(getBlogs());
          handleClose();
        }
      }
    } else {
      dispatch(showAlert({ message: "Blog title & message is required", severity: "error" }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      fullWidth
      sx={{ textAlign: "center" }}
    >
      <DialogTitle variant="h6">Create or Edit blog</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={9} container rowGap={1}>
            {" "}
            <Grid item xs={12}>
              <TextField
                id="search"
                value={title}
                size="small"
                placeholder="Blog title"
                sx={{
                  backgroundColor: "white",
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  sx: { fontSize: "14px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="button"
                        sx={{ fontSize: "10px" }}
                        aria-label="search"
                        onClick={() => setTitle("")}
                      >
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <MDEditor
                value={value}
                onChange={_editMode}
                height={400}
                textareaProps={{
                  placeholder: "keep writing",
                }}
                style={{ border: "1px solid #f2f2f2", boxShadow: "none" }}
                commandsFilter={(cmd) =>
                  cmd && /(codeEdit|codeLive|codePreview|image)/.test(cmd.name!) ? false : cmd
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size="medium"
                onClick={_saveBlog}
                disabled={isSaving}
                variant="contained"
                sx={{ mt: 2 }}
              >
                {!isSaving ? (
                  blog?._id ? (
                    "Edit"
                  ) : (
                    "Save"
                  )
                ) : (
                  <CircularProgress color="success" size={11} />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={3} container alignItems={"center"} justifyContent={"center"}>
            <Grid item position={"absolute"} zIndex={100}>
              <IconButton component={"label"}>
                <input hidden accept="image/*" multiple type="file" onChange={_fileSelected} />
                <ImageSearchIcon color="success" />
              </IconButton>
            </Grid>
            {isLoadingImage ? (
              <ComponentLoadingSpinner height="300px" />
            ) : (
              previousImage && (
                <Grid
                  item
                  position={"absolute"}
                  top={10}
                  textAlign={"center"}
                  justifyContent={"center"}
                >
                  <Typography>Current Image</Typography>
                  <img src={previousImage} alt={blog?.title} height={"100px"} />
                </Grid>
              )
            )}
            {file && (
              <Grid
                item
                container
                alignItems={"center"}
                justifyContent={"center"}
                width={"200px"}
                position={"relative"}
              >
                <Grid item position={"absolute"} top={0} right={0}>
                  <IconButton component={"label"} onClick={() => setFile(undefined)}>
                    <CancelIcon color="error" />
                  </IconButton>
                </Grid>

                <img src={fileDataURL} alt={file.name} width={"200px"} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default Blog;
