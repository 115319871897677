import { TextField } from "@mui/material";
import { FormikProps, ErrorMessage } from "formik";
import formFields from "./formFields";

export const AddressDetails = ({
  address,
  postCode,
  city,
  values,
  handleChange,
  touched,
  errors,
}: typeof formFields & FormikProps<any>): JSX.Element => {
  return (
    <>
      <TextField
        id="address"
        name={address.name}
        placeholder={address.label}
        label={address.label}
        fullWidth
        value={values.address}
        onChange={handleChange}
        error={touched.address && Boolean(errors.address)}
        sx={{ marginY: "20px" }}
      />
      <ErrorMessage name="address">
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
      <TextField
        id={postCode.name}
        name={postCode.name}
        label={postCode.label}
        fullWidth
        value={values.postCode}
        onChange={handleChange}
        error={touched.postCode && Boolean(errors.postCode)}
        sx={{ marginY: "20px" }}
      />
      <ErrorMessage name="postCode">
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
      <TextField
        id={city.name}
        name={city.name}
        label={city.label}
        fullWidth
        value={values.city}
        onChange={handleChange}
        error={touched.city && Boolean(errors.city)}
        sx={{ marginY: "20px" }}
      />
    </>
  );
};
