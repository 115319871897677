// @ts-nocheck
import React from "react";

export default function ZohoSalesIQ() {
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: process.env.REACT_APP_ZOHO_SALESIQ_CODE,
    values: {},
    ready: function () {},
  };
  const d = document;
  let s;
  s = d.createElement("script");
  s.type = "text/javascript";
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zohopublic.eu/widget";
  let t;
  t = d.getElementsByTagName("script")[0];
  if (t.parentNode) t.parentNode.insertBefore(s, t);
  return (
    <React.Fragment>
      <div id="zsiqwidget"></div>
    </React.Fragment>
  );
}
