import { Container } from "@mui/material";
import { useAppSelector } from "../../modules/hooks/store";
import { AccountTypeEnum, IAuth, PageProps } from "../../slices/interfaces";
import { isLoading } from "../../slices/user";
import { memo, useContext, useEffect } from "react";
import PageLoadingSpinner from "../PageLoadingSpinner";
import AdminDashboard from "../dashboards/Admin";
import PendingAccount from "../dashboards/PendingAccount";
import { useLocation, useParams } from "react-router-dom";
import useAuth from "../../modules/hooks/auth";
import { AuthContext } from "../../modules/context";
import LockedAccount from "./Locked";

const renderComponent = (accountType: AccountTypeEnum) => {
  switch (accountType) {
    case AccountTypeEnum.UNDEFINED:
      return <PendingAccount />;
    case AccountTypeEnum.CLIENT:
    case AccountTypeEnum.ADMIN:
    case AccountTypeEnum.STAFF:
      return <AdminDashboard accountType={accountType} />;
    default:
      return <LockedAccount />;
  }
};

export function Dashboard({ title }: PageProps) {
  const loading = useAppSelector(isLoading);
  const { pathname } = useLocation();
  const { dbUser } = useContext(AuthContext) as IAuth;
  const { logOut } = useAuth();
  const params = useParams();

  useEffect(() => {
    document.title = title;
    if (dbUser) {
      if (pathname.split("/")[1] !== "user" && params.id !== dbUser._id) logOut();
    }
  }, [title, pathname, logOut, dbUser, params]);

  return (
    <>
      {loading || !dbUser ? (
        <PageLoadingSpinner />
      ) : (
        <Container maxWidth={false} sx={{ height: "90vh" }}>
          {renderComponent(dbUser.accountType)}
        </Container>
      )}
    </>
  );
}

export default memo(Dashboard);
