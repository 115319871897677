import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, CircularProgress, Grid } from "@mui/material";
import { isSendingInvoice } from "../slices/invoice";
import { useAppSelector } from "../modules/hooks/store";
import { isLoadingParents } from "../slices/user";

interface IConfirmationDialogProps {
  message: string;
  handleClose: () => void;
  open: boolean;
  onSubmit: () => void;
  isUpdatepaymentModal?: boolean;
}

export default function ConfirmationDialog({
  open,
  message,
  handleClose,
  onSubmit,
  isUpdatepaymentModal,
}: IConfirmationDialogProps) {
  const sendingInvoice = useAppSelector(!isUpdatepaymentModal ? isSendingInvoice : isLoadingParents);
  return (
    <div>
      <Dialog
        open={open}
        onClose={sendingInvoice ? undefined : handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" textAlign={"center"}>
            {message}
          </DialogContentText>
          {sendingInvoice && (
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={3}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ pb: "20px" }}>
          <Grid container justifyContent={"space-around"}>
            <Button onClick={handleClose} variant="contained" color="error" disabled={sendingInvoice && sendingInvoice}>
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              autoFocus
              variant="contained"
              color="primary"
              disabled={sendingInvoice && sendingInvoice}
            >
              Yes
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
