import { Grid, CircularProgress } from "@mui/material";

const ComponentLoadingSpinner = ({ height = "500px" }: { height?: string }) => {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} height={height ?? "100%"}>
      <CircularProgress />
    </Grid>
  );
};

export default ComponentLoadingSpinner;
