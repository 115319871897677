import {
  Avatar,
  Button,
  Card,
  CardActions,
  Container,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../modules/context";
import { AccountTypeEnum, IAuth, IBlog } from "../../slices/interfaces";
import ArticleIcon from "@mui/icons-material/Article";
import Blog from "./Blog";
import { data, getBlogs, isLoading } from "../../slices/blog";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import EachBlog from "./eachBlog";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";

const Blogs = () => {
  // const [searchByName, setSearchByName] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { dbUser } = useContext(AuthContext) as IAuth;
  const [blog, setBlog] = useState<IBlog>();
  const allBlogs = useAppSelector(data);
  const loading = useAppSelector(isLoading);
  const blogId = useRef<string>();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  // const _searchByName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   // const searchValue = (e.target as HTMLInputElement).value;
  //   setSearchByName((e.target as HTMLInputElement).value);
  // };

  // const _resetSearch = (e: string) => {
  //   setSearchByName(e);
  // };
  logEvent(analytics, "wBlogs", {
    firebase_screen_class: "wBlogs",
    page_title: "Blogs",
  });

  const _handleClose = () => {
    setOpenDialog(!openDialog);
    setBlog(undefined);
  };

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  const _openBlogDialog = () => {
    setOpenDialog(!openDialog);
  };
  if (loading) return <ComponentLoadingSpinner height="90vh" />;
  return (
    <Container maxWidth={"lg"}>
      <Grid container spacing={1}>
        <Grid item container sx={{ position: "relative" }} my={2}>
          {dbUser && dbUser.accountType === AccountTypeEnum.ADMIN && (
            <Tooltip title="Create blog">
              <IconButton
                color="secondary"
                onClick={_openBlogDialog}
                size="small"
                sx={{ position: "fixed", top: 75, left: 30, zIndex: 5 }}
              >
                <Avatar sx={{ bgcolor: "grey" }}>
                  <ArticleIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
          )}
          {/* <Grid item xs={12}>
            <TextField
              id="search"
              value={searchByName}
              // size="small"
              label="Search by blog title"
              sx={{
                fontSize: "5px",
                backgroundColor: "white",
              }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: { fontSize: "14px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="button"
                      sx={{ fontSize: "10px" }}
                      aria-label="search"
                      onClick={() => _resetSearch("")}
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              onChange={_searchByName}
            ></TextField>
          </Grid> */}
        </Grid>
        {allBlogs.length > 0 &&
          [...allBlogs].map((x, _i) => {
            return (
              <Grid item xs={4} key={x._id}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                >
                  <Card
                    sx={{
                      width: "100%",
                      borderRadius: 5,
                      ":hover": { boxShadow: "1px 1px 20px lightgrey" },
                    }}
                    elevation={0}
                    // onClick={() => navigate(`/blogs/${x._id}`)}
                  >
                    <EachBlog
                      {...x}
                      reloadBlogImage={
                        blogId.current === undefined || x._id === blogId.current || !openDialog
                      }
                    />
                    <CardActions>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item xs={6}>
                          <Button size="small" onClick={() => navigate(`/blogs/${x._id}`)}>
                            View
                          </Button>
                        </Grid>
                        {dbUser && dbUser.accountType === AccountTypeEnum.ADMIN && (
                          <Grid item xs={6} justifyContent={"flex-end"} container>
                            <Button
                              size="small"
                              onClick={() => {
                                _openBlogDialog();
                                blogId.current = x.id;
                                setBlog(x);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              size="small"
                              onClick={() => console.log("Delete", x)}
                              color="error"
                            >
                              Delete
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            );
          })}
        {openDialog && <Blog open={openDialog} handleClose={_handleClose} blog={blog} />}
      </Grid>
    </Container>
  );
};

export default Blogs;
