import {
  Box,
  Avatar,
  Typography,
  Button,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { Link, useNavigate } from "react-router-dom";
import { Copyright } from "../copyRight";
import useAuth from "../../modules/hooks/auth";
import { useAppDispatch } from "../../modules/hooks/store";
import { showAlert } from "../../slices/alert";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";

type userData = { email: string };
export function SendPasswordReset() {
  const { userSendPasswordResetEmail } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClickHandler = async (data: userData) => {
    try {
      await userSendPasswordResetEmail(data.email);
      dispatch(
        showAlert({
          message: "Password reset email sent successfully",
          severity: "success",
        })
      );
      navigate("/auth/login");
    } catch (e: any) {
      console.log(e);
      dispatch(
        showAlert({
          message: "An error occurred, please contact admin",
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={"space-around"}
          alignItems="center"
          bgcolor="#e8fdfe"
          borderRadius="12px"
          boxShadow="2"
          width="50%"
          height="80vh"
        >
          <Avatar sx={{ m: 1, bgcolor: "#021d32" }} variant="rounded">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required("This field is mandatory")
                .matches(
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  "Email is not valid"
                )
                .email("Enter a valid email"),
            })}
            onSubmit={onClickHandler}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
            }: FormikProps<{ email: string }>) => (
              <Box width={"80%"} height={"30vh"}>
                <Form>
                  <TextField
                    id="email"
                    name="email"
                    placeholder="john@acme.com"
                    label="Email"
                    type="email"
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ""}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: "50px",
                      borderRadius: "20px",
                      height: "50px",
                      bgcolor: "#a5d6a7",
                      color: "black",
                      fontSize: "20px",
                    }}
                  >
                    {!isSubmitting ? "Send" : <CircularProgress color="success" />}
                  </Button>
                </Form>
              </Box>
            )}
          </Formik>
          <Button to="/" size="large" variant="text" component={Link}>
            Go to Home
          </Button>
        </Box>

        <Copyright />
      </Grid>
    </>
  );
}
