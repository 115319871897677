import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./slices/store";
import { AuthProvider } from "./modules/provider/AuthProvider";
import { createTheme, PaletteColorOptions, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";

document.documentElement.setAttribute("data-color-mode", "light");
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

declare module "@mui/material/styles" {
  interface Palette {
    orange: PaletteColorOptions;
  }
  interface PaletteOptions {
    orange: PaletteColorOptions;
  }
}

// Disable logging in production
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Public Sans", "sans-serif"].join(","),
    fontSize: 14,
  },
  palette: {
    orange: { main: "#ffa500" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: "20px",
          textTransform: "capitalize",
          "&:hover": {
            filter: "brightness(0.85)",
          },
          // ...(ownerState.color !== "primary" && {
          //   backgroundColor: "#283e4a",
          // }),
        }),
      },
    },
  },
});

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
    <div id="recaptcha-container"></div>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
