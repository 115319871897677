import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { AccountTypeEnum, Datum, IAuth, IBankTransactionData, IInvoice, PageProps } from "../../slices/interfaces";
import {
  getInvoice,
  invoiceData,
  isLoading,
  isLoadingInvoice,
  sendInvoice,
  updatePaidStatus,
  bankTransactions,
  getBankTransaction,
} from "../../slices/invoice";
import { ChangeEvent, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PageLoadingSpinner from "../PageLoadingSpinner";
import EmailIcon from "@mui/icons-material/Email";
import { eachDayOfInterval, startOfMonth, endOfMonth, isWeekend, format, parse } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";

import TableRowState from "./TableRow";
import { showAlert } from "../../slices/alert";
import ConfirmationDialog from "../ConfirmationDialog";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import { TableVirtuoso } from "react-virtuoso";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../modules/context";
import { formatNumber } from "../../utils";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";

const createColumnHeader = (date: Date) => format(date, "dd");

const TableComponents = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props: any) => (
    <Table
      size="small"
      {...props}
      style={{
        paddingBottom: "10px",
      }}
    />
  ),
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead
      {...props}
      ref={ref}
      style={{
        paddingBottom: "10px",
        backgroundColor: "#14e6dd",
        position: "sticky",
        top: "0px",
        zIndex: 10,
      }}
    />
  )),
  TableBody: TableBody,
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const QuickStatCard = ({ title, bgcolor, aggregate }: { title: string; bgcolor: string; aggregate: string }) => {
  return (
    <Card sx={{ border: "1px solid #dadde9" }} elevation={0}>
      <CardContent>
        <Typography sx={{ fontSize: 20, bgcolor, fontWeight: "500" }} gutterBottom>
          {title}
        </Typography>
        <Typography sx={{ fontSize: 15 }} color="text.secondary" variant="h6">
          {aggregate}
        </Typography>
      </CardContent>
    </Card>
  );
};

export function Invoice({ title }: PageProps) {
  const isLoadingInvoiceData = useAppSelector(isLoadingInvoice);
  const dispatch = useAppDispatch();
  const invoices = useAppSelector(invoiceData);
  const bankTransactionsData = useAppSelector(bankTransactions);
  const loading = useAppSelector(isLoading);
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [updatedBooking, setUpdatedBooking] = useState<boolean>(false);
  const [searchByName, setSearchByName] = useState<string>("");
  const [filteredInvoices, setFilteredInvoices] = useState<IInvoice[]>([]);
  const [openSendMailDialog, setOpenSendMailDialog] = useState<boolean>(false);
  const [markInvoicePaid, setMarkInvoicePaid] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<Datum>();
  const { dbUser } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();

  logEvent(analytics, "wInvoices", {
    firebase_screen_class: "wInvoices",
    page_title: "Invoices",
  });

  const [fullWidth, setFullWidth] = useState<boolean>(false);
  const getAttendance = useCallback(() => {
    dispatch(getInvoice({ month, year }));
    dispatch(getBankTransaction({ period: -1, invoice: true }));
    setUpdatedBooking(true);
  }, [dispatch, month, year]);
  useEffect(() => {
    getAttendance();
    if (dbUser && dbUser.accountType !== AccountTypeEnum.ADMIN) {
      navigate("/", { replace: true });
    }
  }, [dispatch, getAttendance, dbUser, navigate]);

  const transformTransactions = useMemo(() => {
    const ret = bankTransactionsData.reduce<IBankTransactionData[]>((acc, curr) => [...acc, ...curr.transactions], []);
    return ret;
  }, [bankTransactionsData]);

  const totalInvoiceCost = useMemo(() => {
    setFilteredInvoices(invoices);
    const aggregate: { [x: string]: number } = {};

    if (invoices.length > 0) {
      aggregate["attendanceCreated"] = invoices[0].data.length;
      invoices[0].data.forEach((data) => {
        const temp = data.record.reduce(
          (acc, x) => acc + (x.fullDay ? invoices[0].fullDayCharge : invoices[0].normalCharge),
          0
        );
        aggregate["totalInvoice"] =
          (aggregate["totalInvoice"] || 0) + temp + (data?.travel ?? 0) - (data?.discount ?? 0);
        aggregate["totalPaid"] =
          (aggregate["totalPaid"] || 0) -
          (data.paid
            ? -(temp + (data.travel ?? 0) - (data.discount ?? 0))
            : data.partialPayment > 0
            ? -data.partialPayment
            : 0);
        aggregate["totalAttendance"] = (aggregate["totalAttendance"] || 0) + data.record.length;
      });
    }
    aggregate["outstanding"] =
      ((aggregate["totalInvoice"] || undefined) ?? 0) - ((aggregate["totalPaid"] || undefined) ?? 0);
    return aggregate;
  }, [invoices]);

  const handleSendMailClose = () => {
    setOpenSendMailDialog(false);
  };

  const daysOfMonth = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfMonth(new Date(year, month)),
        end: endOfMonth(new Date(year, month)),
      })
        .filter((date) => !isWeekend(date))
        .map(createColumnHeader),
    [month, year]
  );
  const _searchByPupilName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchValue = (e.target as HTMLInputElement).value;
    setSearchByName((e.target as HTMLInputElement).value);
    if (invoices.length > 0 && searchValue.length > 3) {
      const searchUpdate = [...invoices];
      const updateData = searchUpdate[0].data.filter((invoice) =>
        invoice.firstName.toLocaleLowerCase().includes(searchByName.toLocaleLowerCase())
      );
      searchUpdate.splice(0, 1, { ...searchUpdate[0], data: updateData });
      setFilteredInvoices(searchUpdate);
    }
    if (searchValue.length === 0) {
      setSearchByName("");
      setFilteredInvoices(invoices);
    }
  };

  const _sendInvoice = async () => {
    const ret = await dispatch(sendInvoice({ month, year }));
    if (!sendInvoice.fulfilled.match(ret)) {
      dispatch(
        showAlert({
          message: `${ret.payload!}`,
          severity: "error",
        })
      );
    } else {
      dispatch(
        showAlert({
          message: `All invoices sent successfully`,
          severity: "success",
        })
      );
    }
    handleSendMailClose();
  };

  const _resetSearch = (e: string) => {
    setSearchByName(e);
    setFilteredInvoices(invoices);
  };

  const _updatePayment = async () => {
    if (invoice) {
      const { paid: paidStatus, pupilId } = invoice;
      const ret = await dispatch(updatePaidStatus({ paidStatus, pupilId, month, year }));
      if (!updatePaidStatus.fulfilled.match(ret)) {
        dispatch(
          showAlert({
            message: `${ret.payload!}`,
            severity: "error",
          })
        );
        setInvoice(undefined);
      } else {
        dispatch(
          showAlert({
            message: `Payment status updated for ${invoice.firstName}`,
            severity: "success",
          })
        );
      }
      setMarkInvoicePaid(false);
    }
  };

  const _getInvoice = (invoice: Datum) => {
    setInvoice(invoice);
    setMarkInvoicePaid(true);
  };

  return (
    <>
      {loading && !updatedBooking ? (
        <PageLoadingSpinner />
      ) : (
        <Grid container rowGap={2}>
          <Grid item container sx={{ bgcolor: "white", padding: 1 }}>
            <Grid item xs={12} textAlign={"center"} columns={10} columnSpacing={1} container>
              <Grid item xs={2}>
                <QuickStatCard
                  title="Total attendance"
                  bgcolor={"#ffa500"}
                  aggregate={`${totalInvoiceCost["totalAttendance"] ?? 0}`}
                ></QuickStatCard>
              </Grid>
              <Grid item xs={2}>
                <QuickStatCard
                  title="Invoice created"
                  bgcolor={"#FFF033"}
                  aggregate={`${totalInvoiceCost["attendanceCreated"] ?? 0}`}
                ></QuickStatCard>
              </Grid>
              <Grid item xs={2}>
                <QuickStatCard
                  title="Invoice total"
                  bgcolor={"#00b4ff"}
                  aggregate={formatNumber(totalInvoiceCost["totalInvoice"] ?? 0)}
                ></QuickStatCard>
              </Grid>
              <Grid item xs={2}>
                <QuickStatCard
                  title="Paid"
                  bgcolor={"lightgreen"}
                  aggregate={formatNumber(totalInvoiceCost["totalPaid"] ?? 0)}
                ></QuickStatCard>
              </Grid>
              <Grid item xs={2}>
                <QuickStatCard
                  title="Outstanding"
                  bgcolor={"pink"}
                  aggregate={formatNumber(totalInvoiceCost["outstanding"] ?? 0)}
                ></QuickStatCard>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container height={"fit-content"} rowGap={2}>
            <Grid container alignItems={"center"} justifyContent={"space-between"} columnSpacing={2}>
              <Grid item xs={1}>
                <TextField
                  id="month"
                  value={month}
                  select
                  size="small"
                  label="Month"
                  sx={{
                    textAlign: "left",
                    backgroundColor: "white",
                    fontSize: "10px",
                  }}
                  InputProps={{
                    sx: { fontSize: "12px", p: 0, m: 0 },
                  }}
                  fullWidth
                  onChange={(e) => {
                    setMonth(parseInt(e.target.value));
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Month</em>
                  </MenuItem>
                  {monthNames.map((type, i) => (
                    <MenuItem key={type} value={i} sx={{ fontSize: "12px" }}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={1}>
                <TextField
                  id="year"
                  value={year}
                  select
                  size="small"
                  label="Year"
                  sx={{
                    fontSize: "10px",
                  }}
                  InputProps={{
                    sx: { fontSize: "12px", p: 0, m: 0 },
                  }}
                  fullWidth
                  onChange={(e) => {
                    setYear(parseInt(e.target.value));
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Year</em>
                  </MenuItem>

                  {Array(5)
                    .fill(0)
                    .map((_, i) => i + 2021)
                    .map((type, i) => (
                      <MenuItem key={type} value={type} sx={{ fontSize: "12px" }}>
                        {type}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="search"
                  value={searchByName}
                  size="small"
                  label="Search by pupil name"
                  sx={{
                    fontSize: "5px",
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    sx: { fontSize: "14px" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="button"
                          sx={{ fontSize: "10px" }}
                          aria-label="search"
                          onClick={() => _resetSearch("")}
                        >
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={_searchByPupilName}
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  fullWidth
                  disabled={false}
                  onClick={() => setOpenSendMailDialog(true)}
                >
                  <EmailIcon sx={{ mr: 1 }} /> Send invoice
                </Button>
              </Grid>
            </Grid>
            <Grid item width={"100%"}>
              {isLoadingInvoiceData ? (
                <ComponentLoadingSpinner height={"85vh"} />
              ) : filteredInvoices.length > 0 ? (
                <Grid container marginBottom={1} columnSpacing={1}>
                  <Grid item xs={3} height={"85vh"} display={fullWidth ? "none" : "block"}>
                    <TableContainer
                      component={Paper}
                      elevation={1}
                      sx={{
                        position: "relative",
                        height: "100%",
                        overflow: "auto",
                        td: { fontSize: "12px" },
                        th: {
                          position: "sticky",
                          top: "0px",
                          zIndex: 10,
                        },
                      }}
                    >
                      {false ? (
                        <ComponentLoadingSpinner />
                      ) : (
                        <Table aria-label="transactionCategory" size="small">
                          <TableHead
                            sx={{
                              th: {
                                backgroundColor: "#14e6dd	",
                                fontSize: "11px",
                              },
                            }}
                          >
                            <TableRow>
                              <TableCell width={"2px"}>Date</TableCell>
                              <TableCell width={"80%"}>Description</TableCell>
                              <TableCell>Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {transformTransactions.length > 0 ? (
                              transformTransactions
                                .sort(
                                  (a, b) =>
                                    parse(b.transactionDate, "dd/MM/yyyy", new Date()).getTime() -
                                    parse(a.transactionDate, "dd/MM/yyyy", new Date()).getTime()
                                )
                                .map((transaction) => {
                                  return (
                                    <TableRow key={transaction._id}>
                                      <TableCell>{transaction.transactionDate.slice(0, 5)}</TableCell>
                                      <TableCell title={transaction.transactionDescription}>
                                        {transaction.transactionDescription.substring(0, 22)}{" "}
                                        {transaction.transactionDescription.length >= 22 && "..."}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>{transaction.amount.toFixed(2)}</TableCell>
                                    </TableRow>
                                  );
                                })
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4} align={"center"}>
                                  <Box
                                    height={"78vh"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    display={"flex"}
                                    overflow={"hidden"}
                                  >
                                    No Data Available
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                    {/* </Grid> */}
                  </Grid>
                  <Grid item xs={fullWidth ? 12 : 9}>
                    <TableVirtuoso
                      style={{
                        height: "85vh",
                      }}
                      data={filteredInvoices[0].data}
                      components={TableComponents}
                      fixedHeaderContent={() => (
                        <TableRow sx={{ th: { fontSize: "11px" } }}>
                          <TableCell width={"10px"}>
                            <IconButton type="button" sx={{ p: 0 }} onClick={() => setFullWidth(!fullWidth)}>
                              {fullWidth ? (
                                <ZoomInMapIcon sx={{ color: "black" }} />
                              ) : (
                                <ZoomOutMapIcon sx={{ color: "black" }} />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell width={"15%"}>
                            <Typography fontSize={"inherit"}>Name</Typography>
                          </TableCell>
                          {daysOfMonth.map((date, i) => (
                            <TableCell
                              sx={{
                                textAlign: "center",
                                p: 0,
                              }}
                              key={`${i}_${date}`}
                            >
                              {date}
                            </TableCell>
                          ))}
                          <TableCell
                            width={"50px"}
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            Travel
                          </TableCell>
                          <TableCell
                            width={"50px"}
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            Discount
                          </TableCell>
                          <TableCell
                            width={"60px"}
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            Part pay
                          </TableCell>
                          <TableCell
                            width={"50px"}
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            width={"70px"}
                            sx={{
                              textAlign: "center",
                            }}
                          ></TableCell>
                        </TableRow>
                      )}
                      itemContent={(_, i) => {
                        const total =
                          i.record.reduce(
                            (acc, data) =>
                              acc +
                              (data.fullDay ? filteredInvoices[0].fullDayCharge : filteredInvoices[0].normalCharge),
                            0
                          ) +
                          (i?.travel ?? 0) -
                          (i?.discount ?? 0);
                        return (
                          <TableRowState
                            invoice={i}
                            daysOfMonth={daysOfMonth}
                            total={total}
                            key={i.pupilId}
                            month={month}
                            year={year}
                            getInvoice={_getInvoice}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Box
                  height={"85vh"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  component={Paper}
                  elevation={0}
                >
                  No Data Available
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {openSendMailDialog && (
        <ConfirmationDialog
          open={openSendMailDialog}
          handleClose={handleSendMailClose}
          message={"Do you want to send all invoices to parents?"}
          onSubmit={_sendInvoice}
        />
      )}
      {markInvoicePaid && (
        <ConfirmationDialog
          open={markInvoicePaid}
          handleClose={() => setMarkInvoicePaid(false)}
          message={`Update ${invoice?.firstName}'s invoice as ${invoice?.paid ? "un" : ""}paid?`}
          onSubmit={_updatePayment}
        />
      )}
    </>
  );
}

export default memo(Invoice);
